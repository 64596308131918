import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type QueryClient from "../../../../../api/query/queryClient";
import { numberToMoneyMessage } from "../../../../../utils/utils";
import { PaymentPlanStore } from "../../../store";
import Contract from "./Contract";
import { ESign } from "./ESign";
import { buildInstallments } from "./installments";

export function Summary({
  patientId,
  store,
  queryClient,
  updateSnackBar,
}: {
  patientId: string;
  store: PaymentPlanStore;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}) {
  const [mode, setMode] = useState<"view" | "sign">("view");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Kick them back if first step isn't complete
  useEffect(() => {
    if (store.paymentMethod === undefined || store.paymentTerms === undefined) {
      navigate(`/patients/${patientId}/payment-plans/new?${searchParams}`);
    }
  }, [store.paymentMethod, store.paymentTerms]);

  const toggleSignMode = () => {
    setMode((prev) => (prev === "view" ? "sign" : "view"));
    window.scrollTo(0, 0);
  };

  const createPaymentPlan = async (signature: string) => {
    const terms = store.paymentTerms;
    if (!terms) {
      throw new Error("Payment terms are not defined");
    }

    try {
      const res = await queryClient.postPatientPaymentMethod(patientId, {
        id: store?.id ?? "",
        paymentMethodId: store.paymentMethod?.id ?? "",
        totalAmount: numberToMoneyMessage(terms.totalAmount),
        downPayment: numberToMoneyMessage(terms.downPayment),
        contractAmount: numberToMoneyMessage(terms.contractValue),
        contractPeriod: "PT30D",
        installments: buildInstallments(terms),
        signature: signature.replace("data:image/png;base64,", ""),
        signatureDate: dayjs().format("YYYY-MM-DD"),
      });

      navigate(
        `/patients/${patientId}/payment-plans/${res.id}/success?${searchParams}`,
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      updateSnackBar({
        severity: "error",
        message: "Failed to create payment plan",
      });
    }
  };

  if (store.paymentMethod === undefined || store.paymentTerms === undefined) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection={mode === "sign" ? "column" : "row"}
      position="relative"
    >
      {mode === "view" && (
        <Box
          display="flex"
          justifyContent="flex-end"
          width="50%"
          paddingX="64px"
          paddingY="60px"
          minHeight="100%"
          position="relative"
        >
          <Button
            sx={{
              position: "absolute",
              top: "58px",
              right: `${432 + 140}px`,
            }}
            startIcon={<ArrowBackOutlinedIcon />}
            onClick={() => {
              navigate(
                `/patients/${patientId}/payment-plans/new/terms?${searchParams}`,
              );
            }}
          >
            Back
          </Button>
          <ESign width="432px" onAgree={toggleSignMode} />
        </Box>
      )}

      {mode === "sign" && (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Button
            sx={{
              position: "absolute",
              top: "58px",
              left: `${140}px`,
            }}
            startIcon={<ArrowBackOutlinedIcon />}
            onClick={() => {
              toggleSignMode();
            }}
          >
            Back
          </Button>

          <Typography
            variant="h5"
            fontWeight={600}
            marginBottom={4}
            sx={{
              position: "absolute",
              top: "58px",
              left: `${260}px`,
            }}
          >
            E-SIGN Payment Plan PDF
          </Typography>
          <Alert severity="warning" sx={{ marginBottom: 2, marginX: 2 }}>
            Please scroll to the end of the document to add your signature,
            remember to read the legal language and verify all the information
            is correct before signing.
          </Alert>
        </Box>
      )}

      <Contract
        mode={mode}
        onSign={createPaymentPlan}
        store={store}
        patientId={patientId}
        queryClient={queryClient}
      />
    </Box>
  );
}
