import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

export function ESign({
  onAgree,
  width,
}: {
  onAgree: () => void;
  width: string;
}) {
  const navigate = useNavigate();
  return (
    <Box width={width}>
      <Box>
        <Typography variant="h5" fontWeight={600} marginBottom={4}>
          E-SIGN Payment Plan & TCPA Opt-in
        </Typography>

        <Typography variant="h6" fontWeight={600} marginBottom={1}>
          U.S. Federal Consumer Disclosure E-SIGN Act
        </Typography>
        <Box component="p" fontSize="14px" lineHeight="20px" margin={0}>
          By clicking ‘Yes, I agree’ below, you consent to conduct this
          transaction electronically, record and electronic signature after
          review of your document, and have such signed document provided to you
          in electronic format - specifically PDF format. You may decline to
          complete this transaction electronically by clicking ‘No, I do not
          agree’ below.
        </Box>
        <Box
          component="p"
          fontSize="14px"
          lineHeight="20px"
          margin={0}
          marginBottom={4}
        >
          Your consent here only applies to this transaction. You will be
          provided with an opportunity to download the final, signed document.
        </Box>

        <Typography variant="h6" fontWeight={600} marginBottom={1}>
          Telephone Consumer Protection Act
        </Typography>
        <Box
          component="p"
          fontSize="14px"
          lineHeight="20px"
          margin={0}
          marginBottom={4}
        >
          By clicking ‘Yes, I agree’ below, you consent to receive SMS
          notifications related to payment activity on your Wieldy account. You
          understand that message and data rates may apply, and you can reply
          STOP to opt-out of messages.
        </Box>

        <Alert severity="info" sx={{ marginBottom: 4 }}>
          To conduct this transaction electronically, you will need a Web
          browser and a PDF reader for viewing/printing the signed document,
          such as the free Adobe Reader. A PDF Reader is not required to view or
          sign your document.
        </Alert>

        <Button
          data-testid="agreeButton"
          variant="contained"
          color="success"
          size="large"
          sx={{ marginBottom: 1 }}
          fullWidth
          onClick={() => {
            onAgree();
            // navigate(`/patients/${patientId}/payment-plans/new/success`);
          }}
        >
          Yes, I agree
        </Button>

        <Button
          variant="text"
          size="large"
          fullWidth
          onClick={() => {
            navigate(`/patients`);
          }}
        >
          No, I do not agree
        </Button>
      </Box>
    </Box>
  );
}
