import { partialReplaceByWieldyId } from "../../utils/utils";

export type ReconciliationsStore = {
  transactions: BankTransactionMessage[];
  loadedTransactions: boolean;
};

export const initialReconciliationsStore: ReconciliationsStore = {
  transactions: [],
  loadedTransactions: false,
};

export type ReconciliationsStoreAction =
  | { type: "SET_TRANSACTIONS"; transactions: BankTransactionMessage[] }
  | { type: "RESET_TRANSACTIONS" }
  | {
      type: "UPDATE_TRANSACTION";
      transactionUpdate: BankTransactionUpdateMessage;
    };

export function reconciliationsStoreReducer(
  store: ReconciliationsStore,
  action: ReconciliationsStoreAction,
): ReconciliationsStore {
  switch (action.type) {
    case "RESET_TRANSACTIONS":
      return {
        ...store,
        transactions: [],
        loadedTransactions: false,
      };
    case "SET_TRANSACTIONS":
      return {
        ...store,
        transactions: action.transactions,
        loadedTransactions: true,
      };
    case "UPDATE_TRANSACTION":
      return {
        ...store,
        transactions: partialReplaceByWieldyId(
          store.transactions,
          action.transactionUpdate,
        ),
      };
    default:
      return store;
  }
}
