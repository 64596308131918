export function formatMoney(amount: number | null): string {
  if (amount === null) {
    return "";
  }
  const [dollars, cents] = amount.toFixed(2).split(".");
  return `$${Number(dollars).toLocaleString()}.${cents}`;
}

export function formatPercent(pct: number | null, decimalPlaces = 0): string {
  if (pct === null) {
    return "";
  }
  return `${pct.toFixed(decimalPlaces)}%`;
}
