import { FormControl, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";
import { Control, Controller, Path } from "react-hook-form";

import { camelToLabel } from "../../utils/utils";
import { InputWrapper } from "../CredentialsDashboard/ui/InputWrapper";
import { ErrorHelperText } from "../PatientsDashboard/ui/ErrorHelper";

interface FieldConfig {
  name: string;
  type: string;
  required: boolean;
}

export function renderField<T extends Record<string, string | number | null>>(
  control: Control<T>,
  { name, type, required }: FieldConfig,
) {
  return (
    <Grid item xs={12} sm={6} key={name}>
      <Controller
        name={name as Path<T>}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <FormControl sx={{ width: "100%" }}>
            <InputWrapper>
              <TextField
                onChange={
                  type === "number"
                    ? (e) => {
                      const val =
                          e.target.value === "" ? null : Number(e.target.value);
                      onChange(val);
                    }
                    : onChange
                }
                onBlur={onBlur}
                value={value ?? ""}
                name={name}
                inputRef={ref}
                type={type}
                label={camelToLabel(name)}
                variant="outlined"
                fullWidth
                required={required}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />
    </Grid>
  );
}
