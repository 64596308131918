import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import React from "react";

import { moneyMessageToNumber } from "../../utils/utils";
import PaymentPlanField from "./PaymentPlanField";

interface PaymentPlanDetailsFormProps {
  plan: PaymentPlanMessage;
}

export default function PaymentPlanDetailsForm({
  plan,
}: PaymentPlanDetailsFormProps) {
  const firstInstallment = plan.installments[0];
  const lastInstallment = plan.installments[plan.installments.length - 1];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Total amount"
          name="totalAmount"
          value={plan.totalAmount}
          type="money"
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Down payment"
          name="downPayment"
          value={plan.downPayment}
          type="money"
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Discount"
          name="discount"
          value={
            ((moneyMessageToNumber(plan.totalAmount) -
              moneyMessageToNumber(plan.downPayment) -
              moneyMessageToNumber(plan.contractAmount)) /
              moneyMessageToNumber(plan.totalAmount)) *
            100
          }
          type="percent"
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Contract value"
          name="contractAmount"
          value={plan.contractAmount}
          type="money"
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Contract period"
          name="contractPeriod"
          value={plan.installments.length}
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Withdrawal begin date"
          name="withdrawalBeginDate"
          value={firstInstallment.date}
          type="date"
          disabled
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PaymentPlanField
          label="Contract value/period"
          name="contractValuePerPeriod"
          value={
            moneyMessageToNumber(plan.contractAmount) / plan.installments.length
          }
          type="money"
          precision={4}
          disabled
          required
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Installment amount"
          name="installmentAmount"
          value={firstInstallment.amount}
          type="money"
          disabled
          required
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Last payment"
          name="lastPayment"
          value={lastInstallment.amount}
          type="money"
          disabled
          required
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <PaymentPlanField
          label="Withdrawal end date"
          name="withdrawalEndDate"
          value={dayjs(firstInstallment.date)
            .add(plan.installments.length - 1, "month")
            .format("MM/DD/YYYY")}
          disabled
          required
        />
      </Grid>
    </Grid>
  );
}
