import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRowSpacingParams,
  GridToolbar,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../../../../formatters";
import { isDevMode } from "../../../../../utils/utils";
import CustomNoRowsOverlay from "../../../../TableNoDataOverlay";
import { claimSumRows } from "./rows";

function getColumns(): GridColDef[] {
  return [
    {
      flex: 0.75,
      field: "month",
      headerName: "Month",
    },
    {
      flex: 0.75,
      field: "procedures",
      headerName: "Procedures",
    },
    {
      flex: 0.5,
      field: "procedureDenials",
      headerName: "Denials",
    },
    {
      flex: 0.5,
      field: "denialsRate",
      headerName: "Denial Rate",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        `${params.value.toFixed(2)}%`,
    },
    {
      flex: 1,
      field: "insurancePays",
      headerName: "Insurance Pays",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 1,
      field: "submittedAmount",
      headerName: "Submitted Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 1,
      field: "contractedAmount",
      headerName: "Contracted Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 1,
      field: "patientResponsibility",
      headerName: "Patient Responsibility",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
  ];
}

interface ClaimSumsProps {
  claims: ClaimWithProcedureMessage[];
}

export default function ClaimSums({ claims }: ClaimSumsProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  const rows = claimSumRows(claims);
  return (
    <div
      style={
        rows && rows.length
          ? { width: "100%" }
          : { width: "100%", height: "500px" }
      }
    >
      <h2>Denial Details</h2>
      <DataGridPro
        rowHeight={24}
        getRowSpacing={getRowSpacing}
        sx={{
          [`& .${gridClasses.virtualScrollerRenderZone}`]: {
            width: "100%",
          },
        }}
        rows={rows}
        columns={getColumns()}
        getRowId={(row) => row.month}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: isDevMode() ? GridToolbar : undefined,
        }}
      />
    </div>
  );
}
