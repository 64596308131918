import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import useDataGridPersistence from "../../utils/datagrid";
import DateRangePicker, { DateRange } from "../DateRangePicker";
import ReconciliationsTable from "../ReconciliationsTable";
import NewReconciliation from "./NewReconciliation";

function filterTransactions(
  transactions: BankTransactionMessage[],
  dateRange: DateRange,
): BankTransactionMessage[] {
  return transactions.filter((transaction) => {
    if (!transaction.bankDate) {
      return false;
    }
    const bankDate = dayjs(transaction.bankDate);
    return (
      (bankDate.isSame(dateRange.startDate, "day") ||
        bankDate.isAfter(dateRange.startDate, "day")) &&
      (bankDate.isSame(dateRange.endDate, "day") ||
        bankDate.isBefore(dateRange.endDate, "day"))
    );
  });
}

interface ReconciliationListViewProps {
  load: () => Promise<void>;
  reset: () => void;
  transactions: BankTransactionMessage[];
  transactionsLoading: boolean;
  practices: PracticeMessage[];
  uploadBankTransactions: (practiceId: string, file: File) => Promise<void>;
  updateTransaction: (
    transactionUpdate: BankTransactionUpdateMessage,
  ) => Promise<void>;
}

export default function ReconciliationListView({
  load,
  reset,
  transactions,
  transactionsLoading,
  practices,
  uploadBankTransactions,
  updateTransaction,
}: ReconciliationListViewProps) {
  useEffect(() => {
    load();
    return reset;
  }, []);
  const dataGridPersistence = useDataGridPersistence("reconciliationListView");
  const [open, setOpen] = useState(false);
  const [currentDateRange, setCurrentDateRange] = useState<DateRange>({
    startDate: dayjs().subtract(1, "month"),
    endDate: dayjs(),
  });

  const transactionsByDate = transactions
    .filter((t) => !!t.bankDate)
    .sort((a, b) => (dayjs(a.bankDate).isAfter(dayjs(b.bankDate)) ? -1 : 1));

  return (
    <>
      <h1>Bank Reconciliation</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "14px",
        }}
      >
        <div>
          <Button
            aria-label="Reconcile Bank Statement"
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            Reconcile Bank Statement
          </Button>
        </div>
        <div>
          <DateRangePicker
            selectedDateRange={currentDateRange}
            setDateRange={setCurrentDateRange}
            earliestStartDate={
              transactionsByDate.length
                ? transactionsByDate[transactionsByDate.length - 1].bankDate
                : null
            }
          />
        </div>
      </div>
      <NewReconciliation
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        practices={practices}
        uploadBankTransactions={uploadBankTransactions}
      />
      <ReconciliationsTable
        isLoading={transactionsLoading}
        transactions={filterTransactions(transactionsByDate, currentDateRange)}
        updateTransaction={updateTransaction}
        apiRef={dataGridPersistence.apiRef}
      />
    </>
  );
}
