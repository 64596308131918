import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useState } from "react";

import { isDevMode } from "../../../utils/utils";
import { AddPayerCredentials } from "../Form/AddPayerCredentials";
import { CredentialsStore } from "../store";

interface NewCredentialsDialogProps {
  credentialsStore: CredentialsStore;
  practices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
  afterFormSubmit: ({
    payerId,
    practiceId,
    username,
    password,
  }: Partial<UserCredentialsMessage>) => Promise<void>;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  authorizeMfaEmail: () => Promise<void>;
}

export function NewCredentialsDialog({
  credentialsStore,
  practices,
  supportedPayers,
  afterFormSubmit,
  updateSnackBar,
  authorizeMfaEmail,
}: NewCredentialsDialogProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      {isDevMode() && (
        <Button variant="contained" onClick={authorizeMfaEmail}>
          Authorize MFA Email
        </Button>
      )}
      <Button variant="contained" onClick={() => setOpen(true)}>
        Add Credential
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            maxWidth: "735px",
            width: "100%",
            padding: "24px 36px",
            margin: "auto",
            borderRadius: "12px",
          },
        }}
      >
        <Box>
          <DialogContent sx={{ padding: 0, margin: 0 }}>
            <AddPayerCredentials
              userCredentials={credentialsStore.userCredentials}
              payers={supportedPayers}
              practices={practices}
              cancel={() => {
                setOpen(false);
              }}
              afterFormSubmit={async ({
                payerId,
                practiceId,
                username,
                password,
              }) => {
                setOpen(false);
                await afterFormSubmit({
                  payerId,
                  practiceId,
                  username,
                  password,
                });
              }}
              updateSnackBar={updateSnackBar}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
}
