import { Backend, getBackend } from "../api/apiConfig";

function getAuth0ClientId(): string {
  switch (getBackend()) {
    case Backend.Local:
      return "RCvILgk5H26uLmY2yHZGzOmxOFE6U2if";
    case Backend.Staging:
      return "Ewtmi2YyDHqqxZYOWUA6u2JBDtLGeSGe";
    case Backend.Production:
      return "UJAfrTSqdPsuXyhZtcb6bAbzatR5q79w";
    default:
      throw new Error("Unrecognized backend");
  }
}

function getAuth0Domain(): string {
  switch (getBackend()) {
    case Backend.Local:
      return "https://wieldy-dev.us.auth0.com";
    case Backend.Staging:
      return "https://wieldy-staging.us.auth0.com";
    case Backend.Production:
      return "https://wieldy.us.auth0.com";
    default:
      throw new Error("Unrecognized backend");
  }
}

function getApiAudience(): string {
  switch (getBackend()) {
    case Backend.Local:
      return "http://localhost:8000/api";
    case Backend.Staging:
      return "https://staging.api.wieldy.ai/api";
    case Backend.Production:
      return "https://api.wieldy.ai/api";
    default:
      throw new Error("Unrecognized backend");
  }
}

export { getAuth0ClientId, getAuth0Domain, getApiAudience };
