import { type InputProps } from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import React, { type ChangeEvent, type HTMLInputTypeAttribute } from "react";

export type PatientFieldValue = string | number | null;
export type PatientFieldChangeHandler = (
  name: string,
  value: PatientFieldValue,
) => void;

function toDisplay(
  value: PatientFieldValue | undefined,
  type: HTMLInputTypeAttribute,
): string | number {
  if (value === undefined) {
    return "";
  }
  switch (type) {
    case "string":
      return value as string;
    case "date":
      return dayjs(value).format("YYYY-MM-DD");
    case "number":
      return value as number;
    default:
      throw new Error("Unsupported type for PatientField");
  }
}

function toStore(
  value: string,
  type: HTMLInputTypeAttribute,
): PatientFieldValue {
  if (value.length === 0) {
    return null;
  }
  switch (type) {
    case "string":
    case "date":
      return value;
    case "number":
      return parseFloat(value);
    default:
      throw new Error("Unsupported type for PatientField");
  }
}

interface PatientFieldProps {
  label: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  value?: PatientFieldValue;
  onChange?: (name: string, val: PatientFieldValue) => void;
  required?: boolean;
  disabled?: boolean;
}

export default function PatientField({
  label,
  name,
  type = "string",
  value,
  onChange,
  required,
  disabled,
}: PatientFieldProps) {
  const variant = disabled ? "standard" : "outlined";
  let inputProps: InputProps = { name };
  // disableUnderline is supported only for 'standard' and 'filled' variants.
  // See: https://github.com/mui/material-ui/issues/15502
  if (variant === "standard") {
    inputProps = {
      ...inputProps,
      disableUnderline: true,
    };
  }

  const inputLabelProps = { shrink: true };
  const sx = disabled
    ? {
      "& input:disabled": {
        color: "initial",
        TextFillColor: "initial",
        WebkitTextFillColor: "initial",
      },
    }
    : undefined;
  const placeholder = disabled ? "-" : undefined;
  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!onChange) {
      return;
    }
    onChange(e.target.name, toStore(e.target.value, type));
  };
  return (
    <TextField
      data-sr-redact
      label={label}
      variant={variant}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      sx={sx}
      type={type}
      value={toDisplay(value, type)}
      placeholder={placeholder}
      onChange={handleOnChange}
      required={required}
      disabled={disabled}
      fullWidth
    />
  );
}

PatientField.defaultProps = {
  type: "string",
  value: undefined,
  onChange: undefined,
  required: false,
  disabled: false,
};
