import { Grid } from "@mui/material";
import {
  axisClasses,
  BarChart,
  BarSeriesType,
  mangoFusionPalette,
} from "@mui/x-charts";
import React from "react";

import { formatPercent } from "../../../../../formatters";
import { uniquePayersFrom, uniquePracticesFrom } from "../helper";
import { toDenialsAcrossPayersByPractice } from "./dataset";
import {
  claimToDenialRateByPracticeSeries,
  claimToDenialRatesSeries,
  withFormatting,
} from "./series";

const formatFn = (val: number | null) => formatPercent(val, 2);

interface DenialRatesAcrossPayerAndPracticeProps {
  claims: ClaimWithProcedureMessage[];
}

export default function DenialRatesAcrossPayerAndPractice({
  claims,
}: DenialRatesAcrossPayerAndPracticeProps) {
  return (
    <>
      <h2>Denial Rates Across Payers & Practice</h2>
      {claims.length && (
        <Grid container>
          <Grid item xs={12} sm={6}>
            <h3>Total Denial Rates by Practice Claim</h3>
            <BarChart
              colors={mangoFusionPalette}
              height={400}
              series={claimToDenialRateByPracticeSeries(claims).map(
                withFormatting(formatFn),
              )}
              xAxis={[
                {
                  data: uniquePracticesFrom(claims),
                  scaleType: "band",
                  label: "Practice",
                },
              ]}
              yAxis={[{ label: "Total Denial Rate (%)" }]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h3>Denial Rates by Payers</h3>
            <BarChart
              colors={mangoFusionPalette}
              height={400}
              series={claimToDenialRatesSeries(claims).map(
                withFormatting(formatFn),
              )}
              xAxis={[
                {
                  data: uniquePayersFrom(claims),
                  scaleType: "band",
                  label: "Payer",
                },
              ]}
              yAxis={[{ label: "Denial Rate (%)" }]}
              margin={{
                left: 100,
              }}
              sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translate(-25px, 0)",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <h3>Denial Rates by Payer and Practice Claim</h3>
            <BarChart
              colors={mangoFusionPalette}
              height={400}
              dataset={toDenialsAcrossPayersByPractice(claims)}
              series={uniquePracticesFrom(claims)
                .map<BarSeriesType>(
                  (practice) =>
                    ({
                      dataKey: practice,
                      label: practice,
                    }) as BarSeriesType,
                )
                .map(withFormatting(formatFn))}
              xAxis={[
                {
                  data: uniquePayersFrom(claims),
                  scaleType: "band",
                  label: "Payer",
                },
              ]}
              yAxis={[{ label: "Denial Rate (%)" }]}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
