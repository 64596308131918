export enum BankMatchState {
  NOT_CLEARED,
  AUTO_CLEARED,
}

export enum BankTransactionType {
  INSURANCE,
  UNKNOWN,
  OTHER,
}
