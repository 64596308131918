import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";

export default function PaymentDetailsRenderer(
  props: GridRenderCellParams<PaymentTransactionMessage>,
) {
  const { row } = props;
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          color:
            row.status === "FAILED"
              ? theme.palette.error.dark
              : theme.palette.primary.main,
          fontSize: "14px",
          lineHeight: " 143%",
          letterSpacing: "0.17px",
        }}
      >
        {row.paymentMethodType}
      </Box>
      <Box
        sx={{
          color:
            row.status === "FAILED"
              ? theme.palette.error.dark
              : "rgba(9, 8, 15, 0.65)",
          fontSize: "14px",
          lineHeight: " 143%",
          letterSpacing: "0.17px",
        }}
      >
        {row.paymentMethodMaskedNumber}
      </Box>
    </Box>
  );
}
