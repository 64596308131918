import { Pagination, useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import {
  DataGridPro,
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  gridClasses,
  GridColDef,
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  GridPagination,
  GridRowSpacingParams,
  GridValueFormatterParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useQueryClient from "../../api/query";
import Loading from "../Loading";
import { PaymentPlanStore } from "../PaymentPlans/store";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import DetailPanel from "./DetailPanel";
import EditRenderer from "./EditRenderer";
import PaymentDetailsRenderer from "./PaymentDetailsRenderer";
import StatusRenderer from "./StatusRenderer";
import { PaymentTransactionMessageWithFlag } from "./types";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(_event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter(): React.ReactElement {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}

interface PaymentLedgerProps {
  patient?: PatientMessage;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  paymentPlanStore: PaymentPlanStore;
}

export default function PaymentLedger({
  patient,
  updateSnackBar,
  paymentPlanStore,
}: PaymentLedgerProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [transitions, setTransitions] = React.useState<
    PaymentTransactionMessageWithFlag[] | undefined
  >(undefined);

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
    }),
    [],
  );

  const columns = React.useMemo(() => {
    const defaultColumns: GridColDef<PaymentTransactionMessageWithFlag>[] = [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      },
      {
        width: 130,
        field: "status",
        headerName: "Status",
        renderCell: StatusRenderer,
      },
      !patient && {
        width: 130,
        field: "patientName",
        headerName: "Name",
      },
      !patient && {
        width: 130,
        field: "location",
        headerName: "Location",
      },
      {
        width: 116,
        field: "timestamp",
        headerName: "Date",
      },
      {
        flex: 1.25,
        field: "amount",
        headerName: "Amount",
        valueFormatter: (params: GridValueFormatterParams<MoneyMessage>) => {
          if (params.value == null) {
            return "";
          }
          const Currency = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: params.value.code ?? "USD",
          });
          return Currency.format(
            Number(`${params.value.major}.${params.value.minor}`),
          );
        },
      },
      {
        flex: 1.25,
        field: "remaining",
        headerName: "Remaining",
        valueFormatter: (params: GridValueFormatterParams<MoneyMessage>) => {
          if (params.value == null) {
            return "";
          }
          const Currency = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: params.value.code ?? "USD",
          });
          return Currency.format(
            Number(`${params.value.major}.${params.value.minor}`),
          );
        },
      },
      {
        flex: 1.25,
        field: "paymentType",
        headerName: "Payment Type",
      },
      patient && {
        flex: 1.25,
        field: "paymentName",
        headerName: "Payment Name",
      },
      patient && {
        flex: 1.25,
        field: "details",
        headerName: "Payment Details",
        renderCell: PaymentDetailsRenderer,
      },
      patient && {
        flex: 0.5,
        field: "edit",
        headerName: "",
        cellClassName: "payment-ledger--edit",
        renderCell: EditRenderer,
      },
    ].filter(Boolean) as GridColDef<PaymentTransactionMessageWithFlag>[];

    return defaultColumns;
  }, [patient]);

  const [retrying, setRetrying] = React.useState(false);

  const handleReattempt = useCallback(
    async (paymentPlanId: string, installmentId: string) => {
      setRetrying(true);
      try {
        await queryClient.manuallyReattemptPayment(
          paymentPlanId,
          installmentId,
        );

        const t = (transitions ?? []).map((row) => ({
          ...row,
          hasError: false,
          ...(row.id === installmentId ? { status: "PENDING" } : {}),
        }));

        setTransitions(t as PaymentTransactionMessageWithFlag[]);

        updateSnackBar({
          severity: "success",
          message: "Payment reattempt success.",
        });
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to reattempt payment",
        });
      }
      setRetrying(false);
    },
    [transitions],
  );

  const getDetailPanelContent = React.useCallback<
    NonNullable<
      DataGridProProps<PaymentTransactionMessageWithFlag>["getDetailPanelContent"]
    >
  >(
    ({ row }) => (
      <DetailPanel
        retrying={retrying}
        row={row}
        onReattempt={handleReattempt}
        paymentPlanStore={paymentPlanStore}
        patient={patient}
        updateSnackBar={updateSnackBar}
      />
    ),
    [transitions, retrying],
  );
  const getDetailPanelHeight = React.useCallback(() => "auto", []);

  const getPatientTransactions = useCallback(async (patientId?: string) => {
    if (patientId) {
      const res = await queryClient.getPatientTransactions(patientId);
      if (res !== undefined) {
        const hasError = res.map((row) => row.status).includes("FAILED");
        setTransitions(res.map((row) => ({ ...row, hasError })));
      }
    } else {
      setTransitions([]);
    }
  }, []);

  useEffect(() => {
    getPatientTransactions(patient?.wieldyId);
  }, []);

  if (transitions === undefined) {
    return <Loading />;
  }

  return (
    <div style={{ width: "100%" }}>
      <DataGridPro
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        getRowClassName={(params) => `payment-ledger--${params.row.status}`}
        columnHeaderHeight={36}
        className={
          patient ? "payment-ledger--patient" : "payment-ledger--global"
        }
        sx={{
          [`& .${gridClasses.virtualScrollerRenderZone}`]: {
            width: "100%",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: "0.1px",
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-sortIcon": {
            color: theme.palette.text.primary,
          },
          "& .MuiDataGrid-columnHeaders": {
            marginBottom: "12px",
            borderRadius: "8px",
          },
          "& .payment-ledger--FAILED": {
            backgroundColor: "#FFEBEE",
          },
          "&.payment-ledger--patient .payment-ledger--FAILED": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
          "& .payment-ledger--FAILED .MuiDataGrid-cell .MuiDataGrid-cellContent":
            {
              color: theme.palette.error.dark,
            },

          "& .payment-ledger--edit": {
            paddingRight: 0,
            marginRight: 0,
            minWidth: 0,
            justifyContent: "end",
            height: "100%",
            position: "relative",
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "14px",
            color: theme.palette.text.primary,
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          detailPanel: {
            expandedRowIds: patient ? transitions.map((row) => row.id) : [],
          },
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
            },
          },
        }}
        pageSizeOptions={[10, 20, 40]}
        pagination={!patient}
        slots={{
          pagination: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        onRowClick={(params) => {
          if (!patient) {
            navigate(`/patients/${params.row.patientId}`);
          }
        }}
        rows={transitions}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        disableColumnMenu
      />
    </div>
  );
}

PaymentLedger.defaultProps = {
  patient: undefined,
};
