import { AlertColor } from "@mui/material/Alert/Alert";
import React, { Dispatch } from "react";
import { Route, Routes, useParams } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { isDevMode } from "../../utils/utils";
import PageNotFound from "../PageNotFound";
import { PatientsStore, PatientsStoreAction } from "../PatientsDashboard/store";
import {
  initialPaymentPlanStore,
  PaymentPlanStore,
  PaymentPlanStoreAction,
  paymentPlanStoreReducer,
} from "./store";
import CreatePaymentPlan from "./views/Create";
import { Success } from "./views/create/Success";

interface PaymentPlanProps {
  patientStore: PatientsStore;
  updatePatientStore: Dispatch<PatientsStoreAction>;
  store: PaymentPlanStore;
  updateStore: Dispatch<PaymentPlanStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export { initialPaymentPlanStore, paymentPlanStoreReducer };

export default function PatientsPaymentDashboard({
  patientStore,
  updatePatientStore,
  store,
  updateStore,
  queryClient,
  updateSnackBar,
}: PaymentPlanProps) {
  const { patientId } = useParams();
  const devMode = isDevMode();

  const loadPatientAndClaims = async () => {
    if (patientId) {
      const patientClaims = await queryClient.getPatientClaims(patientId);
      updatePatientStore({ type: "SET_PATIENT", patient: patientClaims });
      const claimIds = (patientClaims?.Claim ?? []).map((c) => c.wieldyId);
      const claims = claimIds.length
        ? await queryClient.getClaimsProceduresAndPatient(claimIds)
        : [];
      updatePatientStore({
        type: "SET_CLAIMS",
        claims,
      });
    }
  };

  React.useEffect(() => {
    if (!patientStore.patient) {
      loadPatientAndClaims();
    }
  }, [patientStore.patient]);

  if (!patientId || !devMode) {
    // Add 404 or redirect here
    return <PageNotFound />;
  }

  return (
    <Routes>
      <Route
        path="/new/*"
        element={
          <CreatePaymentPlan
            patientId={patientId}
            patientStore={patientStore}
            store={store}
            updateStore={updateStore}
            queryClient={queryClient}
            updateSnackBar={updateSnackBar}
          />
        }
      />
      <Route
        path="/:paymentPlanId/success"
        element={
          <Success
            patientId={patientId}
            queryClient={queryClient}
            updateSnackBar={updateSnackBar}
          />
        }
      />
    </Routes>
  );
}
