import { AlertColor } from "@mui/material/Alert/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import React, { useCallback, useEffect, useState } from "react";

import useQueryClient from "../../../api/query";
import Loading from "../../Loading";
import NewPaymentMethod from "../../PaymentPlans/views/NewPaymentMethod";
import AddPaymentMethodCard from "./components/AddPaymentMethodCard";
import PaymentMethodCard from "./components/PaymentMethodCard";

export default function PatientPaymentMethods({
  patient,
  updateSnackBar,
}: {
  patient?: PatientMessage;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const [methods, setMethods] = useState<PatientPaymentMessage[] | undefined>(
    undefined,
  );
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [methodToDelete, setMethodToDelete] = useState<string | undefined>();

  const loadPatientPayments = useCallback(async () => {
    setIsLoading(true);
    if (patient?.wieldyId) {
      try {
        const payments = await queryClient.getPatientPayments(patient.wieldyId);
        setMethods(payments);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to load patient payment methods",
        });
      }
    }
    setIsLoading(false);
  }, [patient?.wieldyId]);

  useEffect(() => {
    if (patient?.wieldyId) {
      loadPatientPayments();
    }
  }, [patient?.wieldyId]);

  const handleSetAsPrimary = async (paymentMethodId: string) => {
    if (patient?.wieldyId) {
      setIsLoading(true);
      try {
        await queryClient.setPatientPrimaryPaymentMethod(
          patient?.wieldyId,
          paymentMethodId,
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to set payment method as primary",
        });
      }
      loadPatientPayments();
    }
  };

  const handleDeletePaymentMethod = async () => {
    if (patient?.wieldyId && methodToDelete) {
      setIsLoading(true);
      try {
        await queryClient.deletePaymentMethod(
          patient?.wieldyId,
          methodToDelete,
        );
        updateSnackBar({
          severity: "success",
          message: "Payment method removed",
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to delete payment method",
        });
      }
      setMethods(methods?.filter((method) => method.id !== methodToDelete));
      setMethodToDelete(undefined);
      setIsLoading(false);
    }
  };

  if (isLoading || !patient?.wieldyId) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={2}>
        {(methods ?? []).map((method) => (
          <Grid key={method.id} item xs={6}>
            <PaymentMethodCard
              paymentMethod={method}
              canRemove={(methods?.length ?? 0) > 1}
              onSetAsPrimary={handleSetAsPrimary}
              onDelete={setMethodToDelete}
            />
          </Grid>
        ))}
        {patient?.wieldyId && (
          <Grid item xs={6}>
            <AddPaymentMethodCard onClick={() => setAddModalOpen(true)} />
          </Grid>
        )}
      </Grid>
      {addModalOpen && (
        <NewPaymentMethod
          open
          onRequestClose={() => setAddModalOpen(false)}
          onAdd={(paymentMethod) => {
            setAddModalOpen(false);
            setMethods([...(methods ?? []), paymentMethod]);
          }}
          patient={patient}
          queryClient={queryClient}
          updateSnackBar={updateSnackBar}
        />
      )}
      <Dialog
        open={!!methodToDelete}
        onClose={() => setMethodToDelete(undefined)}
        maxWidth="xs"
      >
        <DialogContent sx={{ padding: 2, paddingTop: 2.5, paddingBottom: 0 }}>
          <DialogContentText color="black">
            Are you sure you want to permanently delete this payment method?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={() => setMethodToDelete(undefined)}>No</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePaymentMethod}
            autoFocus
          >
            Delete Payment Method
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PatientPaymentMethods.defaultProps = {
  patient: undefined,
};
