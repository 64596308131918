/* eslint-disable indent */
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import dayjs from "dayjs";
import React, { useState } from "react";

import { PaymentPlanStore } from "../PaymentPlans/store";
import { RetryInstallmentDialog } from "./RetryInstallmentDialog";

export default function DetailPanel({
  row,
  onReattempt,
  retrying,
  paymentPlanStore,
  updateSnackBar,
  patient,
}: {
  row: PaymentTransactionMessage;
  retrying: boolean;
  onReattempt: (paymentPlanId: string, installmentId: string) => void;
  paymentPlanStore: PaymentPlanStore;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  patient?: PatientMessage;
}) {
  const theme = useTheme();
  const [toggleRetryInstallmentDialog, setToggleRetryInstallmentDialog] =
    useState(false);

  if (row.status !== "FAILED") {
    return null;
  }

  return (
    <Box
      sx={{
        marginTop: "1px",
        backgroundColor: "#FFEBEE",
        borderRadius: "12px",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          paddingY: theme.spacing(1),
          paddingLeft: "10px",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        {row.attempts.map((attempt, index) => (
          <>
            <Box
              key={attempt.id}
              sx={{
                marginTop: index === 0 ? 0 : theme.spacing(1),
                display: "flex",
                paddingX: "32px",
              }}
            >
              <Box
                sx={{
                  color: theme.palette.error.dark,
                  fontSize: "12px",
                  lineHeight: " 166%",
                  letterSpacing: "0.4px",
                  textTransform: "uppercase",
                  marginRight: theme.spacing(1),
                  width: "260px",
                  flexShrink: 0,
                  flexGrow: 0,
                }}
              >
                {attempt.status}
              </Box>
              <Box
                sx={{
                  color: theme.palette.error.dark,
                  fontSize: "12px",
                  lineHeight: " 166%",
                  letterSpacing: "0.4px",
                  flexShrink: 1,
                  flexGrow: 1,
                }}
              >
                {attempt.message ?? "Payment failed"}
              </Box>
              <Box
                sx={{
                  color: theme.palette.error.dark,
                  fontSize: "12px",
                  lineHeight: " 166%",
                  letterSpacing: "0.4px",
                  flexShrink: 0,
                  flexGrow: 0,
                  marginLeft: "auto",
                }}
              >
                {dayjs(attempt.timestamp).format("MM/DD/YYYY - h:mm A")}
              </Box>
            </Box>
            {patient && (
              <RetryInstallmentDialog
                open={toggleRetryInstallmentDialog}
                paymentPlanStore={paymentPlanStore}
                primaryOnClick={() => onReattempt(row.paymentPlanId, row.id)}
                close={() => setToggleRetryInstallmentDialog(false)}
                patient={patient}
                updateSnackBar={updateSnackBar}
              />
            )}
          </>
        ))}
      </Box>
      {patient && (
        <Tooltip title="Manually reattempt payment" placement="left" arrow>
          <Box
            data-testid="reattemptPaymentButton"
            component="button"
            type="button"
            sx={{
              display: "flex",
              flexGrow: 0,
              flexShrink: 0,
              width: "40px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#E6E6EA",
              color:
                row.tryability === "RETRY"
                  ? "currentColor"
                  : "rgba(9, 8, 15, 0.2)",
            }}
            onClick={() => {
              setToggleRetryInstallmentDialog(true);
            }}
            disabled={retrying || row.tryability !== "RETRY"}
          >
            <RefreshIcon />
            <Box sx={visuallyHidden}>Manually reattempt payment</Box>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

DetailPanel.defaultProps = {
  patient: undefined,
};
