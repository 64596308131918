import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridRowSpacingParams,
  GridToolbar,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { head } from "lodash";
import React, { useEffect, useState } from "react";

import { formatMoney } from "../../../../../formatters";
import { isDevMode } from "../../../../../utils/utils";
import CustomNoRowsOverlay from "../../../../TableNoDataOverlay";
import { uniquePayersFrom } from "../helper";
import { denialDescriptions } from "./rows";

function defaultPayerFilterFrom(
  claims: ClaimWithProcedureMessage[],
): GridFilterItem {
  const payers = uniquePayersFrom(claims);
  const toGridFilterItem = (payer: string) => ({
    field: "payer",
    operator: "equals",
    value: payer,
  });
  if (payers.length > 1 && payers.includes("DentaQuest")) {
    return toGridFilterItem("DentaQuest");
  }
  return toGridFilterItem(head(payers) ?? "");
}

function getColumns(): GridColDef[] {
  return [
    {
      flex: 0.75,
      field: "payer",
      headerName: "Payer",
    },
    {
      flex: 0.75,
      field: "procedureDenialCode",
      headerName: "Denial Code",
    },
    {
      flex: 0.75,
      field: "denialDescription",
      headerName: "Denial Description",
    },
    {
      flex: 0.75,
      field: "claimCount",
      headerName: "Claim Count",
    },
    {
      flex: 0.75,
      field: "procedureCount",
      headerName: "Procedure Count",
    },
    {
      flex: 0.75,
      field: "submittedAmount",
      headerName: "Submitted Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
  ];
}

interface DenialDescriptionProps {
  claims: ClaimWithProcedureMessage[];
}

export default function DenialDescription({ claims }: DenialDescriptionProps) {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [defaultPayerFilterFrom(claims)],
  });

  useEffect(() => {
    setFilterModel({ items: [defaultPayerFilterFrom(claims)] });
  }, [claims]);

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  return (
    <>
      <h2>Denial by Descriptions</h2>
      <DataGridPro
        rowHeight={24}
        getRowSpacing={getRowSpacing}
        sx={{
          [`& .${gridClasses.virtualScrollerRenderZone}`]: {
            width: "100%",
          },
        }}
        rows={denialDescriptions(claims)}
        columns={getColumns()}
        getRowId={(row) => row.procedureDenialCode}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: isDevMode() ? GridToolbar : undefined,
        }}
        filterModel={filterModel}
        onFilterModelChange={setFilterModel}
      />
    </>
  );
}
