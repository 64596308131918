import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Box from "@mui/material/Box";
// import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";

export default function PaymentDetailsRenderer(
  props: GridRenderCellParams<PaymentTransactionMessage>,
) {
  const { row } = props;

  return (
    // Disable until we can edit payment plans
    // <Tooltip title="Edit payment plan" placement="left" arrow>
    <Box
      component="button"
      type="button"
      sx={{
        display: "flex",
        //   flexGrow: 0,
        //   flexShrink: 0,
        width: "40px",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(9, 8, 15, 0.2)", // disabled color
        backgroundColor: "#E6E6EA", // theme.palette.grey[200] <- close but not exact
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        borderTopRightRadius: "12px",
        borderBottomRightRadius: row.status !== "FAILED" ? "12px" : 0,
      }}
    >
      <ModeEditIcon />
      <Box sx={visuallyHidden}>Edit payment plan</Box>
    </Box>
    // </Tooltip>
  );
}
