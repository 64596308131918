import "./index.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  getApiAudience,
  getAuth0ClientId,
  getAuth0Domain,
} from "./utils/auth0";
import initializeGTM from "./utils/gtm";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

initializeGTM();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={getAuth0Domain()}
      clientId={getAuth0ClientId()}
      authorizationParams={{
        redirect_uri: `${window.location.origin}${window.location.search}`,
        audience: getApiAudience(),
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
