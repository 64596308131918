import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { PropsWithChildren, ReactNode } from "react";

interface DetailsCardProps {
  title: string;
  headerAdornment?: ReactNode;
}

export default function DetailsCard({
  title,
  headerAdornment,
  children,
}: PropsWithChildren<DetailsCardProps>) {
  const theme = useTheme();
  return (
    <Paper variant="outlined" sx={{ padding: theme.spacing(3) }}>
      <Grid
        container
        justifyContent="space-between"
        sx={{ marginBottom: theme.spacing(3) }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, lineHeight: 2 }}>
          {title}
        </Typography>
        {headerAdornment}
      </Grid>
      {children}
    </Paper>
  );
}

DetailsCard.defaultProps = {
  headerAdornment: null,
};
