import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { PaymentMethodHeader } from "./PaymentMethod";

export function SMSPaymentMethod() {
  const theme = useTheme();

  return (
    <Box
      component="label"
      border={1}
      borderColor={theme.palette.divider}
      borderRadius={1}
      paddingX={2.5}
      paddingY={3}
      marginBottom={1}
    >
      <PaymentMethodHeader disabled>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            alignItems: "flex-start",
          }}
        >
          <img
            src="/static/images/sms-icon.svg"
            alt="sms"
            style={{ marginRight: "8px" }}
          />

          <Box sx={{ marginRight: "auto", width: "65%" }}>
            <Typography
              sx={{ color: "#09080F", fontSize: "18px", fontWeight: 600 }}
            >
              Send SMS
            </Typography>
            <Typography variant="body2" display="flex" alignItems="center">
              The patient will be able to update or add a new payment method
            </Typography>
          </Box>
        </Box>
      </PaymentMethodHeader>
    </Box>
  );
}
