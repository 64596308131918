import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { DataTestProps } from "../../../utils/utils";
import PatientField, { PatientFieldChangeHandler } from "./PatientField";

export interface ResponsibleDetailsFields {
  responsibleName?: string;
  responsibleSsn?: string;
  responsibleMobilePhone?: string;
  responsibleWorkPhone?: string;
  responsibleEmail?: string;
  responsibleAddress?: string;
  responsibleCity?: string;
  responsibleState?: string;
  responsibleZipCode?: string;
}

export function toResponsibleDetailsFields(
  patient: PatientMessage,
): ResponsibleDetailsFields {
  return {
    responsibleName: patient.responsibleName ?? undefined,
    responsibleSsn: patient.responsibleSsn ?? undefined,
    responsibleMobilePhone: patient.responsibleMobilePhone ?? undefined,
    responsibleWorkPhone: patient.responsibleWorkPhone ?? undefined,
    responsibleEmail: patient.responsibleEmail ?? undefined,
    responsibleAddress: patient.responsibleAddress ?? undefined,
    responsibleCity: patient.responsibleCity ?? undefined,
    responsibleState: patient.responsibleState ?? undefined,
    responsibleZipCode: patient.responsibleZipCode ?? undefined,
  };
}

interface ResponsibleDetailsFormProps {
  values: ResponsibleDetailsFields;
  editable?: boolean;
  showForm?: boolean;
  onClickCheckbox?: () => void;
  onUpdate?: (update: ResponsibleDetailsFields) => void;
}

export default function ResponsibleDetailsForm({
  values,
  editable,
  showForm,
  onClickCheckbox,
  onUpdate,
}: ResponsibleDetailsFormProps) {
  const theme = useTheme();
  const disabled = !editable;
  const onChange: PatientFieldChangeHandler = (name, value) => {
    if (!onUpdate) {
      return;
    }
    const updated: ResponsibleDetailsFields = {
      ...values,
      [name]: value,
    };
    onUpdate(updated);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={
                {
                  "data-testid": "responsibleDetailsCheckbox",
                } as DataTestProps
              }
              checked={showForm}
              onChange={onClickCheckbox}
              disabled={disabled}
            />
          }
          label="Responsible Details"
        />
        <Divider
          orientation="vertical"
          sx={{ margin: `0 ${theme.spacing(2)}` }}
          flexItem
        />
        <Typography
          variant="caption"
          style={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.secondary,
          }}
        >
          Check this field to add specific details for the responsible person of
          this patient
        </Typography>
      </Box>
      {showForm && (
        <Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Responsible Name"
              name="responsibleName"
              value={values.responsibleName}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Responsible SSN"
              name="responsibleSsn"
              value={values.responsibleSsn}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Mobile Number"
              name="responsibleMobilePhone"
              value={values.responsibleMobilePhone}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Work Phone Number"
              name="responsibleWorkPhone"
              value={values.responsibleWorkPhone}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Email"
              name="responsibleEmail"
              value={values.responsibleEmail}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PatientField
              label="Address"
              name="responsibleAddress"
              value={values.responsibleAddress}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PatientField
              label="City"
              name="responsibleCity"
              value={values.responsibleCity}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PatientField
              label="State"
              name="responsibleState"
              value={values.responsibleState}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PatientField
              label="ZIP Code"
              name="responsibleZipCode"
              value={values.responsibleZipCode}
              onChange={onChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

ResponsibleDetailsForm.defaultProps = {
  editable: false,
  showForm: false,
  onClickCheckbox: undefined,
  onUpdate: undefined,
};
