import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Button from "@mui/material/Button";
import React, { ReactElement, useEffect, useState } from "react";

import useApi from "../../api/apiConfig";

export function toPDFFileName(
  payer: string | null,
  date: string | null,
  index?: number,
): string {
  const prefix = payer
    ? `${payer.toLowerCase().replaceAll(/ /g, "_")}`
    : "document";
  const suffix = date ? `${date.toLowerCase()}` : "download";
  if (index) {
    return `${prefix}-${suffix}_${index}.pdf`;
  }
  return `${prefix}-${suffix}.pdf`;
}

interface DownloadSourceProps {
  url: string;
  downloadFileName: string;
  showDownloadText?: boolean;
}

export default function DownloadSource({
  url,
  downloadFileName,
  showDownloadText,
}: DownloadSourceProps): ReactElement | null {
  const { getClient } = useApi();
  const [downloadLink, setDownloadLink] = useState<string>();

  useEffect(() => {
    let link: string;
    const createLink = async () => {
      const api = await getClient();
      const response = await api.get(url, { responseType: "blob" });
      link = URL.createObjectURL(response.data);
      setDownloadLink(link);
    };

    createLink().catch((error) => {
      // eslint-disable-next-line no-console
      console.error(`Failed to create pdf download link: ${error}`);
    });

    return () => {
      if (link) {
        URL.revokeObjectURL(link);
      }
    };
  }, [url]);

  return (
    // @ts-expect-error: Material-UI allows setting arbitrary props on their components. These props are passed down
    // to the underlying root component/element. Here we want to pass the 'download' prop down to the underlying
    // anchor tag. This is supported, however it seems the type declarations for the Button component doesn't reflect that.
    // see: https://github.com/mui/material-ui/issues/6243
    <Button
      style={{ textTransform: "none" }}
      href={downloadLink}
      rel="noreferrer"
      aria-label="Download PDF"
      download={downloadFileName}
    >
      {showDownloadText ? "Download PDF" : null}
      <CloudDownloadIcon
        style={{ marginLeft: showDownloadText ? "8px" : "0px" }}
      />
    </Button>
  );
}

DownloadSource.defaultProps = {
  showDownloadText: false,
};
