/* eslint-disable prefer-arrow-callback */
import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import { SxProps } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";

import {
  FINIX_APPLICATION_ID,
  FINIX_ENVIRONMENT,
} from "../../../api/apiConfig";
import Loading from "../../Loading";
import {
  PaymentMethod,
  PaymentMethodBody,
  PaymentMethodHeader,
} from "../../PaymentPlans/views/create/Payment/PaymentMethod";
import { useFinix } from "../../PaymentPlans/views/create/Payment/useFinix";

interface PaymentProps {
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps;
}

export interface NewPaymentMethodRef {
  submit: (callback: (token: string | undefined) => void) => void;
}

export default forwardRef(function NewPaymentMethod(
  { updateSnackBar, sx = {} }: PaymentProps,
  ref: React.ForwardedRef<NewPaymentMethodRef | undefined>,
) {
  const theme = useTheme();
  const [selectedMethod, setSelectedMethod] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState(false);

  const { loading, form, loadBankPaymentMethod, loadCardPaymentMethod } =
    useFinix();

  const formRef = useRef(form);
  formRef.current = form;

  const selectedMethodRef = useRef(selectedMethod);
  selectedMethodRef.current = selectedMethod;

  const handlePaymentMethodChange = useCallback(
    (_: React.ChangeEvent, value: string) => {
      if (value === "__bank__") {
        loadBankPaymentMethod("method-bank");
        setSelectedMethod("__bank__");
      } else {
        loadCardPaymentMethod("method-card");
        setSelectedMethod("__credit__");
      }
    },
    [],
  );

  useImperativeHandle(
    ref,
    () => ({
      submit: (callback) => {
        if (!selectedMethodRef.current) {
          callback(undefined); // No payment method selected
        } else if (!formRef.current) {
          updateSnackBar({
            severity: "error",
            message:
              "Failed to create payment method, please reload the page and retry",
          });
        } else {
          setSubmitting(true);
          formRef.current.submit(
            FINIX_ENVIRONMENT,
            FINIX_APPLICATION_ID,
            async (err, res) => {
              if (err) {
                let message = "Failed to create payment method";
                if (err?.status === 400) {
                  message = "Invalid payment information";
                }
                updateSnackBar({
                  severity: "error",
                  message,
                });
              } else {
                callback(res?.data?.id);
              }
              setSubmitting(false);
            },
          );
        }
      },
    }),
    [],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Container
      sx={{
        paddingY: theme.spacing(4),
        paddingBottom: 0,
        position: "relative",
        ...sx,
      }}
    >
      {submitting && (
        <Box
          sx={{
            zIndex: 100,
            background: "rgba(255,255,255,.7)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Loading />
        </Box>
      )}
      <RadioGroup
        name="payment-method"
        value={selectedMethod}
        onChange={handlePaymentMethodChange}
      >
        <PaymentMethod value="__bank__">
          <PaymentMethodHeader>
            <Box display="flex" alignItems="start">
              <Box paddingTop="7px">
                <AccountBalanceOutlined />
              </Box>
              <Box marginLeft={2}>
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  lineHeight="160%"
                >
                  Savings or Checking Account
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  lineHeight="157%"
                  color={theme.palette.text.secondary}
                >
                  Payments associated to a bank account
                </Typography>
              </Box>
            </Box>
          </PaymentMethodHeader>
          <PaymentMethodBody>
            <Box id="method-bank" />
          </PaymentMethodBody>
        </PaymentMethod>

        <PaymentMethod value="__credit__">
          <PaymentMethodHeader>
            <Box display="flex" alignItems="start">
              <Box paddingTop="4px">
                <CreditCardOutlined />
              </Box>
              <Box marginLeft={2}>
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  lineHeight="160%"
                >
                  Credit or Debit Card
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  lineHeight="157%"
                  color={theme.palette.text.secondary}
                >
                  Payments through a credit or debit card
                </Typography>
              </Box>
            </Box>
          </PaymentMethodHeader>
          <PaymentMethodBody>
            <Box id="method-card" />
          </PaymentMethodBody>
        </PaymentMethod>
      </RadioGroup>
    </Container>
  );
});
