/* eslint-disable react/no-unused-prop-types */
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import AutoSizer from "react-virtualized-auto-sizer";

import type QueryClient from "../../../../../api/query/queryClient";
import { numberToMoneyMessage } from "../../../../../utils/utils";
import SignaturePad, { SignaturePadRef } from "../../../../SignaturePad";
import { PaymentPlanStore } from "../../../store";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

const paperSizeInInches = {
  width: 8.5,
  height: 11,
};

interface ContractProps {
  mode: "view" | "sign";
  store: PaymentPlanStore;
  patientId: string;
  onSign: (signature: string) => void;
  queryClient: QueryClient;
}

export default function Contract({
  mode,
  onSign,
  store,
  patientId,
  queryClient,
}: ContractProps) {
  const signaturePadRef = useRef<SignaturePadRef>(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const [pdf, setPdf] = useState<Blob | undefined>(undefined);
  const [loadingError, setLoadingError] = useState<Error | undefined>(
    undefined,
  );

  useEffect(() => {
    if (showSignatureModal) {
      setHasSigned(false);
      setTimeout(() => {
        // need to wait for the signature pad to be initialized
        signaturePadRef.current?.addEventListener("endStroke", () => {
          setHasSigned(true);
        });
      }, 1);
    }
    return () => {
      signaturePadRef.current?.off();
    };
  }, [showSignatureModal]);

  // Load PDF
  useEffect(() => {
    const getUnsignedPDF = async () => {
      if (!store.paymentTerms) {
        try {
          throw new Error("Payment terms are not defined");
        } catch (error) {
          setLoadingError(error as Error);
          return;
        }
      }

      const installments = [];
      let currentInstallment = store.paymentTerms.withdrawalBeginDate;
      const { contractPeriod, contractValue, installmentAmount } =
        store.paymentTerms;

      for (let i = 0; i < contractPeriod; i += 1) {
        // If last installment, calculate remaining amount
        if (i === contractPeriod - 1) {
          const amount = contractValue - installmentAmount * i;
          installments.push({
            date: currentInstallment.toISOString(),
            amount: numberToMoneyMessage(amount),
          });
        } else {
          installments.push({
            date: currentInstallment.toISOString(),
            amount: numberToMoneyMessage(installmentAmount),
          });
          currentInstallment = currentInstallment.add(30, "day");
        }
      }

      try {
        const res = await queryClient.postPatientPaymentPlanUnsignedPdf(
          patientId,
          {
            id: store?.id ?? "",
            signatureDate: dayjs().format("YYYY-MM-DD"),
            paymentMethodId: store.paymentMethod?.id ?? "",
            totalAmount: numberToMoneyMessage(store.paymentTerms.totalAmount),
            downPayment: numberToMoneyMessage(store.paymentTerms.downPayment),
            contractAmount: numberToMoneyMessage(
              store.paymentTerms.contractValue,
            ),
            contractPeriod: "PT30D",
            installments,
          },
        );

        setPdf(res);
      } catch (e) {
        setLoadingError(e as Error);
      }
    };
    getUnsignedPDF();
  }, []);

  return (
    <>
      <Box
        width={mode === "sign" ? "100%" : "50%"}
        sx={{
          backgroundColor: "#E6E6EA",
          padding: "16px",
        }}
      >
        {loadingError && (
          <Alert severity="error">
            {loadingError?.message ?? "Unable to load PDF."}
          </Alert>
        )}

        {pdf && (
          <AutoSizer disableHeight>
            {({ width }: { width: number }) => (
              <Box marginX="auto" width={width} sx={{ position: "relative" }}>
                <Document file={pdf}>
                  <Page width={width} pageNumber={1} />
                </Document>
                {mode === "sign" && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom:
                        (0.072 * width * paperSizeInInches.height) /
                        paperSizeInInches.width, // signature starts 7.2% from the bottom
                      left: 0.067 * width, // signature starts 6.7% from the left
                      zIndex: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      size="large"
                      onClick={() => {
                        setShowSignatureModal(true);
                      }}
                    >
                      Click here to add your signature
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </AutoSizer>
        )}
      </Box>
      <Dialog
        open={showSignatureModal}
        maxWidth="lg"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <DialogTitle sx={{ background: "#F5F5F5" }}>Sign Document</DialogTitle>
        <DialogContent
          sx={{ width: "800px", background: "#F5F5F5", position: "relative" }}
        >
          <SignaturePad ref={signaturePadRef} height="200px" />
          <Button
            sx={{
              position: "absolute",
              bottom: "34px",
              right: "32px",
              zIndex: 2,
              display: !hasSigned ? "none" : "block",
            }}
            onClick={() => {
              setHasSigned(false);
              signaturePadRef.current?.clear();
            }}
          >
            Clear
          </Button>
        </DialogContent>
        <DialogActions
          disableSpacing
          sx={{
            background: "#F5F5F5",
            justifyContent: "center",
            paddingBottom: "16px",
            paddingTop: 0,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            onClick={() => setShowSignatureModal(false)}
          >
            Cancel
          </Button>
          <Button
            data-testid="sign"
            variant="contained"
            color="success"
            size="large"
            disabled={!hasSigned}
            onClick={() => {
              const signature = signaturePadRef.current?.toDataURL();

              // Reset
              // signaturePadRef.current?.clear();
              // setHasSigned(false);
              // setShowSignatureModal(false);
              if (signature) {
                onSign(signature);
              }
            }}
            sx={{ marginLeft: "16px" }}
          >
            Sign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
