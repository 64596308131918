import { HeatMapDatum, HeatMapSerie } from "@nivo/heatmap/dist/types/types";

import { ClaimStatus } from "../../../../../status";
import { Action } from "../../../../actions";
import { Urgency } from "../../../../urgencyColors";

// Exclude 'COMPLETED' as we are not surfacing this action in the heatmap. Include
// 'NO_ACTION' as we are using it for claims where action is null.
type ActionsIndex = Omit<
  Record<keyof typeof Action | "NO_ACTION", number>,
  "COMPLETED"
>;

// Add 'NO_URGENCY' as we are using it for claim where urgency is null.
type UrgenciesIndex = Record<keyof typeof Urgency | "NO_URGENCY", number>;

export function emptyHeatMapSeries(
  ids: string[],
  xs: string[],
): HeatMapSerie<HeatMapDatum, object>[] {
  return ids.map((id) => ({
    id,
    data: xs.map((x) => ({ x, y: 0 })),
  }));
}

function toClaimUrgencyActionDenialSeries(
  claims: ClaimWithProcedureMessage[],
): HeatMapSerie<HeatMapDatum, object>[] {
  const deniedClaims = claims
    .filter(
      (claim) =>
        ClaimStatus[claim.claimStatus as keyof typeof ClaimStatus] ===
        ClaimStatus.DENIED,
    )
    .filter(
      (claim) =>
        Action[claim.action as keyof typeof Action] !== Action.COMPLETED,
    );
  const urgenciesIndex: UrgenciesIndex = {
    NO_URGENCY: 0,
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
  };
  const actionsIndex: ActionsIndex = {
    NO_ACTION: 0,
    APPEAL: 1,
    INTERNAL_TEAMMATE: 2,
    WRITE_OFF: 3,
    RESUBMIT: 4,
    PATIENT_RESPONSIBILITY: 5,
    DUPLICATE: 6,
  };
  return deniedClaims.reduce(
    (acc, claim) => {
      const urgencyKey = (claim.urgency ||
        "NO_URGENCY") as keyof typeof urgenciesIndex;
      const actionKey = (claim.action ||
        "NO_ACTION") as keyof typeof actionsIndex;
      const object =
        acc[urgenciesIndex[urgencyKey]].data[actionsIndex[actionKey]];
      object.y = (object.y ?? 0) + 1;
      return acc;
    },
    emptyHeatMapSeries(
      ["No Urgency", "Low", "Medium", "High"],
      [
        "No Action",
        "Appeal",
        "Internal Teammate",
        "Write Off",
        "Resubmit",
        "Patient Responsibility",
        "Duplicate",
      ],
    ),
  );
}

export { toClaimUrgencyActionDenialSeries };
