import { ResponsiveHeatMap } from "@nivo/heatmap";
import React from "react";

import { toClaimUrgencyActionDenialSeries } from "./data";

interface ClaimUrgencyActionForDenialsProps {
  claims: ClaimWithProcedureMessage[];
}

export default function ClaimUrgencyActionForDenials({
  claims,
}: ClaimUrgencyActionForDenialsProps) {
  return (
    <div style={{ height: 400 }}>
      <h4>Heatmap of Claim Urgency and Action for Complete Denials</h4>
      <ResponsiveHeatMap
        data={toClaimUrgencyActionDenialSeries(claims)}
        margin={{ top: 60, right: 60, bottom: 20, left: 120 }}
        axisTop={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Action",
          legendPosition: "middle",
          legendOffset: -50,
        }}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0,
          legend: "Urgency",
          legendPosition: "middle",
          legendOffset: -110,
        }}
        colors={{
          type: "quantize",
          scheme: "cool",
          steps: 10,
        }}
        emptyColor="#25233F"
        borderWidth={1}
        borderColor="#000000"
        legends={[
          {
            anchor: "right",
            translateX: 27,
            translateY: 0,
            length: 200,
            thickness: 10,
            direction: "column",
            tickPosition: "after",
            tickSize: 3,
            tickSpacing: 4,
            tickOverlap: false,
            tickFormat: ">-.2s",
            title: "Number of Claims →",
            titleAlign: "start",
            titleOffset: 4,
          },
        ]}
        annotations={[]}
        hoverTarget="cell"
      />
    </div>
  );
}
