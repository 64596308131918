import { axisClasses } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { mangoFusionPalette } from "@mui/x-charts/colorPalettes";
import React from "react";

import { getMonthLabels } from "../helper";
import { toClaimsByStatusSeries } from "./series";

interface ClaimCategoryBarProps {
  claims: ClaimWithProcedureMessage[];
}

export default function ClaimPaymentTypes({ claims }: ClaimCategoryBarProps) {
  const months = getMonthLabels(claims);
  return (
    <>
      <h2>Claims by Status</h2>
      <BarChart
        colors={mangoFusionPalette}
        height={400}
        series={toClaimsByStatusSeries(claims)}
        xAxis={[{ data: months, scaleType: "band", label: "Month" }]}
        yAxis={[{ label: "Number of Claims" }]}
        margin={{
          left: 100,
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-10px, 0)",
          },
        }}
      />
    </>
  );
}
