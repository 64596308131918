import Grid from "@mui/material/Grid";
import React from "react";

import { formatPercent } from "../../../formatters";
import { redactText } from "../../redact";
import PatientField, { PatientFieldChangeHandler } from "./PatientField";

export interface InsuranceDetailsFields {
  insuranceSubscriberName?: string;
  insuranceSubscriberId?: string;
  insurancePractice?: string;
  insurancePracticeLocation?: string;
  insurance?: string;
  insuranceType?: string;
  insurancePolicyEffectiveDate?: string;
  insurancePatientLifetimeMax?: number;
  insurancePatientLifetimeUsed?: number;
  insuranceCovered?: number;
}

export function toInsuranceDetailsFields(
  patient: PatientMessage,
): InsuranceDetailsFields {
  return {
    insuranceSubscriberName: patient.insuranceSubscriberName ?? undefined,
    insuranceSubscriberId: patient.insuranceSubscriberId ?? undefined,
    insurancePractice: patient.insurancePracticeDisplayName ?? undefined,
    insurancePracticeLocation: patient.insurancePracticeLocation ?? undefined,
    insurance: patient.insurance ?? undefined,
    insuranceType: patient.insuranceType ?? undefined,
    insurancePolicyEffectiveDate:
      patient.insurancePolicyEffectiveDate ?? undefined,
    insurancePatientLifetimeMax:
      patient.insurancePatientLifetimeMax ?? undefined,
    insurancePatientLifetimeUsed:
      patient.insurancePatientLifetimeUsed ?? undefined,
    insuranceCovered: patient.insuranceCovered ?? undefined,
  };
}

interface InsuranceDetailsFormProps {
  values: InsuranceDetailsFields;
  editable?: boolean;
  onUpdate?: (update: InsuranceDetailsFields) => void;
}

export default function InsuranceDetailsForm({
  values,
  editable,
  onUpdate,
}: InsuranceDetailsFormProps) {
  const disabled = !editable;
  const onChange: PatientFieldChangeHandler = (name, value) => {
    if (!onUpdate) {
      return;
    }
    const updated: InsuranceDetailsFields = {
      ...values,
      [name]: value,
    };
    onUpdate(updated);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Subscriber"
          name="insuranceSubscriberName"
          value={redactText(values.insuranceSubscriberName)}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Subscriber ID"
          name="insuranceSubscriberId"
          value={redactText(values.insuranceSubscriberId)}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Practice"
          name="insurancePractice"
          value={values.insurancePractice}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Location"
          name="insurancePracticeLocation"
          value={values.insurancePracticeLocation}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Insurance"
          name="insurance"
          value={values.insurance}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Insurance Type"
          name="insuranceType"
          value={values.insuranceType}
          onChange={onChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Policy Effective Date"
          name="insurancePolicyEffectiveDate"
          onChange={onChange}
          type="date"
          value={values.insurancePolicyEffectiveDate}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Patient's Lifetime Max"
          name="insurancePatientLifetimeMax"
          onChange={onChange}
          type="number"
          value={values.insurancePatientLifetimeMax}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Patient's Lifetime Used"
          name="insurancePatientLifetimeUsed"
          onChange={onChange}
          type="number"
          value={values.insurancePatientLifetimeUsed}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={editable ? 6 : 3}>
        <PatientField
          label="Insurance Covered %"
          name="insuranceCovered"
          onChange={onChange}
          type={editable ? "number" : "string"}
          value={
            !editable
              ? formatPercent(values.insuranceCovered ?? null)
              : values.insuranceCovered
          }
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

InsuranceDetailsForm.defaultProps = {
  editable: false,
  onUpdate: undefined,
};
