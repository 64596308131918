import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";

import {
  FINIX_APPLICATION_ID,
  FINIX_ENVIRONMENT,
} from "../../../api/apiConfig";
import type QueryClient from "../../../api/query/queryClient";
import Loading from "../../Loading";
import Modal from "../../Modal";
import {
  PaymentMethod,
  PaymentMethodBody,
  PaymentMethodHeader,
} from "./create/Payment/PaymentMethod";
import { useFinix } from "./create/Payment/useFinix";

interface PaymentProps {
  open: boolean;
  onRequestClose: () => void;
  onAdd: (paymentMethod: PatientPaymentMessage) => void;
  queryClient: QueryClient;
  patient?: PatientMessage;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function NewPaymentMethod({
  open,
  onRequestClose,
  onAdd,
  patient,
  queryClient,
  updateSnackBar,
}: PaymentProps) {
  const theme = useTheme();
  const [selectedMethod, setSelectedMethod] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState(false);

  const {
    loading,
    form,
    loadBankPaymentMethod,
    loadCardPaymentMethod,
    canSubmit,
  } = useFinix();

  const handlePaymentMethodChange = useCallback(
    (_: React.ChangeEvent, value: string) => {
      if (value === "__bank__") {
        loadBankPaymentMethod("method-bank");
        setSelectedMethod("__bank__");
      } else {
        loadCardPaymentMethod("method-card");
        setSelectedMethod("__credit__");
      }
    },
    [],
  );

  const handleSubmit = useCallback(() => {
    if (!patient?.wieldyId) {
      return;
    }
    if (!form) {
      updateSnackBar({
        severity: "error",
        message:
          "Failed to create payment method, please reload the page and retry",
      });
      return;
    }
    setSubmitting(true);
    form.submit(FINIX_ENVIRONMENT, FINIX_APPLICATION_ID, async (err, res) => {
      if (err) {
        let message = "Failed to create payment method";
        if (err?.status === 400) {
          message = "Invalid payment information";
        }
        updateSnackBar({
          severity: "error",
          message,
        });
      } else {
        try {
          const tokenData = res.data || {};
          const paymentMethod = await queryClient.postPatientPayment(
            patient.wieldyId,
            tokenData.id,
          );
          updateSnackBar({
            severity: "success",
            message: "Payment method added successfully",
          });
          onAdd(paymentMethod);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          updateSnackBar({
            severity: "error",
            message: "Failed to create payment method",
          });
        }
      }
      setSubmitting(false);
    });
  }, [patient?.wieldyId, form]);

  if (loading || !patient?.wieldyId) {
    return (
      <Modal
        title={patient?.name ?? "New Payment Method"}
        open={open}
        onClose={onRequestClose}
      >
        <Loading />
      </Modal>
    );
  }

  const paymentFormComplete = selectedMethod === "" || !canSubmit;

  return (
    <Modal
      title={patient?.name ?? "New Payment Method"}
      open={open}
      onClose={onRequestClose}
    >
      <Container
        sx={{ paddingY: theme.spacing(4), maxWidth: "39.5rem !important" }}
      >
        <RadioGroup
          name="payment-method"
          value={selectedMethod}
          onChange={handlePaymentMethodChange}
        >
          <Typography
            variant="h5"
            sx={{ marginBottom: "16px", marginTop: "20px" }}
          >
            Payment Details
          </Typography>

          <PaymentMethod value="__bank__">
            <PaymentMethodHeader>
              <Box display="flex" alignItems="start">
                <Box paddingTop="7px">
                  <AccountBalanceOutlined />
                </Box>
                <Box marginLeft={2}>
                  <Typography
                    variant="body1"
                    fontSize="18px"
                    fontWeight={600}
                    lineHeight="160%"
                  >
                    Savings or Checking Account
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    lineHeight="157%"
                    color={theme.palette.text.secondary}
                  >
                    Payments associated to a bank account
                  </Typography>
                </Box>
              </Box>
            </PaymentMethodHeader>
            <PaymentMethodBody>
              <Box id="method-bank" />
            </PaymentMethodBody>
          </PaymentMethod>

          <PaymentMethod value="__credit__">
            <PaymentMethodHeader>
              <Box display="flex" alignItems="start">
                <Box paddingTop="4px">
                  <CreditCardOutlined />
                </Box>
                <Box marginLeft={2}>
                  <Typography
                    variant="body1"
                    fontSize="18px"
                    fontWeight={600}
                    lineHeight="160%"
                  >
                    Credit or Debit Card
                  </Typography>
                  <Typography
                    component="div"
                    variant="body2"
                    lineHeight="157%"
                    color={theme.palette.text.secondary}
                  >
                    Payments through a credit or debit card
                  </Typography>
                </Box>
              </Box>
            </PaymentMethodHeader>
            <PaymentMethodBody>
              <Box id="method-card" />
            </PaymentMethodBody>
          </PaymentMethod>

          <Button
            variant="contained"
            color="success"
            size="large"
            fullWidth
            sx={{ marginTop: "28px" }}
            disabled={paymentFormComplete || submitting}
            onClick={handleSubmit}
          >
            {submitting ? "Saving..." : "Save new payment method"}
          </Button>
        </RadioGroup>
      </Container>
    </Modal>
  );
}

NewPaymentMethod.defaultProps = {
  patient: undefined,
};
