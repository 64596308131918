export type ReportsStore = {
  payments: PaymentMessage[];
  claims: ClaimWithProcedureMessage[];
};

export const initialReportsStore: ReportsStore = {
  payments: [],
  claims: [],
};

export type ReportsStoreAction =
  | {
      type: "SET_REPORTS";
      payments: PaymentMessage[];
      claims: ClaimWithProcedureMessage[];
    }
  | { type: "RESET_REPORTS" };

export function reportsStoreReducer(
  store: ReportsStore,
  action: ReportsStoreAction,
): ReportsStore {
  switch (action.type) {
    case "SET_REPORTS":
      return {
        ...store,
        payments: action.payments,
        claims: action.claims,
      };
    case "RESET_REPORTS":
      return {
        ...store,
        payments: [],
        claims: [],
      };
    default:
      return store;
  }
}
