import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

export default function AddPaymentMethodCard({
  onClick,
}: {
  onClick: () => void;
}) {
  const theme = useTheme();
  return (
    <Box
      data-testid="addPaymentMethod"
      component="a"
      onClick={onClick}
      border={1}
      borderColor={theme.palette.divider}
      borderRadius={1}
      color={theme.palette.info.main}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      sx={{
        minHeight: "160px",
        textDecoration: "none",
        ":hover": {
          textDecoration: "underline",
        },
      }}
    >
      <AddCircleOutlineIcon />
      <Typography
        variant="body1"
        fontSize="14px"
        fontWeight={500}
        lineHeight="24px"
        marginTop="4px"
      >
        Add new payment method
      </Typography>
    </Box>
  );
}
