import { formatFixedNumber } from "../../../../../utils/utils";
import { MONTH_LABELS } from "../helper";

type AvgPaymentByProcedureRow =
  | { procedureCode: string }
  | {
      [key: string]: number;
    };

type ClaimSumRow = {
  month: string;
  procedures: number;
  procedureDenials: number;
  insurancePays: number;
  submittedAmount: number;
  contractedAmount: number;
  patientResponsibility: number;
};

type DenialByProcedureRow = {
  procedureCode: string;
  denialRate: number;
  submittedAmount: number;
  contractedAmount: number;
  insurancePays: number;
  patientResponsibility: number;
  totalProcedureDenials: number;
  procedureDenials: number;
};

type DenialDescriptionRow = {
  payer: string;
  procedureDenialCode: string;
  claimCount: number;
  procedureCount: number;
  submittedAmount: number;
  denialDescription: string;
};

type ClaimSumRowWithRate = ClaimSumRow & {
  denialsRate: number;
};

function calcAverage(inputs: number[]) {
  const sum = inputs.reduce((acc, curr) => acc + curr, 0);
  return sum / inputs.length;
}

function averagePaymentByProcedure(
  claims: ClaimWithProcedureMessage[],
): AvgPaymentByProcedureRow[] {
  const obj: { [key: string]: { [key: string]: number[] } } = {};
  claims.forEach((c) => {
    c.Procedure.forEach((p) => {
      if (!p.procedureCode || p.procedureDenialCode) {
        return;
      }
      if (!obj[p.procedureCode]) {
        obj[p.procedureCode] = {};
      }
      if (!obj[p.procedureCode][c.payer]) {
        obj[p.procedureCode][c.payer] = [];
      }
      obj[p.procedureCode][c.payer].push(p.procedurePayerPays || 0);
    });
  });
  const rows: AvgPaymentByProcedureRow[] = [];
  Object.entries(obj).forEach(([code, payments]) => {
    const row: AvgPaymentByProcedureRow = Object.keys(
      payments,
    ).reduce<AvgPaymentByProcedureRow>(
      (acc, payer) => ({ ...acc, [payer]: calcAverage(payments[payer]) }),
      { procedureCode: code },
    );
    rows.push(row);
  });
  return rows;
}

function claimSumRows(
  claims: ClaimWithProcedureMessage[],
): ClaimSumRowWithRate[] {
  const intialClaimSumRows = MONTH_LABELS.map((month: string) => ({
    month,
    procedures: 0,
    procedureDenials: 0,
    insurancePays: 0,
    submittedAmount: 0,
    contractedAmount: 0,
    patientResponsibility: 0,
  }));
  const allMonths = claims.reduce((acc, claim) => {
    if (!claim.wieldyClaimDate) {
      return acc;
    }
    const month = new Date(claim.wieldyClaimDate).getUTCMonth();

    acc[month].procedures += claim.Procedure ? claim.Procedure.length : 0;
    acc[month].procedureDenials += claim.Procedure.reduce(
      (total, procedure) =>
        total + (procedure.procedurePayerPays === 0 ? 1 : 0),
      0,
    );
    acc[month].insurancePays += claim.Procedure.reduce(
      (total, procedure) => total + (procedure.procedurePayerPays ?? 0),
      0,
    );
    acc[month].submittedAmount += claim.Procedure.reduce(
      (total, procedure) => total + (procedure.procedureSubmittedAmount ?? 0),
      0,
    );
    acc[month].contractedAmount += claim.Procedure.reduce(
      (total, procedure) => total + (procedure.procedureContractedAmount ?? 0),
      0,
    );
    acc[month].patientResponsibility += claim.Procedure.reduce(
      (total, procedure) =>
        total + (procedure.procedurePatientResponsibility ?? 0),
      0,
    );

    return acc;
  }, intialClaimSumRows);
  return allMonths
    .filter((month: ClaimSumRow) => month.procedures > 0)
    .map((month: ClaimSumRow) => ({
      ...month,
      denialsRate: formatFixedNumber(
        (month.procedureDenials / month.procedures) * 100,
        2,
      ),
    }));
}

/** Returns map from procedure codes to the rates at which they've been entirely
 * denied (procedurePayerPays === 0).
 */
function mapDenialRates(
  procedures: Array<ProcedureMessage>,
): Map<string, number> {
  const fractions: Map<string, [number, number]> = new Map();
  procedures.forEach((procedure) => {
    const { procedureCode } = procedure;
    if (procedureCode === undefined || procedureCode === null) {
      return;
    }
    const [denials, total] = fractions.get(procedureCode) ?? [0, 0];
    fractions.set(procedureCode, [
      denials + (procedure.procedurePayerPays === 0 ? 1 : 0),
      total + 1,
    ]);
  });
  const denialRates: Map<string, number> = new Map();
  fractions.forEach(([numerator, denominator], procedureCode) => {
    denialRates.set(procedureCode, numerator / denominator);
  });
  return denialRates;
}

function denialsByProcedureCode(
  claims: ClaimWithProcedureMessage[],
): DenialByProcedureRow[] {
  const procedures = claims.map((claim) => claim.Procedure).flat();
  const proceduresCodes: string[] = Array.from(
    new Set(
      procedures
        .filter((procedure) => procedure.procedureCode !== null)
        .map((procedure) => procedure.procedureCode),
    ),
  ) as string[];

  const denialRates = mapDenialRates(procedures);

  const initialDenialsByProcedureRow = proceduresCodes.reduce(
    (acc, procedureCode) => {
      acc[procedureCode] = {
        procedureCode,
        submittedAmount: 0,
        denialRate: denialRates.get(procedureCode) ?? 0,
        contractedAmount: 0,
        insurancePays: 0,
        patientResponsibility: 0,
        totalProcedureDenials: 0,
        procedureDenials: 0,
      };
      return acc;
    },
    {} as { [key: string]: DenialByProcedureRow },
  );

  return Object.values(
    procedures.reduce((acc, procedure) => {
      if (procedure.procedureCode !== null) {
        acc[procedure.procedureCode].totalProcedureDenials += 1;

        if (procedure.procedurePayerPays === 0) {
          acc[procedure.procedureCode].submittedAmount +=
            procedure.procedureSubmittedAmount ?? 0;
          acc[procedure.procedureCode].contractedAmount +=
            procedure.procedureContractedAmount ?? 0;
          acc[procedure.procedureCode].insurancePays +=
            procedure.procedurePayerPays ?? 0;
          acc[procedure.procedureCode].patientResponsibility +=
            procedure.procedurePatientResponsibility ?? 0;
          acc[procedure.procedureCode].procedureDenials +=
            procedure.procedurePayerPays === 0 ? 1 : 0;
        }
      }
      return acc;
    }, initialDenialsByProcedureRow),
  );
}

function denialDescriptions(claims: ClaimWithProcedureMessage[]) {
  return Object.values(
    claims.reduce(
      (acc, claim) => {
        if (!claim.Procedure) {
          return acc;
        }
        const { payer } = claim;
        const proceduresUpdates = new Set<string>();
        claim.Procedure.forEach((procedure) => {
          if (procedure.procedurePayerPays === 0) {
            const { procedureDenialCode } = procedure;
            if (procedureDenialCode) {
              if (!acc[`${payer}-${procedureDenialCode}`]) {
                acc[`${payer}-${procedureDenialCode}`] = {
                  payer,
                  procedureDenialCode,
                  claimCount: 0,
                  procedureCount: 0,
                  submittedAmount: 0,
                  denialDescription: procedure.procedureDenialDescription ?? "",
                };
              }
              acc[`${payer}-${procedureDenialCode}`].procedureCount += 1;
              acc[`${payer}-${procedureDenialCode}`].submittedAmount +=
                procedure.procedureSubmittedAmount ?? 0;
              proceduresUpdates.add(`${payer}-${procedureDenialCode}`);
            }
          }
        });
        proceduresUpdates.forEach((payerProcedure) => {
          acc[payerProcedure].claimCount += 1;
        });
        return acc;
      },
      {} as { [key: string]: DenialDescriptionRow },
    ),
  );
}

export {
  averagePaymentByProcedure,
  claimSumRows,
  denialsByProcedureCode,
  denialDescriptions,
};
