import isMobilePhone from "validator/es/lib/isMobilePhone";

import type {
  PatientDetailsFields,
  ResponsibleDetailsFields,
} from "./patientsDashboard.interfaces";

export const isUSMobileNumber = (input: string) =>
  isMobilePhone(input, "en-US");

export const hasTruthyProperty = <T extends object>(
  obj: T | null | undefined,
): boolean => {
  if (obj === null || obj === undefined) {
    return false;
  }

  const values = Object.values(obj);
  return values.some((value) => value);
};

export const removeNonDigits = (value: string) => {
  if (!value) return value;
  return value.replace(/\D/g, "");
};

export const formatSSN = (value?: string | null) => {
  if (!value) return value;

  const ssnFormattingThreshold = 5;
  const cleaned = removeNonDigits(value);

  if (cleaned.length <= ssnFormattingThreshold) {
    return value;
  }

  return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5)}`;
};

export function toPatientDetailsFields(
  patient: PatientMessage,
): PatientDetailsFields {
  return {
    name: patient.name ?? undefined,
    ssn: patient.ssn ?? undefined,
    dob: patient.dob ?? undefined,
    phoneNumber: patient.phoneNumber ?? undefined,
    email: patient.email ?? undefined,
    address: patient.address ?? undefined,
    city: patient.city ?? undefined,
    state: patient.state ?? undefined,
    zipCode: patient.zipCode ?? undefined,
    practiceId: patient.practiceId ?? undefined,
  };
}

export function toResponsibleDetailsFields(
  patient: PatientMessage,
): ResponsibleDetailsFields {
  return {
    responsibleName: patient.responsibleName ?? undefined,
    responsibleSsn: patient.responsibleSsn ?? undefined,
    responsibleMobilePhone: patient.responsibleMobilePhone ?? undefined,
    responsibleWorkPhone: patient.responsibleWorkPhone ?? undefined,
    responsibleEmail: patient.responsibleEmail ?? undefined,
    responsibleAddress: patient.responsibleAddress ?? undefined,
    responsibleCity: patient.responsibleCity ?? undefined,
    responsibleState: patient.responsibleState ?? undefined,
    responsibleZipCode: patient.responsibleZipCode ?? undefined,
  };
}
