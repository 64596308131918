import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WelcomePageProps {}

// eslint-disable-next-line no-empty-pattern
export function WelcomePage({}: WelcomePageProps) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          maxWidth: "800px",
          marginBottom: 8,
          overflowY: "auto",
          flexGrow: 1,
          padding: 2,
          marginTop: 6,
        }}
      >
        <Box sx={{ marginBottom: 4 }}>
          <img
            src="/static/images/practice-placeholder-logo.png"
            alt="Practice logo"
            width={112}
            height={110}
          />
        </Box>
        <Typography variant="h5" marginBottom={2}>
          [PRACTICE NAME] is requesting you to update your payment details
        </Typography>
        <Typography variant="body1" marginBottom={2}>
          Please provide us with your payment details so we can process your
          dental bill payment online and keep your account up-to-date to speed
          up the billing process. Thank you for your prompt response.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
          borderTop: "1px solid rgba(37, 35, 63, 0.12)",
          padding: 2,
          backgroundColor: "white",
        }}
      >
        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{ maxWidth: "800px" }}
          onClick={() => navigate(`/patient-portal/123/payment-methods`)}
        >
          Update payment details
        </Button>
      </Box>
    </Box>
  );
}
