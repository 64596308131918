import PersonAddAlt from "@mui/icons-material/PersonAddAlt";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, useLocation } from "react-router-dom";

interface PatientListHeaderProps {
  practices: PracticeMessage[];
}

export default function PatientListHeader({
  practices,
}: PatientListHeaderProps) {
  const searchParams = new URLSearchParams(useLocation().search);
  const theme = useTheme();
  const hasPractice = practices.length > 0;
  return (
    <Grid
      container
      sx={{
        padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
        borderBottom: "1px solid rgba(37, 35, 63, 0.12)",
      }}
    >
      <Grid container flexDirection="row" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            <span style={{ marginRight: theme.spacing(2) }}>Patients</span>
            <span>
              <Chip
                label="Beta"
                size="small"
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                }}
              />
            </span>
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ margin: `0 ${theme.spacing(3)}` }}
            flexItem
          />
          <Typography
            variant="caption"
            sx={{ fontSize: "14px", color: theme.palette.text.secondary }}
          >
            Find your patients. Click on one of the rows to see more details.
          </Typography>
        </Grid>
        {hasPractice ? (
          <Link to={`/patients/new-patient?${searchParams}`}>
            <Button variant="contained" startIcon={<PersonAddAlt />}>
              Add Patient
            </Button>
          </Link>
        ) : (
          <Button variant="contained" startIcon={<PersonAddAlt />} disabled>
            Add Patient
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
