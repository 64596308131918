import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { type Dayjs } from "dayjs";
import React from "react";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function TermTableWithValues({
  contractValue,
  contractPeriod,
  withdrawalBeginDate,
  installmentAmount,
}: {
  contractValue: number;
  contractPeriod: number;
  withdrawalBeginDate: Dayjs;
  installmentAmount: number;
}) {
  const rows = [];
  let currentInstallment = withdrawalBeginDate;

  for (let i = 0; i < contractPeriod; i += 1) {
    if (i === contractPeriod - 1) {
      rows.push({
        installment: currentInstallment.format("MM/DD/YYYY"),
        amount: USDollar.format(contractValue - installmentAmount * i),
        remaining: USDollar.format(0),
      });
    } else {
      rows.push({
        installment: currentInstallment.format("MM/DD/YYYY"),
        amount: USDollar.format(installmentAmount),
        remaining: USDollar.format(contractValue - installmentAmount * i),
      });
      currentInstallment = currentInstallment.add(30, "day");
    }
  }
  return (
    <Box
      width="50%"
      sx={{ backgroundColor: "#E6E6EA", paddingY: "60px", paddingX: "84px" }}
    >
      <Typography variant="h6" sx={{ marginBottom: "28px" }}>
        Schedule Payment Plan
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="payment table">
          <TableHead>
            <TableRow>
              <TableCell>Installment</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="right">Remaining</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.installment}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.installment}
                </TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="right">{row.remaining}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function TermTableWithoutValues() {
  return (
    <Box
      width="50%"
      height="100%"
      sx={{
        backgroundColor: "#E6E6EA",
        paddingY: "60px",
        paddingX: "84px",
        position: "relative",
      }}
    >
      <Box
        width={432}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="absolute"
        top="50%"
        left="50%"
        sx={{ transform: "translate(-50%, -50%)" }}
      >
        <img
          src="/static/images/doctor-waving.png"
          alt="Doctor waiving"
          width={252}
          height={166}
        />
        <Typography variant="h6" sx={{ marginTop: "24px" }}>
          Schedule Payment Plan
        </Typography>
        <Typography variant="subtitle1" textAlign="center">
          Your Payment Plan will be available to preview once it’s been setup
        </Typography>
      </Box>
    </Box>
  );
}

export function TermTable({
  contractValue,
  contractPeriod,
  withdrawalBeginDate,
  installmentAmount,
}: {
  contractValue: number | undefined;
  contractPeriod: number | undefined;
  withdrawalBeginDate: Dayjs | undefined;
  installmentAmount: number | undefined;
}) {
  if (
    !contractValue ||
    !contractPeriod ||
    !withdrawalBeginDate ||
    !installmentAmount
  ) {
    return <TermTableWithoutValues />;
  }

  return (
    <TermTableWithValues
      contractValue={contractValue}
      contractPeriod={contractPeriod}
      withdrawalBeginDate={withdrawalBeginDate}
      installmentAmount={installmentAmount}
    />
  );
}
