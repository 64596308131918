import React from "react";

export function LeftPaneLogo() {
  return (
    <div className="leftPaneLogo">
      <a href="/">
        <img
          src="/static/images/logo-mark-white-neutral.svg"
          alt="Wieldy Logo"
          width={80}
          height={61}
        />
      </a>
    </div>
  );
}
