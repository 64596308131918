import head from "lodash/head";

import { getBackendAPI } from "./apiConfig";

function makeSourceUrl(document: string): string {
  const { search } = window.location;
  const encoded = encodeURIComponent(document);
  return search.length
    ? `${getBackendAPI()}sources/${search}&d=${encoded}`
    : `${getBackendAPI()}sources/?d=${encoded}`;
}

function toSourceUrls(sources: SourceList | null): string[] {
  if (!sources?.length) {
    return [];
  }
  const [, documents] = head(sources) || ["", []];
  if (!documents.length) {
    return [];
  }
  return documents.map(makeSourceUrl);
}

export { makeSourceUrl, toSourceUrls };
