import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { type Control, Controller } from "react-hook-form";

import { ErrorHelperText } from "../../PatientsDashboard/ui/ErrorHelper";
import { CREDENTIAL_TYPE } from "../common";
import { CredentialsStore } from "../store";
import { InputLabelText } from "../ui/InputLabelText";
import { InputWrapper } from "../ui/InputWrapper";
import { LineBreak } from "../ui/LineBreak";
import { SupportText } from "../ui/SelectSupportText";
import UserSignalIcon from "../ui/UserSignalIcon.svg";
import { AddPayerCredentialsFormFields } from "./PayerCredentialsForm.zod";

export const ADD_PAYER_VALUE = "addNewPayer";

interface AddPayerCredentialFormFieldProps {
  control: Control<AddPayerCredentialsFormFields>;
  practices: PracticeMessage[];
  payers: CredentialsSupportedPayersMessage[];
  userCredentials: CredentialsStore["userCredentials"];
  selectedPractice: PracticeMessage["wieldyId"];
  selectedType: AddPayerCredentialsFormFields["type"];
  selectedPayer?: CredentialsSupportedPayersMessage["wieldyId"];
  cancel: () => void;
}

export function AddPayerCredentialFormFields({
  control,
  practices,
  payers,
  userCredentials,
  selectedPractice,
  selectedType,
  selectedPayer,
  cancel,
}: AddPayerCredentialFormFieldProps) {
  const hasPayers = Array.isArray(payers);
  const placeholderProviderText = "Choose Provider";
  const selectedPayerObject =
    payers && selectedType === CREDENTIAL_TYPE.PAYER
      ? payers.find((payer) => payer.wieldyId === selectedPayer) || null
      : null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem 0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F2F4F7",
              width: "48px",
              height: "48px",
              borderRadius: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "8px solid #F9FAFB",
            }}
          >
            <img
              src={UserSignalIcon}
              alt="Credentials Icon"
              width="24"
              height="24"
            />
          </Box>
          <IconButton
            aria-label="close"
            onClick={cancel}
            sx={{
              color: "#475467",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Typography
          paragraph
          sx={{
            fontSize: "1.125rem",
            fontWeight: 600,
            color: "#303030",
            marginBottom: 0,
          }}
        >
          Add a new login
        </Typography>
        <Typography
          paragraph
          sx={{
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "#475467",
            marginBottom: 0,
          }}
        >
          Please fill the detail below to create a new login
        </Typography>
      </Box>

      <LineBreak />

      <Controller
        name="practiceId"
        control={control}
        rules={{ required: true }}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <FormLabel>
              <InputLabelText>Practice</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <Select
                name={name}
                value={value}
                label="Choose Practice"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                displayEmpty
                IconComponent={KeyboardArrowDownOutlinedIcon}
                inputProps={{
                  "data-testid": "practiceInput",
                }}
                MenuProps={{
                  sx: {
                    marginTop: "1rem",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "0.875rem",

                  ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                    {
                      padding: "12px 14px",
                    },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                  fieldset: {
                    legend: {
                      width: 0,
                    },
                  },
                }}
                renderValue={(currentValue) => {
                  const isEmpty = !currentValue;
                  if (isEmpty) {
                    return "Choose A Practice";
                  }
                  const practiceDisplayName = practices.find(
                    (practice) => practice.wieldyId === currentValue,
                  );
                  return practiceDisplayName?.displayName;
                }}
              >
                {practices.map((practice) => {
                  const { wieldyId, displayName: practiceDisplayName } =
                    practice;
                  return (
                    <MenuItem
                      key={wieldyId}
                      value={wieldyId}
                      data-testid={`practiceSelectItem-${wieldyId}`}
                    >
                      {practiceDisplayName}
                    </MenuItem>
                  );
                })}
              </Select>
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <Controller
        name="type"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, name, value, onBlur, onChange } }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <FormLabel>
              <InputLabelText>Type</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <Select
                disabled={!selectedPractice}
                name={name}
                value={value}
                label="Choose Type"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                displayEmpty
                IconComponent={KeyboardArrowDownOutlinedIcon}
                inputProps={{
                  "data-testid": "typeInput",
                }}
                MenuProps={{
                  sx: {
                    marginTop: "1rem",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "0.875rem",

                  ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                    {
                      padding: "12px 14px",
                    },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                  fieldset: {
                    legend: {
                      width: 0,
                    },
                  },
                }}
                renderValue={(currentValue) => {
                  const placeholder = "Choose Type";
                  return currentValue || placeholder;
                }}
              >
                <MenuItem
                  value={CREDENTIAL_TYPE.PAYER}
                  data-testid={`type-${CREDENTIAL_TYPE.PAYER.toLowerCase()}`}
                >
                  {CREDENTIAL_TYPE.PAYER}
                </MenuItem>
              </Select>
            </InputWrapper>
          </FormControl>
        )}
      />

      {!selectedType && (
        <FormControl
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <FormLabel>
            <InputLabelText>Provider</InputLabelText>
          </FormLabel>
          <InputWrapper>
            <Select
              IconComponent={KeyboardArrowDownOutlinedIcon}
              inputProps={{
                "data-testid": "providerInput",
              }}
              MenuProps={{
                sx: {
                  marginTop: "1rem",
                },
              }}
              sx={{
                backgroundColor: "#F9FAFB",
                width: "100%",
                borderRadius: "8px",
                fontSize: "0.875rem",

                ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                  {
                    padding: "12px 14px",
                  },
                ".MuiOutlinedInput-notchedOutline": {
                  border: "solid 1px rgba(208, 213, 221, 0.5)",
                },
                fieldset: {
                  legend: {
                    width: 0,
                  },
                },
              }}
              disabled
              // Intentionally set as an empty string to trigger placeholder
              defaultValue=" "
              renderValue={() => placeholderProviderText}
            />
          </InputWrapper>
        </FormControl>
      )}

      {selectedType === CREDENTIAL_TYPE.PMS && (
        <Controller
          name="pms"
          control={control}
          rules={{ required: true }}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <FormControl
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <FormLabel>
                <InputLabelText>Provider</InputLabelText>
              </FormLabel>
              <InputWrapper>
                <Select
                  name={name}
                  value={value}
                  label="Choose Provider"
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  displayEmpty
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                  inputProps={{
                    "data-testid": "pmsProviderInput",
                  }}
                  MenuProps={{
                    sx: {
                      marginTop: "1rem",
                    },
                  }}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "0.875rem",

                    ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                      {
                        padding: "12px 14px",
                      },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "solid 1px rgba(208, 213, 221, 0.5)",
                    },
                    fieldset: {
                      legend: {
                        width: 0,
                      },
                    },
                  }}
                  renderValue={(currentValue) =>
                    currentValue || placeholderProviderText
                  }
                />
                <ErrorHelperText message={error?.message} />
              </InputWrapper>
            </FormControl>
          )}
        />
      )}

      {selectedType === CREDENTIAL_TYPE.PAYER && (
        <Controller
          name="payer"
          control={control}
          rules={{ required: true }}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <FormControl
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <FormLabel>
                <InputLabelText>Provider</InputLabelText>
              </FormLabel>
              <InputWrapper>
                <Select
                  name={name}
                  value={value}
                  label="Choose Provider"
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  displayEmpty
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                  inputProps={{
                    "data-testid": "payerProviderInput",
                  }}
                  MenuProps={{
                    sx: {
                      marginTop: "1rem",
                    },
                  }}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "0.875rem",

                    ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                      {
                        padding: "12px 14px",
                      },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "solid 1px rgba(208, 213, 221, 0.5)",
                    },
                    fieldset: {
                      legend: {
                        width: 0,
                      },
                    },
                  }}
                  renderValue={(selectedValue) =>
                    selectedValue === ADD_PAYER_VALUE
                      ? "New Insurance Payer"
                      : selectedPayerObject?.displayLabel ||
                        placeholderProviderText
                  }
                >
                  {hasPayers &&
                    payers.map(({ wieldyId, displayLabel }) => {
                      const isDisabled = userCredentials?.some(
                        (credential) =>
                          credential.payerId === wieldyId &&
                          credential.practiceId === selectedPractice,
                      );

                      return (
                        <MenuItem
                          disabled={isDisabled}
                          key={wieldyId}
                          value={wieldyId}
                          data-testid={`providerSelectItem-${wieldyId}`}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "1rem",
                          }}
                        >
                          <Box component="span" sx={{ textAlign: "right" }}>
                            {displayLabel}
                          </Box>
                          <Box component="span" sx={{ textAlign: "right" }}>
                            {isDisabled && <SupportText />}
                          </Box>
                        </MenuItem>
                      );
                    })}
                  <Divider />
                  <MenuItem
                    value={ADD_PAYER_VALUE}
                    data-testid="providerSelectItem-add-new"
                  >
                    <ListItemIcon>
                      <AddCircleIcon />
                    </ListItemIcon>
                    <ListItemText>Add New</ListItemText>
                  </MenuItem>
                </Select>
                <ErrorHelperText message={error?.message} />
              </InputWrapper>
            </FormControl>
          )}
        />
      )}
      <LineBreak />

      {selectedType === CREDENTIAL_TYPE.PAYER &&
        selectedPayer === ADD_PAYER_VALUE && (
        <Controller
          name="unsupportedPayer"
          control={control}
          rules={{ required: true }}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <FormControl
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <FormLabel>
                <InputLabelText>Name</InputLabelText>
              </FormLabel>
              <InputWrapper>
                <TextField
                  name={name}
                  value={value}
                  type="text"
                  variant="outlined"
                  placeholder="Enter Payer Name"
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  inputProps={{
                    "data-testid": "unsupportedPayerInput",
                    autoComplete: "unsupported payer",
                    sx: {
                      border: "none",
                    },
                  }}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    width: "100%",
                    fontSize: "1rem",
                    ".MuiInputBase-root": {
                      borderRadius: "8px",
                      border: "none",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "solid 1px rgba(208, 213, 221, 0.5)",
                    },
                  }}
                />
                <ErrorHelperText message={error?.message} />
              </InputWrapper>
            </FormControl>
          )}
        />
      )}

      <Controller
        name="username"
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Username</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                name={name}
                value={value}
                type="text"
                variant="outlined"
                placeholder="Enter Username"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "usernameInput",
                  autoComplete: "username",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  fontSize: "1rem",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <Controller
        name="website"
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Website</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                name={name}
                disabled={!!selectedPayerObject}
                value={value}
                type="text"
                variant="outlined"
                placeholder={selectedPayerObject?.website || "Enter Website"}
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "labelInput",
                  autoComplete: "off",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    opacity: selectedPayerObject?.website ? 1 : "none",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <Controller
        name="notes"
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Notes</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                multiline
                name={name}
                value={value}
                rows={4}
                type="text"
                variant="outlined"
                placeholder="Enter Notes"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "labelInput",
                  autoComplete: "off",
                  sx: {
                    border: "none",
                  },
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "none",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />

      <LineBreak />

      <Controller
        name="password"
        control={control}
        render={({
          field: { ref, name, value, onBlur, onChange },
          fieldState: { error },
        }) => (
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            <FormLabel>
              <InputLabelText>Password</InputLabelText>
            </FormLabel>
            <InputWrapper>
              <TextField
                name={name}
                value={value}
                type="password"
                variant="outlined"
                placeholder="Enter Password"
                ref={ref}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                  "data-testid": "passwordInput",
                  autoComplete: "current-password",
                }}
                sx={{
                  backgroundColor: "#F9FAFB",
                  width: "100%",
                  margin: "0 auto",
                  fontSize: "1rem",
                  maxWidth: "785px",
                  ".MuiInputBase-root": {
                    borderRadius: "8px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "solid 1px rgba(208, 213, 221, 0.5)",
                  },
                }}
              />
              <ErrorHelperText message={error?.message} />
            </InputWrapper>
          </FormControl>
        )}
      />
    </Box>
  );
}

AddPayerCredentialFormFields.defaultProps = {
  selectedPayer: undefined,
};
