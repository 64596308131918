import { type Dayjs } from "dayjs";

export interface PaymentTerms {
  totalAmount: number;
  downPayment: number;
  discount: number;
  contractValue: number;
  contractPeriod: number;
  withdrawalBeginDate: Dayjs;
  contractValuePerPeriod: number;
  installmentAmount: number;
  lastPayment: number;
}

export type PaymentPlanStore = {
  id?: string;
  paymentMethodOptions?: PatientPaymentMessage[];
  paymentMethod?: PatientPaymentMessage;
  paymentTerms?: PaymentTerms;
  loadedPaymentPlans?: PaymentPlanMessage[];
};

export const initialPaymentPlanStore: PaymentPlanStore = {
  id: undefined,
  paymentMethodOptions: undefined,
  paymentMethod: undefined,
  paymentTerms: undefined,
  loadedPaymentPlans: undefined,
};

export type PaymentPlanStoreAction =
  | { type: "SET_CURRENT_PAYMENTS_OPTIONS"; payload: PatientPaymentMessage[] }
  | {
      type: "SET_PAYMENT_METHOD";
      payload: PatientPaymentMessage;
    }
  | { type: "SET_PAYMENT_TERMS"; payload: PaymentTerms }
  | { type: "RESET" }
  | { type: "SET_LOADED_PAYMENT_PLANS"; payload: PaymentPlanMessage[] }
  | { type: "CLEAR_PAYMENT_PLAN"; payload: PaymentPlanMessage["id"] };

export function paymentPlanStoreReducer(
  store: PaymentPlanStore,
  action: PaymentPlanStoreAction,
): PaymentPlanStore {
  switch (action.type) {
    case "SET_CURRENT_PAYMENTS_OPTIONS":
      return {
        ...store,
        id: crypto.randomUUID(),
        paymentMethodOptions: action.payload,
      };
    case "SET_PAYMENT_METHOD": // Clear out store and set patientId
      return {
        ...store,
        paymentMethod: action.payload,
      };
    case "SET_PAYMENT_TERMS": // Clear out store and set patientId
      return {
        ...store,
        paymentTerms: action.payload,
      };
    case "RESET":
      return initialPaymentPlanStore;
    case "SET_LOADED_PAYMENT_PLANS":
      return {
        ...store,
        loadedPaymentPlans: action.payload,
      };
    case "CLEAR_PAYMENT_PLAN":
      return {
        ...store,
        loadedPaymentPlans: store.loadedPaymentPlans?.filter(
          (paymentPlan) => paymentPlan.id !== action.payload,
        ),
      };
    default:
      throw new Error("Invalid action type");
  }
}
