import { type SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export function LineBreak({ sx }: { sx?: SxProps }) {
  return (
    <Box
      component="hr"
      sx={{
        border: "1px solid #EAECF0",
        width: "100%",
        height: "1px",
        ...sx,
      }}
    />
  );
}

LineBreak.defaultProps = {
  sx: {},
};
