import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type QueryClient from "../../../../../api/query/queryClient";
import Modal from "../../../../Modal";
import Invoice from "./Invoice";

const smsButtonText = {
  idle: "Send via SMS",
  sending: "Sending...",
  success: "Sent!",
};

export function Success({
  patientId,
  queryClient,
  updateSnackBar,
}: {
  patientId: string;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}) {
  const navigate = useNavigate();
  const { paymentPlanId } = useParams();
  const [pdf, setPdf] = useState<Blob | undefined>(undefined);
  const [smsState, setSmsState] = useState<keyof typeof smsButtonText>("idle");

  useEffect(() => {
    const fetchPatientPaymentPlan = async () => {
      if (paymentPlanId) {
        const res = await queryClient.getPatientPaymentPlan(paymentPlanId);
        setPdf(res);
      }
    };

    fetchPatientPaymentPlan();
  }, [paymentPlanId]);

  const handleSendSms = async () => {
    if (paymentPlanId) {
      setSmsState("sending");
      try {
        await queryClient.sentPatientPaymentPlanViaSms(paymentPlanId);
        setSmsState("success");
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to send SMS",
        });
        setSmsState("idle");
      }
    }
  };

  return (
    <Modal
      title="Payment plan"
      open
      onClose={() => {
        navigate(`/patients/${patientId}`);
      }}
    >
      <Box display="flex" position="relative" flexGrow={1} flexShrink={1}>
        <Box
          display="flex"
          justifyContent="flex-end"
          width="50%"
          paddingX="64px"
          paddingY="60px"
          minHeight="100%"
          position="relative"
        >
          <Box width={432}>
            <img
              src="/static/images/doctor-okay.png"
              alt="Doctor okay"
              width={137}
              height={120}
            />
            <Typography variant="h3" marginY={3}>
              All set!
            </Typography>
            <Box component="p" marginBottom={6}>
              Here you can find the final summary PDF document, you can send it
              to the patient via SMS
            </Box>
            <Button
              startIcon={<PhoneIphoneIcon />}
              variant="contained"
              color={smsState === "success" ? "success" : "primary"}
              size="large"
              sx={{ marginBottom: "16px" }}
              disabled={smsState === "sending"}
              onClick={handleSendSms}
              fullWidth
            >
              {smsButtonText[smsState]}
            </Button>
            <Button
              data-testid="backToPatient"
              variant="outlined"
              color="primary"
              size="large"
              sx={{ marginBottom: "16px" }}
              fullWidth
              onClick={() => navigate(`/patients/${patientId}`)}
            >
              Back to patient
            </Button>
          </Box>
        </Box>
        <Invoice pdf={pdf} />
      </Box>
    </Modal>
  );
}
