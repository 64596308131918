import { AlertColor } from "@mui/material/Alert/Alert";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import usePaymentsApi from "../../api/payments";
import useDataGridPersistence from "../../utils/datagrid";
import DateRangePicker, { DateRange } from "../DateRangePicker";
import PaymentsTable from "../PaymentsTable";

export interface PaymentDashboardSettings {
  selectedDate: DateRange;
}

function filterPayments(
  payments: PaymentMessage[],
  // TODO: Consistently order practices before locations.  Practice, then location, always.
  // TODO: Define a PracticeLocation type composing practice with location.
  _selectedLocations: PracticeMessage["displayName"][],
  selectedPractices: PracticeMessage["displayName"][],
  dateRange: DateRange,
): PaymentMessage[] {
  const selectedPracticeSet = new Set(selectedPractices);
  const paymentsByPractice =
    payments.filter((payment) =>
      selectedPracticeSet.has(payment.practiceDisplayName),
    ) || [];

  return paymentsByPractice.filter((payment) => {
    if (!payment.paymentDate) {
      return false;
    }
    const paymentDate = dayjs(payment.paymentDate);
    return (
      (paymentDate.isSame(dateRange.startDate, "day") ||
        paymentDate.isAfter(dateRange.startDate, "day")) &&
      (paymentDate.isSame(dateRange.endDate, "day") ||
        paymentDate.isBefore(dateRange.endDate, "day"))
    );
  });
}

interface PaymentsListViewProps {
  payments: PaymentMessage[];
  paymentsLoading: boolean;
  load: () => Promise<void>;
  reset: () => void;
  selectedLocations: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  dashboardSettings: PaymentDashboardSettings;
  saveDashboardSettings: (newSettings: PaymentDashboardSettings) => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function PaymentsListView({
  payments,
  paymentsLoading,
  load,
  reset,
  selectedLocations,
  selectedPractices,
  dashboardSettings,
  saveDashboardSettings,
  updateSnackBar,
}: PaymentsListViewProps) {
  useEffect(() => {
    load();
    return reset;
  }, []);

  const [practicesToSync, updatePractices] = useState<string[] | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { synchronizePayments } = usePaymentsApi();

  const dataGridPersistence = useDataGridPersistence("paymentsListView");
  const selectedPracticeNames = selectedPractices.map(
    (practice) => practice.displayName,
  );
  const selectedLocationNames = selectedLocations.map(
    (practice) => practice.displayName,
  );

  useEffect(() => {
    async function fetchData() {
      if (practicesToSync) {
        const response = await synchronizePayments(practicesToSync);
        updateSnackBar({ severity: "success", message: response?.detail });
        updatePractices(null);
      }
    }

    fetchData().catch((error) => {
      // eslint-disable-next-line no-console
      updateSnackBar({
        severity: "error",
        message: error.response?.data?.detail,
      });
      updatePractices(null);
    });
  }, [practicesToSync]);

  const handleRowClick = (payment: PaymentMessage | null) => {
    if (payment) {
      navigate({
        pathname: `/payments/${payment.wieldyId}`,
        search: searchParams.toString(),
      });
    }
  };

  const onDateChange = (dateRange: DateRange) => {
    saveDashboardSettings({ ...dashboardSettings, selectedDate: dateRange });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSyncClick = debounce(() => {
    updatePractices(selectedPracticeNames);
  }, 1000);

  const paymentsByDate = payments
    .filter((p) => !!p.paymentDate)
    .sort((a, b) =>
      dayjs(a.paymentDate).isAfter(dayjs(b.paymentDate)) ? -1 : 1,
    );

  return (
    <>
      <h1>Payments</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "14px",
        }}
      >
        {/* Remove all plumbing in 4 weeks (10/23/24) */}
        {/* <div> */}
        {/*   <Button */}
        {/*     variant="contained" */}
        {/*     disabled={practicesToSync != null} */}
        {/*     onClick={handleSyncClick} */}
        {/*   > */}
        {/*     Sync Payments */}
        {/*   </Button> */}
        {/* </div> */}
        <div>
          <DateRangePicker
            selectedDateRange={dashboardSettings.selectedDate}
            setDateRange={onDateChange}
            earliestStartDate={
              paymentsByDate?.length
                ? paymentsByDate[paymentsByDate.length - 1].paymentDate
                : null
            }
          />
        </div>
      </div>
      <PaymentsTable
        isLoading={paymentsLoading}
        payments={filterPayments(
          paymentsByDate,
          selectedLocationNames,
          selectedPracticeNames,
          dashboardSettings.selectedDate,
        )}
        onSelectPayment={handleRowClick}
        initialState={dataGridPersistence.retrieveState()}
        apiRef={dataGridPersistence.apiRef}
      />
    </>
  );
}
