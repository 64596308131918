import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import React, { createContext, useContext } from "react";

const PaymentMethodContext = createContext<string>("");

export function PaymentMethod({
  children,
  value,
}: {
  children: React.ReactNode;
  value: string;
}) {
  const theme = useTheme();
  return (
    <PaymentMethodContext.Provider value={value}>
      <Box
        component="label"
        border={1}
        borderColor={theme.palette.divider}
        borderRadius={1}
        paddingX={2.5}
        paddingY={3}
        marginBottom={1}
      >
        {children}
      </Box>
    </PaymentMethodContext.Provider>
  );
}

export function PaymentMethodHeader({
  children,
  disabled,
  checked,
  onChange,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  checked?: boolean;
  onChange?: () => void;
}) {
  const value = useContext(PaymentMethodContext);
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>{children}</Box>
      <Radio
        name="payment-method"
        value={value}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </Box>
  );
}

export function PaymentMethodBody({ children }: { children: React.ReactNode }) {
  const radioGroup = useRadioGroup();
  const value = useContext(PaymentMethodContext);
  return (
    <Box display={radioGroup?.value === value ? "block" : "none"}>
      {children}
    </Box>
  );
}

PaymentMethodHeader.defaultProps = {
  disabled: false,
  checked: false,
  onChange: undefined,
};
