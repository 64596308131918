import { CircularProgress, circularProgressClasses } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export default function Loading() {
  return (
    <Box
      sx={{ position: "relative" }}
      style={{
        minWidth: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: "#E9D7D9",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        size={100}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: "#25233F",
          animationDuration: "1000ms",
          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={100}
        thickness={4}
      />
    </Box>
  );
}
