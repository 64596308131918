import React from "react";

export default function PageNotFound() {
  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for cannot be found.</p>
    </div>
  );
}
