import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { redactText } from "../../redact";
import PatientField, { PatientFieldChangeHandler } from "./PatientField";

export interface PatientDetailsFields {
  name?: string;
  ssn?: string;
  dob?: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  practiceId?: string;
}

export function toPatientDetailsFields(
  patient: PatientMessage,
): PatientDetailsFields {
  return {
    name: patient.name ?? undefined,
    ssn: patient.ssn ?? undefined,
    dob: patient.dob ?? undefined,
    phoneNumber: patient.phoneNumber ?? undefined,
    email: patient.email ?? undefined,
    address: patient.address ?? undefined,
    city: patient.city ?? undefined,
    state: patient.state ?? undefined,
    zipCode: patient.zipCode ?? undefined,
    practiceId: patient.practiceId ?? undefined,
  };
}

interface PatientDetailsFormProps {
  values: PatientDetailsFields;
  editable?: boolean;
  onUpdate?: (update: PatientDetailsFields) => void;
  practices: PracticeMessage[];
}

export default function PatientDetailsForm({
  values,
  editable,
  onUpdate,
  practices,
}: PatientDetailsFormProps) {
  const theme = useTheme();
  const disabled = !editable;

  const onChange: PatientFieldChangeHandler = (name, value) => {
    if (!onUpdate) {
      return;
    }
    const updated: PatientDetailsFields = {
      ...values,
      [name]: value,
    };
    onUpdate(updated);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={editable ? 4 : 3}>
          <PatientField
            label="Full Name"
            name="name"
            value={redactText(values.name)}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 4 : 3}>
          <PatientField
            label="Social Security Number"
            name="ssn"
            value={redactText(values.ssn)}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 4 : 3}>
          <PatientField
            label="DoB"
            name="dob"
            type="date"
            value={redactText(values.dob)}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 6 : 3}>
          <PatientField
            label="Phone number"
            name="phoneNumber"
            value={values.phoneNumber}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 6 : 3}>
          <PatientField
            label="Email"
            name="email"
            value={redactText(values.email)}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 12 : 4}>
          <PatientField
            label="Address"
            name="address"
            value={redactText(values.address)}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <PatientField
            label="City"
            name="city"
            value={values.city}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 4 : 3}>
          <PatientField
            label="State"
            name="state"
            value={values.state}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 4 : 3}>
          <PatientField
            label="Zip Code"
            name="zipCode"
            value={values.zipCode}
            onChange={onChange}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12} sm={editable ? 6 : 3}>
          <FormControl fullWidth>
            <InputLabel id="select-practice">Practice</InputLabel>
            <Select
              name="selectPractice"
              labelId="select-practice"
              value={values.practiceId || ""}
              label="Practice"
              disabled={disabled}
              onChange={(event) => onChange("practiceId", event.target.value)}
            >
              {practices.map((practice) => {
                const { wieldyId, name } = practice;
                return (
                  <MenuItem key={wieldyId} value={wieldyId}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {editable && (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: "12px",
            marginTop: theme.spacing(1),
          }}
        >
          * These fields are mandatory
        </Typography>
      )}
    </>
  );
}

PatientDetailsForm.defaultProps = {
  editable: false,
  onUpdate: undefined,
};
