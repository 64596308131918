import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PermIdentity from "@mui/icons-material/PermIdentity";
import VerifiedUserOutlined from "@mui/icons-material/VerifiedUserOutlined";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import { type StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { type SxProps, type Theme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { ReactNode, useRef, useState } from "react";

import { constructPatientMessage } from "../../../../api/query/messages";
import Modal from "../../../Modal";
import InsuranceDetailsForm, {
  InsuranceDetailsFields,
  toInsuranceDetailsFields,
} from "../../forms/InsuranceDetailsForm";
import PatientDetailsForm, {
  PatientDetailsFields,
  toPatientDetailsFields,
} from "../../forms/PatientDetailsForm";
import PaymentDetailsForm, {
  NewPaymentMethodRef,
} from "../../forms/PaymentDetailsForm";
import ResponsibleDetailsForm, {
  ResponsibleDetailsFields,
  toResponsibleDetailsFields,
} from "../../forms/ResponsibleDetailsForm";

function renderStepIcon(
  Icon: React.ElementType,
  theme: Theme,
): React.ElementType<StepIconProps> {
  return function Element({ active, completed }: StepIconProps) {
    if (completed && !active) {
      return (
        <IconButton
          sx={{
            color: theme.palette.success.main,
            border: `2px solid ${theme.palette.success.main}`,
            "&:hover": { backgroundColor: "initial" },
          }}
        >
          <CheckOutlined />
        </IconButton>
      );
    }
    const iconButtonStyles: SxProps<Theme> = active
      ? {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
        },
      }
      : {
        border: "2px solid rgba(37, 35, 63, 0.50)",
        "&:hover": { backgroundColor: "initial" },
      };
    return (
      <IconButton sx={iconButtonStyles}>
        <Icon />
      </IconButton>
    );
  };
}

enum NewPatientStep {
  PatientDetails = "Patient Details",
  InsuranceDetails = "Insurance Details",
  PaymentDetails = "Payment Details",
}

function labelToIndex(step: NewPatientStep): number {
  return Object.values(NewPatientStep).indexOf(step);
}

function indexToLabel(index: number): NewPatientStep {
  return Object.values(NewPatientStep)[index];
}

function renderForm(
  step: NewPatientStep,
  patient: PatientMessage,
  includeResponsibleDetails: boolean,
  onClickCheckbox: () => void,
  onUpdate: (
    update:
      | PatientDetailsFields
      | ResponsibleDetailsFields
      | InsuranceDetailsFields,
  ) => void,
  theme: Theme,
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void,
  paymentMethodRef: React.MutableRefObject<NewPaymentMethodRef | undefined>,
  practices: PracticeMessage[],
): ReactNode {
  switch (step) {
    case NewPatientStep.PatientDetails:
      return (
        <>
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            Patient Details
          </Typography>
          <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
            <PatientDetailsForm
              values={toPatientDetailsFields(patient)}
              onUpdate={onUpdate}
              practices={practices}
              editable
            />
          </Box>
          <Divider />
          <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
            <ResponsibleDetailsForm
              values={toResponsibleDetailsFields(patient)}
              onClickCheckbox={onClickCheckbox}
              onUpdate={onUpdate}
              showForm={includeResponsibleDetails}
              editable
            />
          </Box>
        </>
      );
    case NewPatientStep.InsuranceDetails:
      return (
        <>
          <Typography
            variant="h5"
            sx={{ fontWeight: "600", marginBottom: theme.spacing(3) }}
          >
            Insurance Details
          </Typography>
          <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
            <InsuranceDetailsForm
              values={toInsuranceDetailsFields(patient)}
              onUpdate={onUpdate}
              editable
            />
          </Box>
        </>
      );
    case NewPatientStep.PaymentDetails:
      return (
        <>
          <Typography
            variant="h5"
            sx={{ fontWeight: "600", marginBottom: theme.spacing(3) }}
          >
            Payment Details
          </Typography>
          <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
            <PaymentDetailsForm
              updateSnackBar={updateSnackBar}
              ref={paymentMethodRef}
            />
          </Box>
        </>
      );
    default:
      return null;
  }
}

function renderBackButton(
  step: NewPatientStep,
  onClick: () => void,
): ReactNode {
  if (labelToIndex(step) === 0) {
    return null;
  }
  return (
    <Button
      sx={{ fontSize: "16px", fontWeight: 400, textTransform: "none" }}
      startIcon={<ArrowBackOutlined sx={{ width: "24px", height: "24px" }} />}
      onClick={onClick}
    >
      Back
    </Button>
  );
}

function renderSubmitButton(
  step: NewPatientStep,
  onClick: () => void,
): ReactNode {
  const nextStepIndex = labelToIndex(step) + 1;
  const text =
    nextStepIndex >= Object.values(NewPatientStep).length
      ? "Create Patient"
      : `Continue to ${indexToLabel(nextStepIndex)}`;
  const isPaymentDetailsStep =
    nextStepIndex >= Object.values(NewPatientStep).length
      ? {
        width: "39.5rem",
        display: "flex",
        margin: "auto",
      }
      : {};
  return (
    <Button
      variant="contained"
      color="success"
      size="large"
      sx={{ fontWeight: 600, marginBottom: "2rem", ...isPaymentDetailsStep }}
      onClick={onClick}
      fullWidth
    >
      {text}
    </Button>
  );
}

interface NewPatientModalProps {
  open: boolean;
  onSubmit: (patient: PatientMessage, token?: string) => void;
  onClose: () => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  practices: PracticeMessage[];
}

export default function NewPatientModal({
  open,
  onSubmit,
  onClose,
  updateSnackBar,
  practices,
}: NewPatientModalProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(NewPatientStep.PatientDetails);
  const [patient, setPatient] = useState<PatientMessage>(() => {
    const [defaultFirstPractice] = practices;
    return constructPatientMessage({
      practiceId: defaultFirstPractice?.wieldyId,
    });
  });
  const [includeResponsibleDetails, setIncludeResponsibleDetails] =
    useState(false);
  const onUpdate = (
    update:
      | PatientDetailsFields
      | ResponsibleDetailsFields
      | InsuranceDetailsFields,
  ) => {
    const updatedPatient: PatientMessage = {
      ...patient,
      ...update,
    };
    setPatient(updatedPatient);
  };
  const paymentRef = useRef<NewPaymentMethodRef>();

  const onClickCheckbox = () => {
    setIncludeResponsibleDetails(!includeResponsibleDetails);
  };
  const onClickSubmit = () => {
    const nextStepIndex = labelToIndex(activeStep) + 1;
    if (nextStepIndex < Object.values(NewPatientStep).length) {
      setActiveStep(indexToLabel(nextStepIndex));
    } else {
      paymentRef.current?.submit((token) => {
        onSubmit(patient, token);
      });
    }
  };
  const onClickBack = () => {
    const prevStepIndex = labelToIndex(activeStep) - 1;
    if (prevStepIndex < 0) {
      return;
    }
    setActiveStep(indexToLabel(prevStepIndex));
  };
  return (
    <Modal
      title="Add Patient"
      open={open}
      onClose={onClose}
      adornment={
        <Stepper
          activeStep={labelToIndex(activeStep)}
          connector={<StepConnector sx={{ width: "60px" }} />}
        >
          <Step key={NewPatientStep.PatientDetails}>
            <StepLabel StepIconComponent={renderStepIcon(PermIdentity, theme)}>
              {NewPatientStep.PatientDetails}
            </StepLabel>
          </Step>
          <Step key={NewPatientStep.InsuranceDetails}>
            <StepLabel
              StepIconComponent={renderStepIcon(VerifiedUserOutlined, theme)}
            >
              {NewPatientStep.InsuranceDetails}
            </StepLabel>
          </Step>
          <Step key={NewPatientStep.PaymentDetails}>
            <StepLabel
              StepIconComponent={renderStepIcon(PaymentsOutlinedIcon, theme)}
            >
              {NewPatientStep.PaymentDetails}
            </StepLabel>
          </Step>
        </Stepper>
      }
    >
      <Grid container sx={{ height: "100%", margin: `${theme.spacing(7)} 0` }}>
        <Grid item xs={12} sm={3.5} sx={{ paddingLeft: theme.spacing(12) }}>
          {renderBackButton(activeStep, onClickBack)}
        </Grid>
        <Grid item xs={12} sm={5}>
          {renderForm(
            activeStep,
            patient,
            includeResponsibleDetails,
            onClickCheckbox,
            onUpdate,
            theme,
            updateSnackBar,
            paymentRef,
            practices,
          )}
          {renderSubmitButton(activeStep, onClickSubmit)}
        </Grid>
      </Grid>
    </Modal>
  );
}
