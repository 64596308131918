import type { AxiosResponse } from "axios";

import useApi from "./apiConfig";

const usePaymentsApi = () => {
  const { getClient } = useApi();

  const synchronizePayments = async (
    practices: string[],
  ): Promise<Pipeline> => {
    const { search } = window.location;
    const api = await getClient();
    const response: AxiosResponse = await api.post(
      `payments/pipelines${search}`,
      {
        practices,
      },
    );
    return response.data;
  };

  return {
    synchronizePayments,
  };
};

export default usePaymentsApi;
