export const CREDENTIAL_TYPE = {
  PMS: "PMS",
  PAYER: "Payer",
} as const;

export function isSupportPayer(
  credential: Partial<UserCredentialsMessage>,
): boolean {
  return !!credential.payerId && !credential.name;
}

export function isNewPayer(
  credential: Partial<UserCredentialsMessage>,
): boolean {
  return !!credential.name;
}

/*
  This function derives credential type from the credential object. This is temporary
  till we add credential type as an attribute from the back-end.
 */
export function typeFromCredential(
  credential: Partial<UserCredentialsMessage>,
): (typeof CREDENTIAL_TYPE)[keyof typeof CREDENTIAL_TYPE] {
  if (isSupportPayer(credential) || isNewPayer(credential)) {
    return CREDENTIAL_TYPE.PAYER;
  }
  return CREDENTIAL_TYPE.PMS;
}
