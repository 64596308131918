import Box from "@mui/material/Box";
import React, { type ReactNode } from "react";

export function InputWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "0 auto",
        maxWidth: "785px",
      }}
    >
      {children}
    </Box>
  );
}
