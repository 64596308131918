import Close from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { PropsWithChildren, ReactNode } from "react";

interface Logo {
  alt: string;
  path: string;
}

interface ModalProps {
  logo?: Logo;
  onClose: () => void;
  open: boolean;
  title: string;
  adornment?: ReactNode;
}

export default function Modal({
  children,
  logo,
  onClose,
  open,
  title,
  adornment,
}: PropsWithChildren<ModalProps>) {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      sx={{ display: "flex", flexDirection: "column" }}
      fullScreen
    >
      <AppBar
        sx={{
          boxShadow: "initial",
          backgroundColor: "initial",
          borderBottom: `1px solid ${theme.palette.divider}`,
          position: "relative",
          flexShrink: 0,
          flexGrow: 0,
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.background.paper,
            padding: `20px ${theme.spacing(3)}`,
            height: "84px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }} flexBasis="30%">
            <img src={logo?.path} alt={logo?.alt} width={48} height={31} />
            <Divider
              sx={{ margin: `0 ${theme.spacing(2)}` }}
              orientation="vertical"
              flexItem
            />
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.primary, fontWeight: 600 }}
            >
              {title}
            </Typography>
          </Box>
          {adornment && <Box flexBasis="40%">{adornment}</Box>}
          <Box flexBasis="30%" display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}

Modal.defaultProps = {
  logo: {
    alt: "Wieldy Logo",
    path: "/static/images/logo-mark-black-neutral.svg",
  },
  adornment: null,
};
