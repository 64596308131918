import { axisClasses, BarChart } from "@mui/x-charts";
import { mangoFusionPalette } from "@mui/x-charts/colorPalettes";
import React from "react";

import { formatMoney } from "../../../../../formatters";
import { toPaymentPracticeDataset } from "./dataset";

interface PaymentsByPracticeProps {
  payments: PaymentMessage[];
}

function toPractices(payments: PaymentMessage[]): string[] {
  return Array.from(
    new Set<string>(
      payments
        .filter((payment) => payment.practice)
        .map((payment) => payment.practice),
    ),
  );
}

export default function PaymentsByPractice({
  payments,
}: PaymentsByPracticeProps) {
  const practices = toPractices(payments);
  const dataset = toPaymentPracticeDataset(payments, practices);

  return (
    <>
      <h2>Payments By Practice</h2>
      <BarChart
        dataset={dataset}
        colors={mangoFusionPalette}
        height={400}
        series={practices.map((name) => ({
          dataKey: name,
          label: name,
          valueFormatter: formatMoney,
        }))}
        xAxis={[
          {
            data: dataset.map((o) => o.period),
            scaleType: "band",
            label: "Month",
          },
        ]}
        yAxis={[{ label: "Total Payments ($)" }]}
        margin={{
          left: 100,
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-25px, 0)",
          },
        }}
      />
    </>
  );
}
