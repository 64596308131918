export class QueryBuilder {
  private query: Query;

  constructor(type: Type) {
    this.query = {
      type,
    };
  }

  dice(field: string, values: DiceValue[]): QueryBuilder {
    if (!this.query.dice) {
      this.query = { ...this.query, dice: [] };
    }
    this.query.dice?.push([field, values]);
    return this;
  }

  join(query: Query): QueryBuilder {
    if (!this.query.join) {
      this.query = { ...this.query, join: [] };
    }
    this.query.join?.push(query);
    return this;
  }

  fold(op: FoldOp, field: string): QueryBuilder {
    this.query = Object.assign(this.query, { fold: [op, field] });
    return this;
  }

  build(): Query {
    return this.query;
  }
}
