import { DatasetType } from "@mui/x-charts/models/seriesType/config";
import dayjs from "dayjs";

import {
  claimsToDenialRate,
  getMonthLabels,
  uniquePracticesFrom,
} from "../helper";

type DatasetItem = DatasetType[number];

type PayerKey = string;
type PracticeKey = string;

interface ClaimsByPayerPractice {
  [key: PayerKey]: { [key: PracticeKey]: ClaimWithProcedureMessage[] };
}

function emptyDatasetItem<T extends string | number | Date>(
  dataKey: string,
  dataKeyValue: string | number | Date,
  labels: string[],
  initialValue: T,
): DatasetItem {
  return labels.reduce<DatasetItem>(
    (acc, cur) => ({
      ...acc,
      [cur]: initialValue,
    }),
    { [dataKey]: dataKeyValue },
  );
}

function updateDatasetItem<T extends string | number | Date>(
  item: DatasetItem,
  key: string,
  value: T,
) {
  return {
    ...item,
    [key]: value,
  };
}

function expectNumber(num: unknown): number | undefined {
  if (num === undefined) {
    return num;
  }
  if (typeof num !== "number") {
    throw new Error("Expected number");
  }
  return num;
}

function toPaymentPracticeDataset(
  payments: PaymentMessage[],
  practices: string[],
): DatasetType {
  const monthLabels = getMonthLabels(payments);
  const datasetObject: { [key: string]: DatasetItem } = monthLabels.reduce<{
    [key: string]: DatasetItem;
  }>((acc, month) => {
    acc[month] = emptyDatasetItem("period", month, practices, 0);
    return acc;
  }, {});
  payments.forEach((payment) => {
    if (
      !payment.practice ||
      !payment.paymentDate ||
      !payment.eopPaymentAmount
    ) {
      return;
    }
    const { practice, paymentDate, eopPaymentAmount } = payment;
    const month = dayjs(paymentDate).format("MMM-YYYY");
    if (!datasetObject[month]) {
      throw new Error(`Missing month ${month} in dataset`);
    }
    const prevValue = expectNumber(datasetObject[month][practice]) || 0;
    datasetObject[month] = updateDatasetItem(
      datasetObject[month],
      practice,
      prevValue + eopPaymentAmount,
    );
  });
  return Object.values(datasetObject);
}

function claimsByPayerPractice(
  claims: ClaimWithProcedureMessage[],
): ClaimsByPayerPractice {
  return claims.reduce<{
    [key: string]: { [key: string]: ClaimWithProcedureMessage[] };
  }>((acc, cur) => {
    if (!cur.payer || !cur.practice) {
      return acc;
    }
    const { payer, practice } = cur;
    if (!acc[payer]) {
      acc[payer] = {};
    }
    if (!acc[payer][practice]) {
      acc[payer][practice] = [];
    }
    acc[payer][practice].push(cur);
    return acc;
  }, {});
}

function toDenialsAcrossPayersByPractice(
  claims: ClaimWithProcedureMessage[],
): DatasetType {
  const practices = uniquePracticesFrom(claims);
  const byPayerPractice = claimsByPayerPractice(claims);
  return Object.keys(byPayerPractice).reduce<DatasetType>((dataset, payer) => {
    const claimsByPayer = byPayerPractice[payer];
    if (!claimsByPayer) {
      return dataset;
    }
    let data: DatasetItem = emptyDatasetItem("payer", payer, practices, 0);
    Object.keys(claimsByPayer).forEach((practice) => {
      const claimsForPayerPractice = claimsByPayer[practice];
      if (!claimsForPayerPractice) {
        return;
      }
      data = updateDatasetItem(
        data,
        practice,
        claimsToDenialRate(claimsForPayerPractice),
      );
    });
    return [...dataset, data];
  }, []);
}

export { toPaymentPracticeDataset, toDenialsAcrossPayersByPractice };
