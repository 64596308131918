import React from "react";

import { claimToDenialType, DenialType } from "./charts/charts/helper";
import DenialCode from "./charts/charts/pie/DenialCode";
import ClaimSums from "./charts/charts/table/ClaimSums";
import DenialByProcedure from "./charts/charts/table/DenialByProcedure";
import DenialDescription from "./charts/charts/table/DenialDescription";

function filter(
  claims: ClaimWithProcedureMessage[],
  denialType: DenialType,
): ClaimWithProcedureMessage[] {
  return claims.filter((claim) => claimToDenialType(claim) === denialType);
}

interface DenialInsightsProps {
  claims: ClaimWithProcedureMessage[];
  denialType: DenialType;
}

export default function DenialInsights({
  claims,
  denialType,
}: DenialInsightsProps) {
  const filteredClaims = filter(claims, denialType);
  return (
    <>
      <ClaimSums claims={filteredClaims} />
      <DenialByProcedure claims={filteredClaims} />
      <DenialCode claims={filteredClaims} />
      <DenialDescription claims={filteredClaims} />
    </>
  );
}
