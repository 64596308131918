import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import AmexIcon from "./card-icons/AMEX.svg";
import DefaultIcon from "./card-icons/Default.svg";
import DinersIcon from "./card-icons/Diners.svg";
import DiscoverIcon from "./card-icons/Discover.svg";
import JCBIcon from "./card-icons/JCB.svg";
import MaestroIcon from "./card-icons/Maestro.svg";
import MasterCardIcon from "./card-icons/Master.svg";
import UnionPayIcon from "./card-icons/Union.svg";
import VisaIcon from "./card-icons/Visa.svg";
import { PaymentMethod, PaymentMethodHeader } from "./PaymentMethod";

const iconMapping: Record<string, string> = {
  VISA: VisaIcon,
  AMERICAN_EXPRESS: AmexIcon,
  MASTERCARD: MasterCardIcon,
  DINERS_CLUB_INTERNATIONAL: DinersIcon,
  DINERS_CLUB: DinersIcon,
  DISCOVER: DiscoverIcon,
  JCB: JCBIcon,
  MAESTRO: MaestroIcon,
  CHINA_UNION_PAY: UnionPayIcon,
};

export function SavedPaymentMethodDetails({
  paymentMethod,
}: {
  paymentMethod: PatientPaymentMessage;
}) {
  const theme = useTheme();
  let icon = iconMapping[paymentMethod.brand ?? ""];
  if (!icon) {
    icon = DefaultIcon;
  }
  return (
    <>
      <Typography
        component="div"
        variant="caption"
        color={theme.palette.text.disabled}
        marginTop={1}
      >
        {paymentMethod.paymentMethodType === "CARD"
          ? "Card Number"
          : "Account Number"}
      </Typography>
      <Typography variant="body2" display="flex" alignItems="center">
        {paymentMethod.brand && (
          <img src={icon} alt="Visa" style={{ marginRight: "8px" }} />
        )}
        {paymentMethod.paymentMethodType === "CARD"
          ? `**** **** **** ${paymentMethod.lastFour}`
          : paymentMethod.maskedAccountNumber}
      </Typography>
    </>
  );
}

export function SavedPaymentMethod({
  paymentMethod,
  checked,
  onChange,
}: {
  paymentMethod: PatientPaymentMessage;
  checked?: boolean;
  onChange?: () => void;
}) {
  return (
    <PaymentMethod value={paymentMethod.id}>
      <PaymentMethodHeader checked={checked} onChange={onChange}>
        <Typography
          variant="body1"
          fontSize="18px"
          fontWeight={600}
          lineHeight="160%"
        >
          Associated{" "}
          {paymentMethod.paymentMethodType === "CARD"
            ? "Credit Card"
            : "Bank Account"}
        </Typography>
        <SavedPaymentMethodDetails paymentMethod={paymentMethod} />
      </PaymentMethodHeader>
    </PaymentMethod>
  );
}

SavedPaymentMethod.defaultProps = {
  checked: false,
  onChange: undefined,
};
