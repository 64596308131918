import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export function SuccessPage() {
  return (
    <Box
      display="flex"
      sx={{ flexDirection: "column", alignItems: "center", paddingY: 8 }}
    >
      <Box width={432}>
        <img
          src="/static/images/doctor-okay.png"
          alt="Doctor okay"
          width={137}
          height={120}
        />
        <Typography variant="h3" marginY={3}>
          All set!
        </Typography>
        <Box component="p" marginBottom={6}>
          Your payment details have been set, you will receive a confirmation
          message, and your payment will be processed. Once your payment has
          been processed, you will receive an SMS with a link to download your
          invoice.
        </Box>
      </Box>
    </Box>
  );
}
