import { axisClasses } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { mangoFusionPalette } from "@mui/x-charts/colorPalettes";
import React from "react";

import { formatMoney } from "../../../../../formatters";
import { getMonthLabels } from "../helper";
import { toPaymentAmountSeries, withFormatting } from "./series";

interface TotalPaymentsByInsuranceProps {
  payments: PaymentMessage[];
}

export default function TotalPaymentsByInsurance({
  payments,
}: TotalPaymentsByInsuranceProps) {
  const months = getMonthLabels(payments);
  return (
    <>
      <h2>Total Payments by Insurance</h2>
      <BarChart
        colors={mangoFusionPalette}
        height={400}
        series={toPaymentAmountSeries(payments).map(
          withFormatting(formatMoney),
        )}
        xAxis={[{ data: months, scaleType: "band", label: "Month" }]}
        yAxis={[{ label: "Total Payments ($)" }]}
        margin={{
          left: 100,
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translate(-25px, 0)",
          },
        }}
      />
    </>
  );
}
