import { zodResolver } from "@hookform/resolvers/zod";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiFormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { type Dayjs } from "dayjs";
import React, { Dispatch, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as z from "zod";

import { PaymentPlanStore, PaymentPlanStoreAction } from "../../../store";
import {
  FormControl,
  FormField,
  FormInputLabel,
  FormItem,
  FormMessage,
} from "./Form";
import { TermTable } from "./TermTable";

const formSchema = z.object({
  totalAmount: z.number().min(0.01).multipleOf(0.01),
  downPayment: z.number().min(0).multipleOf(0.01),
  discount: z.number().min(0).max(100).multipleOf(0.01),
  contractValue: z.number().min(0).multipleOf(0.01),
  contractPeriod: z.number().min(0).multipleOf(1),
  withdrawalBeginDate: z
    .instanceof(dayjs as unknown as typeof Dayjs, {
      message: "Withdrawal must be a future date",
    })
    .refine((date) => date.isAfter(dayjs()), {
      message: "Withdrawal must be a future date",
    }),
  contractValuePerPeriod: z.number().min(0),
  installmentAmount: z.number().min(0).multipleOf(0.01),
  lastPayment: z.number().min(0).multipleOf(0.01),
});

type FormValues = z.infer<typeof formSchema>;

export function Terms({
  patientId,
  store,
  updateStore,
}: {
  patientId: string;
  store: PaymentPlanStore;
  updateStore: Dispatch<PaymentPlanStoreAction>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const minWithdrawalBeginDate = dayjs().add(1, "day");

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      withdrawalBeginDate: undefined,
      contractPeriod: 12,
      discount: 0,
    },
    mode: "onBlur",
  });

  const totalAmount = form.watch("totalAmount");
  const downPayment = form.watch("downPayment");
  const discount = form.watch("discount");
  const contractValue = form.watch("contractValue");
  const contractPeriod = form.watch("contractPeriod");
  const withdrawalBeginDate = form.watch("withdrawalBeginDate");

  const installmentAmount = form.watch("installmentAmount");

  useEffect(() => {
    if (totalAmount !== undefined && downPayment !== undefined) {
      const netAmount = totalAmount - downPayment;
      const rawContractValue = netAmount - (netAmount * discount) / 100;
      form.setValue("contractValue", Math.round(rawContractValue * 100) / 100);
    }
  }, [totalAmount, downPayment, discount]);

  useEffect(() => {
    if (contractValue !== undefined && contractPeriod !== undefined) {
      const contractValuePerPeriod = contractValue / contractPeriod;
      form.setValue("contractValuePerPeriod", contractValuePerPeriod);
      const installments = Math.ceil(contractValuePerPeriod * 100) / 100;
      form.setValue("installmentAmount", installments);
      form.setValue(
        "lastPayment",
        Math.round(
          (contractValue - installments * (contractPeriod - 1)) * 100,
        ) / 100,
      );
    }
  }, [contractValue, contractPeriod]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = useCallback(async (data: FormValues) => {
    updateStore({
      type: "SET_PAYMENT_TERMS",
      payload: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(data as any),
      },
    });
    navigate(
      `/patients/${patientId}/payment-plans/new/summary?${searchParams}`,
    );
  }, []);

  // Kick them back if first step isnt complete
  useEffect(() => {
    if (store.paymentMethod === undefined) {
      navigate(`/patients/${patientId}/payment-plans/new?${searchParams}`);
    }
  }, [store.paymentMethod]);

  if (store.paymentMethod === undefined) {
    return null;
  }

  return (
    <Box display="flex" position="relative">
      <Box
        display="flex"
        justifyContent="flex-end"
        width="50%"
        paddingX="64px"
        paddingY="60px"
        minHeight="100%"
        position="relative"
      >
        <Button
          sx={{
            position: "absolute",
            top: "58px",
            right: `${432 + 140}px`,
          }}
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
        <Box width={432} minHeight="100%">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Grid container rowSpacing={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{ marginBottom: "4px" }}>
                    Select Payment Plan
                  </Typography>
                </Grid>
                <FormField
                  control={form.control}
                  name="totalAmount"
                  render={({ field: { value = "", onChange, ...rest } }) => (
                    <FormItem xs={12}>
                      <FormControl>
                        <MuiFormControl variant="outlined" fullWidth>
                          <FormInputLabel>Total amount</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Total amount"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            value={value}
                            onChange={(event) => {
                              const v = parseFloat(event.target.value);
                              onChange?.(Number.isNaN(v) ? undefined : v);
                            }}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="downPayment"
                  render={({ field: { value = "", onChange, ...rest } }) => (
                    <FormItem xs={12}>
                      <FormControl>
                        <MuiFormControl variant="outlined" fullWidth>
                          <FormInputLabel>Down payment</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Down payment"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            value={value}
                            onChange={(event) => {
                              const v = parseFloat(event.target.value);
                              onChange?.(Number.isNaN(v) ? "" : v);
                            }}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="discount"
                  render={({ field: { value = "", onChange, ...rest } }) => (
                    <FormItem xs={6}>
                      <FormControl>
                        <MuiFormControl variant="outlined" fullWidth>
                          <FormInputLabel>Discount</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Discount"
                            endAdornment={
                              <InputAdornment position="end">%</InputAdornment>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            value={value}
                            onChange={(event) => {
                              const v = parseFloat(event.target.value);
                              onChange?.(Number.isNaN(v) ? "" : v);
                            }}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="contractValue"
                  render={({ field: { value = "", onChange, ...rest } }) => (
                    <FormItem xs={6}>
                      <FormControl>
                        <MuiFormControl variant="outlined" fullWidth>
                          <FormInputLabel>Contract value</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Contract value"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            value={value}
                            disabled
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="contractPeriod"
                  render={({ field: { value = "", onChange, ...rest } }) => (
                    <FormItem xs={6}>
                      <FormControl>
                        <MuiFormControl variant="outlined" fullWidth>
                          <FormInputLabel>Contract period</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Contract period"
                            endAdornment={
                              <InputAdornment position="end">
                                Months
                              </InputAdornment>
                            }
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            value={value}
                            onChange={(event) => {
                              const v = parseFloat(event.target.value);
                              onChange?.(Number.isNaN(v) ? "" : v);
                            }}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="withdrawalBeginDate"
                  render={({ field }) => (
                    <FormItem xs={6}>
                      <FormControl>
                        <DatePicker
                          label="Withdrawal begin date"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          minDate={minWithdrawalBeginDate}
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="contractValuePerPeriod"
                  render={({ field: { value, ...rest } }) => (
                    <FormItem xs={12}>
                      <FormControl>
                        <MuiFormControl variant="outlined" disabled fullWidth>
                          <FormInputLabel>Contract value/period</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Contract value/period"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={value?.toFixed(4) ?? ""}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="installmentAmount"
                  render={({ field: { value, ...rest } }) => (
                    <FormItem xs={12}>
                      <FormControl>
                        <MuiFormControl variant="outlined" disabled fullWidth>
                          <FormInputLabel>Installment amount</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Installment amount"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={value?.toFixed(2) ?? ""}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastPayment"
                  render={({ field: { value, ...rest } }) => (
                    <FormItem xs={12}>
                      <FormControl>
                        <MuiFormControl variant="outlined" disabled fullWidth>
                          <FormInputLabel>Last payment</FormInputLabel>
                          <OutlinedInput
                            type="number"
                            label="Last payment"
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            value={value?.toFixed(2) ?? ""}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                          />
                        </MuiFormControl>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    fullWidth
                    type="submit"
                    // onClick={() => {
                    //   navigate(
                    //     `/patients/${patientId}/payment-plans/new/summary`,
                    //   );
                    // }}
                  >
                    Confirm Payment Plan
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>

      <TermTable
        contractValue={contractValue}
        contractPeriod={contractPeriod}
        withdrawalBeginDate={withdrawalBeginDate}
        installmentAmount={installmentAmount}
      />
    </Box>
  );
}
