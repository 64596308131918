import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import React from "react";

import { denialCodeData } from "./data";

interface DenialCodeProps {
  claims: ClaimWithProcedureMessage[];
}

interface CodeData {
  value: number;
  label: string;
}

function topDenialCodes(data: CodeData[], cutoffRank: number): CodeData[] {
  return data.sort((a, b) => b.value - a.value).slice(0, cutoffRank);
}

export default function DenialCode({ claims }: DenialCodeProps) {
  return (
    <>
      <h2>Denial Code</h2>
      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.label}`,
            arcLabelMinAngle: 20,
            data: topDenialCodes(denialCodeData(claims), 10),
          },
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
          },
        }}
        width={700}
        height={350}
      />
    </>
  );
}
