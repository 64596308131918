import { zodResolver } from "@hookform/resolvers/zod";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { redactText } from "../../../redact";
import type {
  PatientDetailsFields,
  ResponsibleDetailsFields,
} from "../../patientsDashboard.interfaces";
import { hasTruthyProperty } from "../../utils";
import {
  extendedFormSchema,
  FormFields,
  patientSchema,
  responsibleSchema,
} from "./PatientDetailsForm.zod";
import { PatientDetailsFormFields } from "./PatientDetailsFormFields";
import { PatientDetailsFormSubmit } from "./PatientDetailsFormSubmit";
import { PatientDetailsResponsiblePartyFormFields } from "./PatientDetailsResponsiblePartyFormFields";
import { ToggleResponsiblePartyFormFields } from "./ToggleResponsiblePartyFormFields";

interface PatientDetailsFormProps {
  patientDetailsFields: PatientDetailsFields;
  responsibleDetailsFields: ResponsibleDetailsFields;
  readOnly?: boolean;
  onSubmit: (data: FormFields) => void;
  defaultResponsibleForm: boolean;
  practices: PracticeMessage[];
}

export function PatientDetailsForm({
  patientDetailsFields,
  responsibleDetailsFields,
  readOnly,
  onSubmit,
  defaultResponsibleForm,
  practices,
}: PatientDetailsFormProps) {
  const [toggleResponsibleForm, setToggleResponsibleForm] = useState<boolean>(
    () => hasTruthyProperty(responsibleDetailsFields) || defaultResponsibleForm,
  );

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FormFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: toggleResponsibleForm
      ? zodResolver(extendedFormSchema)
      : zodResolver(patientSchema),
    defaultValues: {
      email: redactText(patientDetailsFields.email) || null,
      name: redactText(patientDetailsFields.name) || "",
      ssn: redactText(patientDetailsFields.ssn) || null,
      dob: redactText(patientDetailsFields.dob) || undefined,
      phoneNumber: redactText(patientDetailsFields.phoneNumber) || null,
      address: redactText(patientDetailsFields.address) || null,
      city: patientDetailsFields.city || null,
      state: patientDetailsFields.state || null,
      zipCode: patientDetailsFields.zipCode || null,
      practiceId: patientDetailsFields.practiceId,
      // Defaults responsible person fields if properties exist
      isAdditionalInfoRequired: toggleResponsibleForm,

      responsibleName:
        redactText(responsibleDetailsFields.responsibleName) || "",
      responsibleSsn:
        redactText(responsibleDetailsFields.responsibleSsn) || null,
      responsibleMobilePhone:
        redactText(responsibleDetailsFields.responsibleMobilePhone) || "",
      responsibleWorkPhone:
        redactText(responsibleDetailsFields.responsibleWorkPhone) || null,
      responsibleEmail:
        redactText(responsibleDetailsFields.responsibleEmail) || "",
      responsibleAddress:
        redactText(responsibleDetailsFields.responsibleAddress) || "",
      responsibleCity: responsibleDetailsFields.responsibleCity || "",
      responsibleState: responsibleDetailsFields.responsibleState || "",
      responsibleZipCode: responsibleDetailsFields.responsibleZipCode || "",
    },
  });

  const hasErrors = Object.keys(errors).length > 0;
  const disable = hasErrors || isSubmitting;

  /**
   * The effect is executed whenever toggleResponsibleForm state changes.
   * It's purpose is to clear any remaining errors from the responsible person form if a user
   * toggles the form off. These errors are not cleared by default and can prevent form submission.
   */
  useEffect(() => {
    const isToggledOff = !toggleResponsibleForm;

    if (isToggledOff) {
      Object.keys(responsibleSchema.shape).forEach((fieldName) =>
        clearErrors(fieldName as keyof FormFields),
      );
    }
  }, [toggleResponsibleForm, clearErrors]);

  return (
    <Box
      noValidate
      component="form"
      onSubmit={handleSubmit((data) => onSubmit(data))}
    >
      <PatientDetailsFormFields
        control={control}
        readOnly={readOnly}
        practices={practices}
      />
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: readOnly ? 0 : "1.5rem",
          marginTop: "1.5rem",
        }}
      >
        <ToggleResponsiblePartyFormFields
          control={control}
          defaultChecked={toggleResponsibleForm}
          onChange={() => setToggleResponsibleForm(!toggleResponsibleForm)}
          readOnly={readOnly}
        />

        {toggleResponsibleForm && (
          <PatientDetailsResponsiblePartyFormFields
            control={control}
            readOnly={readOnly}
          />
        )}
        {readOnly || (
          <PatientDetailsFormSubmit copy="default" disable={disable} />
        )}
      </Box>
    </Box>
  );
}

PatientDetailsForm.defaultProps = {
  readOnly: false,
};
