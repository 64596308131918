import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FINIX_APPLICATION_ID, FINIX_ENVIRONMENT } from "../../api/apiConfig";
import type QueryClient from "../../api/query/queryClient";
import Loading from "../Loading";
import {
  PaymentMethod,
  PaymentMethodBody,
  PaymentMethodHeader,
} from "../PaymentPlans/views/create/Payment/PaymentMethod";
import { useFinix } from "../PaymentPlans/views/create/Payment/useFinix";
import PaymentMethodCard from "./components/PaymentMethodCard";

interface PaymentMethodsPageProps {
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function PaymentMethodsPage({
  queryClient,
  updateSnackBar,
}: PaymentMethodsPageProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [status, setStatus] = useState<
    "loading" | "loaded" | "error" | "submitting"
  >("loading");
  const [methods, setMethods] = useState<PatientPaymentMessage[] | undefined>(
    undefined,
  );

  const [selectedMethod, setSelectedMethod] = React.useState<string>("");
  const [methodToDelete, setMethodToDelete] = useState<string | undefined>();

  const {
    loading: finixLoading,
    form,
    loadBankPaymentMethod,
    loadCardPaymentMethod,
    canSubmit,
  } = useFinix();

  const handlePaymentMethodChange = useCallback(
    (_: React.ChangeEvent, value: string) => {
      if (value === "__bank__") {
        loadBankPaymentMethod("method-bank");
      } else if (value === "__credit__") {
        loadCardPaymentMethod("method-card");
      }

      setSelectedMethod(value);
    },
    [],
  );

  const handleDeletePaymentMethod = async () => {
    if (sessionId && methodToDelete) {
      setStatus("loading");
      try {
        await queryClient.deletePublicPaymentMethod(sessionId, methodToDelete);
        updateSnackBar({
          severity: "success",
          message: "Payment method removed",
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to delete payment method",
        });
      }
      setMethods(methods?.filter((method) => method.id !== methodToDelete));
      setMethodToDelete(undefined);
      setStatus("loaded");
    }
  };

  const loadPatientPayments = useCallback(async () => {
    setStatus("loading");
    if (sessionId) {
      try {
        // this also serves as the authentication check since
        const payments = await queryClient.getPublicPatientPayments(sessionId);
        setMethods(payments);
        setStatus("loaded");
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setStatus("error");
      }
    }
  }, [sessionId]);

  const handleSubmit = async () => {
    if (selectedMethod === "__bank__" || selectedMethod === "__credit__") {
      if (!form || !sessionId) {
        updateSnackBar({
          severity: "error",
          message:
            "Failed to create payment method, please reload the page and retry",
        });
        return;
      }
      setStatus("submitting");
      form.submit(FINIX_ENVIRONMENT, FINIX_APPLICATION_ID, async (err, res) => {
        if (err) {
          let message = "Failed to create payment method";
          if (err?.status === 400) {
            message = "Invalid payment information";
          }
          updateSnackBar({
            severity: "error",
            message,
          });
          setStatus("loaded");
        } else {
          try {
            const tokenData = res.data || {};
            await queryClient.postPublicPatientPayment(
              sessionId,
              tokenData?.id,
            );
            navigate(`/patient-portal/${sessionId}/success`);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            updateSnackBar({
              severity: "error",
              message:
                "Failed to create payment method, please reload the page and retry",
            });
          }
        }
      });
    } else if (selectedMethod && sessionId) {
      setStatus("submitting");
      await queryClient.setPublicPatientPrimaryPaymentMethod(
        sessionId,
        selectedMethod,
      );
      navigate(`/patient-portal/${sessionId}/success`);
    }
  };

  useEffect(() => {
    if (sessionId) {
      loadPatientPayments();
    }
  }, [sessionId]);

  if (status === "loading" || finixLoading) {
    return <Loading />;
  }

  if (status === "error") {
    return (
      <Container
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          paddingY: 8,
          flexDirection: "column",
        }}
      >
        <Typography variant="h5">The link has expired.</Typography>
        <Typography variant="body1" marginTop={2}>
          Please request a new one.
        </Typography>
      </Container>
    );
  }

  const paymentFormComplete =
    selectedMethod === "" ||
    ((selectedMethod === "__bank__" || selectedMethod === "__credit__") &&
      !canSubmit);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "100%",
            marginBottom: 8,
            flexGrow: 1,
            padding: 2,
            marginTop: 6,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <RadioGroup
            name="payment-method"
            value={selectedMethod}
            onChange={handlePaymentMethodChange}
          >
            {(methods ?? []).length > 0 && (
              <>
                <Typography variant="h6" marginBottom={2}>
                  Associated Payment Methods
                </Typography>
                <Grid container spacing={2}>
                  {(methods ?? []).map((method) => (
                    <Grid key={method.id} item xs={12}>
                      <PaymentMethodCard
                        paymentMethod={method}
                        canRemove={(methods?.length ?? 0) > 1}
                        onSetAsPrimary={setSelectedMethod}
                        onDelete={setMethodToDelete}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

            <Typography variant="h6" marginTop={4} marginBottom={2}>
              Add New Payment Method
            </Typography>

            <PaymentMethod value="__bank__">
              <PaymentMethodHeader>
                <Box display="flex" alignItems="start">
                  <Box paddingTop="7px">
                    <AccountBalanceOutlined />
                  </Box>
                  <Box marginLeft={2}>
                    <Typography
                      variant="body1"
                      fontSize="18px"
                      fontWeight={600}
                      lineHeight="160%"
                    >
                      Savings or Checking Account
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      lineHeight="157%"
                      color={theme.palette.text.secondary}
                    >
                      Payments associated to a bank account
                    </Typography>
                  </Box>
                </Box>
              </PaymentMethodHeader>
              <PaymentMethodBody>
                <Box id="method-bank" />
              </PaymentMethodBody>
            </PaymentMethod>

            <PaymentMethod value="__credit__">
              <PaymentMethodHeader>
                <Box display="flex" alignItems="start">
                  <Box paddingTop="4px">
                    <CreditCardOutlined />
                  </Box>
                  <Box marginLeft={2}>
                    <Typography
                      variant="body1"
                      fontSize="18px"
                      fontWeight={600}
                      lineHeight="160%"
                    >
                      Credit or Debit Card
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      lineHeight="157%"
                      color={theme.palette.text.secondary}
                    >
                      Payments through a credit or debit card
                    </Typography>
                  </Box>
                </Box>
              </PaymentMethodHeader>
              <PaymentMethodBody>
                <Box id="method-card" />
              </PaymentMethodBody>
            </PaymentMethod>
          </RadioGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: 100,
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid rgba(37, 35, 63, 0.12)",
            padding: 2,
            backgroundColor: "white",
          }}
        >
          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{ maxWidth: "800px" }}
            disabled={status === "submitting" || paymentFormComplete}
            onClick={handleSubmit}
          >
            Select payment method
          </Button>
        </Box>
      </Box>
      <Dialog
        open={!!methodToDelete}
        onClose={() => setMethodToDelete(undefined)}
        maxWidth="xs"
      >
        <DialogContent sx={{ padding: 2, paddingTop: 2.5, paddingBottom: 0 }}>
          <DialogContentText color="black">
            Are you sure you want to permanently delete this payment method?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={() => setMethodToDelete(undefined)}>No</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeletePaymentMethod}
            autoFocus
          >
            Delete Payment Method
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
