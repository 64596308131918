import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

const submitCopy = {
  default: "Save Patient Details",
  isSubmitting: "Saving Patient Details",
};

interface PatientDetailsFormSubmitProps {
  disable: boolean;
  copy: keyof typeof submitCopy;
}

export function PatientDetailsFormSubmit({
  disable,
  copy,
}: PatientDetailsFormSubmitProps) {
  const theme = useTheme();

  return (
    <>
      <Button
        data-testid="submitPatientForm"
        fullWidth
        size="large"
        type="submit"
        color="success"
        disabled={disable}
        variant="contained"
        sx={{ fontWeight: 600 }}
      >
        {submitCopy[copy]}
      </Button>
      <Typography
        sx={{
          color: theme.palette.text.secondary,
          fontSize: "12px",
          marginTop: theme.spacing(1),
        }}
      >
        * These fields are mandatory
      </Typography>
    </>
  );
}
