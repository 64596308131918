type DeniedProcedure = Omit<ProcedureMessage, "procedureDenialCode"> & {
  procedureDenialCode: string;
};

function isDenial(procedure: ProcedureMessage): procedure is DeniedProcedure {
  return procedure.procedurePayerPays === 0 && !!procedure.procedureDenialCode;
}

function denialCodeData(claims: ClaimWithProcedureMessage[]) {
  const procedures = claims.map((claim) => claim.Procedure).flat();
  return Object.values(
    procedures.reduce(
      (acc, procedure) => {
        if (!isDenial(procedure)) {
          return acc;
        }
        if (!acc[procedure.procedureDenialCode]) {
          acc[procedure.procedureDenialCode] = {
            value: 0,
            label: procedure.procedureDenialCode,
          };
        }
        acc[procedure.procedureDenialCode].value += 1;

        return acc;
      },
      {} as { [key: string]: { value: number; label: string } },
    ),
  );
}

export { denialCodeData };
