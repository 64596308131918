import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

export default function textArea(
  label: string,
  text: string,
  onTextChange: (text: string) => void,
  enableEdit: boolean,
  editAndSave: (isSave: boolean) => void,
) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", paddingRight: "5px" }}
          >
            {label}
          </Typography>
          <button
            style={{ marginTop: "4px" }}
            type="button"
            onClick={() => {
              editAndSave(enableEdit);
            }}
          >
            {enableEdit ? <SaveIcon /> : <EditIcon />}
          </button>
        </div>
        <TextField
          disabled={!enableEdit}
          multiline
          variant="standard"
          fullWidth
          InputProps={{
            style: {
              fontSize: 12,
              color: "#475467",
              backgroundColor: "#fff",
              minHeight: "4.375rem",
              border: ".5px solid #E5ECDF",
              borderRadius: "12px",
              padding: "0px 10px",
            },
            disableUnderline: true,
          }}
          value={text}
          placeholder="Enter your notes here"
          id="action-textfield"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            onTextChange(event.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}
