export enum PostedState {
  UNPOSTED,
  FAILURE,
  AUTO_POSTED,
  MANUALLY_POSTED,
  POSTING,
  RETRY,
}

export function hasPosted(claim: ClaimWithProcedureMessage): boolean {
  return [
    PostedState[PostedState.AUTO_POSTED],
    PostedState[PostedState.MANUALLY_POSTED],
  ].includes(claim.postedState);
}
