/** @file Implements the Overview tab from report_streamlit. */

import React from "react";

import ClaimPaymentTypes from "./charts/charts/bar/ClaimPaymentTypes";
import DenialRatesAcrossPayerAndPractice from "./charts/charts/bar/DenialRatesAcrossPayerAndPractice";
import PaymentsByPractice from "./charts/charts/bar/PaymentsByPractice";
import PostedClaims from "./charts/charts/bar/PostedClaims";
import TotalPaymentsByInsurance from "./charts/charts/bar/TotalPaymentsByInsurance";
import ClaimUrgencyActionForDenials from "./charts/charts/heatmap/ClaimUrgencyActionForDenials";
import AvgPaymentByProcedure from "./charts/charts/table/AvgPaymentByProcedure";

interface PaymentInsightsProps {
  payments: PaymentMessage[];
  claims: ClaimWithProcedureMessage[];
}

export default function PaymentInsights({
  payments,
  claims,
}: PaymentInsightsProps) {
  return (
    <>
      <PaymentsByPractice payments={payments} />
      <TotalPaymentsByInsurance payments={payments} />
      <AvgPaymentByProcedure claims={claims} />
      <DenialRatesAcrossPayerAndPractice claims={claims} />
      <PostedClaims claims={claims} />
      <ClaimPaymentTypes claims={claims} />
      <ClaimUrgencyActionForDenials claims={claims} />
    </>
  );
}
