import { Theme, useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useRef, useState } from "react";

const getFormOptions = (theme: Theme): FinixFormOptions => ({
  showAddress: true,
  showPlaceholders: false,
  labels: {
    bank_code: "Routing Number",
  },
  requiredFields: [
    "name",
    "address_line1",
    "address_city",
    "address_region",
    "address_state",
    "address_country",
    "address_postal_code",
  ],
  styles: {
    // default styling for all fields
    default: {
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: "8px 16px",
      // fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      boxShadow: theme.shadows[0],
    },
    success: {
      // specific styling if the field is valid
      color: theme.palette.text.primary,
    },
    error: {
      color: theme.palette.error.main, // specific styling if the field has any errors
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
});

export function useFinix() {
  const theme = useTheme();
  const [form, setForm] = useState<FinixForm | null>(null);
  const elRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [canSubmit, setCanSubmit] = useState(false);

  const loadBankPaymentMethod = useCallback((id: string) => {
    const bankEl = document.getElementById(id);
    if (bankEl && elRef.current) {
      bankEl.innerHTML = "";
      elRef.current.innerHTML = "";
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      bankEl.appendChild(elRef.current);
    }

    const options = getFormOptions(theme);
    options.onUpdate = function onUpdate(
      _state: object,
      _binInformation: object,
      formHasErrors: boolean,
    ) {
      setCanSubmit(!formHasErrors);
    };
    setForm(window.Finix.BankTokenForm("finix", options));
  }, []);

  const loadCardPaymentMethod = useCallback((id: string) => {
    const cardEl = document.getElementById(id);
    if (cardEl && elRef.current) {
      cardEl.innerHTML = "";
      elRef.current.innerHTML = "";
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cardEl.appendChild(elRef.current);
    }
    const options = getFormOptions(theme);
    options.onUpdate = function onUpdate(
      _state: object,
      _binInformation: object,
      formHasErrors: boolean,
    ) {
      setCanSubmit(!formHasErrors);
    };
    setForm(window.Finix.CardTokenForm("finix", options));
  }, []);

  useEffect(() => {
    // Create script
    const script = document.createElement("script");
    script.src = "https://js.finix.com/v/1/finix.js";
    script.async = true;

    const div = document.createElement("div");
    div.id = "finix";
    elRef.current = div;

    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
      setError("Failed to load payment module");
    };

    script.addEventListener("load", handleLoad);
    script.addEventListener("error", handleError);

    // Append script to the body
    document.body.appendChild(script);

    // Remove script from the body on cleanup
    return () => {
      script.removeEventListener("load", handleLoad);
      script.removeEventListener("error", handleError);
      document.body.removeChild(script);
    };
  }, []);

  return {
    canSubmit,
    loading: isLoading,
    error,
    form,
    loadBankPaymentMethod,
    loadCardPaymentMethod,
  };
}
