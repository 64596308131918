import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../formatters";
import { enumToStrings, isoToDate, toPascalCase } from "../../utils/utils";
import { BankTransactionType } from "../bankMatch";
import { NavigationCell } from "../ClaimsTable/ui/NavigationCell";
import { renderCellRedacted } from "../redact";

function renderTransactionType(
  props: GridRenderCellParams,
  transactionUpdate: (transactionUpdate: BankTransactionUpdateMessage) => void,
): JSX.Element {
  const { value, row: transaction } = props;
  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        labelId="transaction-type-select-label"
        id="transaction-type-simple-select"
        value={value}
        onChange={(e) => {
          const { wieldyId, practiceId } = transaction;
          transactionUpdate({
            wieldyId,
            practiceId,
            transactionType: e.target.value as keyof typeof BankTransactionType,
          });
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          borderRadius: "12px",
        }}
      >
        {enumToStrings(BankTransactionType).map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const COLUMN_DEFAULT_VISIBILITY = {
  practice: true,
  bankDate: true,
  checkNumber: true,
  description: true,
  deposit: true,
  withdrawal: true,
  endingBalance: false,
  paymentKey: false,
  transactionType: true,
};

export function getDefaultColumns(
  practiceValueOptions: string[],
  searchParams: URLSearchParams,
  transactionUpdate: (transactionUpdate: BankTransactionUpdateMessage) => void,
): GridColDef[] {
  return [
    {
      flex: 0.75,
      field: "practice",
      headerName: "Practice",
      type: "singleSelect",
      valueOptions: practiceValueOptions.length
        ? practiceValueOptions
        : undefined,
    },
    {
      flex: 0.75,
      type: "date",
      field: "bankDate",
      headerName: "Bank Date",
      valueGetter: ({ value }: GridRenderCellParams) =>
        value ? isoToDate(value) : "",
    },
    {
      flex: 0.5,
      field: "checkNumber",
      headerName: "Check Number",
    },
    {
      flex: 3,
      field: "description",
      headerName: "Description",
      renderCell: (props: GridRenderCellParams) => renderCellRedacted(props),
    },
    {
      flex: 0.65,
      type: "number",
      field: "deposit",
      headerName: "Deposit",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 0.65,
      type: "number",
      field: "withdrawal",
      headerName: "Withdrawal",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 0.65,
      type: "number",
      field: "endingBalance",
      headerName: "Ending Balance",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
    },
    {
      flex: 1,
      type: "singleSelect",
      field: "transactionType",
      headerName: "Transaction Type",
      valueOptions: enumToStrings(BankTransactionType).map((type) => ({
        value: type.toString(),
        label: toPascalCase(type).replace("_", " "),
      })),
      renderCell: (props: GridRenderCellParams) =>
        renderTransactionType(props, transactionUpdate),
    },
    {
      flex: 1,
      field: "paymentKey",
      headerName: "Payment ID",
      renderCell: ({ value }: GridRenderCellParams) => {
        if (value) {
          const paymentId = value.split(".")[1];
          return (
            <NavigationCell
              linkPath={`/payments/${value}?${searchParams}`}
              displayText={paymentId}
              ariaLabel="View payment details"
            />
          );
        }
        return <div>-</div>;
      },
    },
  ];
}
