import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AlertColor } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";

import { isDevMode } from "../../utils/utils";
import PaymentLedger from "../PaymentLedger";
import {
  initialPatientPaymentsStore,
  PatientPaymentsStore,
  patientPaymentsStoreReducer,
} from "./store";

interface PaymentPlanProps {
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  paymentPlanStore: PatientPaymentsStore;
}

export { initialPatientPaymentsStore, patientPaymentsStoreReducer };

export default function PatientsPaymentDashboard({
  updateSnackBar,
  paymentPlanStore,
}: PaymentPlanProps) {
  const theme = useTheme();
  const devMode = isDevMode();
  const [selectedTab, setSelectedTab] = React.useState("today");
  if (!devMode) {
    return null;
  }
  return (
    <Box sx={{ paddingTop: theme.spacing(4) }}>
      <Box sx={{ marginBottom: theme.spacing(2), display: "flex" }}>
        <Tabs
          value={selectedTab}
          onChange={(_event, value) => setSelectedTab(value)}
          sx={{
            minHeight: "42px",
            height: "auto",
            width: "414px",
            marginLeft: "auto",
            marginRight: "12px",
          }}
          variant="fullWidth"
        >
          <Tab label="Today" value="today" sx={{ minHeight: "auto" }} />
          <Tab label="Week" value="week" sx={{ minHeight: "auto" }} />
          <Tab label="Month" value="month" sx={{ minHeight: "auto" }} />
          <Tab label="All" value="all" sx={{ minHeight: "auto" }} />
        </Tabs>
        <IconButton
          sx={{
            backgroundColor: theme.palette.grey[200],
            borderRadius: "8px",
            border: "1px solid rgba(37, 35, 63, 0.12)",
          }}
        >
          <CalendarTodayIcon />
        </IconButton>
      </Box>
      <PaymentLedger
        updateSnackBar={updateSnackBar}
        paymentPlanStore={paymentPlanStore}
      />
    </Box>
  );
}
