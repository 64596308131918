import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import dayjs from "dayjs";

import { ClaimStatus } from "../../../../../status";
import { expectValue } from "../../../../../utils/utils";
import { hasPosted } from "../../../../posting";
import {
  CLAIM_STATUS_LABELS,
  CLAIM_STATUS_MAP,
  claimsToDenialRate,
  getMonthLabels,
  toClaimsByPayer,
  uniquePayersFrom,
  uniquePracticesFrom,
} from "../helper";

function intMonthFromDateString(dateString: string): number {
  return new Date(dateString).getUTCMonth();
}

export { intMonthFromDateString };

function emptyStack(label: string, fillLength: number): BarSeriesType {
  return {
    data: new Array(fillLength).fill(0),
    label,
    id: label,
    stack: "total",
    type: "bar",
  };
}

function emptyBar(fillLength: number): BarSeriesType {
  return {
    data: new Array(fillLength).fill(0),
    stack: "total",
    type: "bar",
  };
}

function toPaymentAmountSeries(payments: PaymentMessage[]): BarSeriesType[] {
  const months = getMonthLabels(payments);
  const seriesObject = payments.reduce<{ [key: string]: BarSeriesType }>(
    (acc, payment) => {
      if (!payment.payer || !payment.paymentDate || !payment.eopPaymentAmount) {
        return acc;
      }
      const { payer, paymentDate, eopPaymentAmount } = payment;
      if (!acc[payer]) {
        acc[payer] = emptyStack(payer, months.length);
      }
      const month = dayjs(paymentDate).format("MMM-YYYY");
      // We created .data so it's not possible for it to be null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      acc[payer].data![months.indexOf(month)] += eopPaymentAmount;
      return acc;
    },
    {},
  );
  return Object.values(seriesObject);
}

function claimsToPostSeries(
  claims: ClaimWithProcedureMessage[],
): BarSeriesType[] {
  const months = getMonthLabels(claims);
  return claims.reduce<BarSeriesType[]>(
    (acc, claim) => {
      if (!claim.wieldyClaimDate) {
        return acc;
      }
      const month = dayjs(claim.wieldyClaimDate).format("MMM-YYYY");
      const series = acc[hasPosted(claim) && acc[0].data ? 0 : 1];
      series.data = series.data || []; // Reassure TypeScript to avoid error ts(18048).
      series.data[months.indexOf(month)] += 1;
      return acc;
    },
    [
      emptyStack("Posted", months.length),
      emptyStack("Not Posted", months.length),
    ],
  );
}

function toClaimsByStatusSeries(
  claims: ClaimWithProcedureMessage[],
): BarSeriesType[] {
  const months = getMonthLabels(claims);
  return claims.reduce(
    (acc, claim) => {
      if (!claim.wieldyClaimDate || !claim.claimStatus) {
        return acc;
      }
      const month = dayjs(claim.wieldyClaimDate).format("MMM-YYYY");
      const monthIndex = months.indexOf(month);
      const index =
        CLAIM_STATUS_MAP[claim.claimStatus as keyof typeof ClaimStatus];
      // Using "regular" equality operator ("==")
      // because we want to match null and undefined
      if (index != null && acc?.[index]?.data?.[monthIndex] != null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        acc[index].data![monthIndex] += 1;
      }
      return acc;
    },
    CLAIM_STATUS_LABELS.map((label) => emptyStack(label, months.length)),
  );
}

function claimToDenialRatesSeries(
  claims: ClaimWithProcedureMessage[],
): BarSeriesType[] {
  const claimsByPayer = toClaimsByPayer(claims);
  const uniquePayers = uniquePayersFrom(claims);
  const payerIndexMap = uniquePayers.reduce(
    (acc, payer, index) => ({ ...acc, [payer]: index }),
    {} as { [key: string]: number },
  );
  return [
    uniquePayers.reduce((acc, payer) => {
      const payerIndex = expectValue(payerIndexMap[payer]);
      const payerClaims = expectValue(claimsByPayer.get(payer));
      expectValue(acc.data)[payerIndex] = claimsToDenialRate(payerClaims);
      return acc;
    }, emptyBar(uniquePayers.length)),
  ];
}

function claimsByPractice(claims: ClaimWithProcedureMessage[]): {
  [key: string]: ClaimWithProcedureMessage[];
} {
  return claims.reduce<{ [key: string]: ClaimWithProcedureMessage[] }>(
    (acc, claim) => {
      if (!claim.practice) {
        return acc;
      }
      const { practice } = claim;
      if (!acc[practice]) {
        acc[practice] = [];
      }
      acc[practice].push(claim);
      return acc;
    },
    {},
  );
}

function claimToDenialRateByPracticeSeries(
  claims: ClaimWithProcedureMessage[],
): BarSeriesType[] {
  const practices = uniquePracticesFrom(claims);
  const practiceIndexMap = practices.reduce<{ [key: string]: number }>(
    (acc, practice, currentIndex) => ({ ...acc, [practice]: currentIndex }),
    {},
  );
  const byPractice = claimsByPractice(claims);
  return [
    Object.keys(byPractice).reduce<BarSeriesType>((acc, practice) => {
      const claimsForPractice = byPractice[practice];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      acc.data![practiceIndexMap[practice]] =
        claimsToDenialRate(claimsForPractice);
      return acc;
    }, emptyBar(practices.length)),
  ];
}

function withFormatting(
  formatFn: (val: number | null) => string,
): (bar: BarSeriesType) => BarSeriesType {
  return (bar) => ({
    ...bar,
    valueFormatter: formatFn,
  });
}

export {
  toPaymentAmountSeries,
  claimsToPostSeries,
  toClaimsByStatusSeries,
  claimToDenialRatesSeries,
  claimToDenialRateByPracticeSeries,
  withFormatting,
};
