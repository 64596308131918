import { Box, FormControl, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { Control, Controller } from "react-hook-form";

import { InputWrapper } from "../CredentialsDashboard/ui/InputWrapper";
import { ErrorHelperText } from "../PatientsDashboard/ui/ErrorHelper";
import { AddClaimFormFields, editableClaimFields } from "./CrudForms.zod";
import { renderField } from "./FormFieldRenderer";

interface NewClaimFormProps {
  control: Control<AddClaimFormFields>;
  practices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function NewClaimForm({
  control,
  practices,
  supportedPayers,
}: NewClaimFormProps) {
  const theme = useTheme();

  const regularFields = editableClaimFields.filter(
    (field) => field.type !== "dropdown",
  );

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: "600" }}>
        Claim Details
      </Typography>
      <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="practiceId"
              control={control}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputWrapper>
                    <TextField
                      select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      name="practiceId"
                      inputRef={ref}
                      label="Practice"
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {practices.map((practice) => (
                        <MenuItem
                          key={practice.wieldyId}
                          value={practice.wieldyId}
                        >
                          {practice.displayName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorHelperText message={error?.message} />
                  </InputWrapper>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="payer"
              control={control}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { error },
              }) => (
                <FormControl sx={{ width: "100%" }}>
                  <InputWrapper>
                    <TextField
                      select
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value ?? ""}
                      name="payer"
                      inputRef={ref}
                      label="Payer"
                      variant="outlined"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {supportedPayers.map((payer) => (
                        <MenuItem key={payer.wieldyId} value={payer.name}>
                          {payer.displayLabel}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorHelperText message={error?.message} />
                  </InputWrapper>
                </FormControl>
              )}
            />
          </Grid>
          {regularFields.map((field) =>
            renderField<AddClaimFormFields>(control, field),
          )}
        </Grid>
      </Box>
    </>
  );
}
