import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { type ReactNode, useState } from "react";

import { getNestedChildText } from "../../../utils/utils";

const mask = "**********";

export function MaskValue({
  children,
  unmaskValue,
}: {
  children: ReactNode;
  unmaskValue: () => Promise<string>;
}) {
  const [displayValue, setDisplayValue] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        "div:first-child": {
          width: "100px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
      }}
    >
      {displayValue ? children : <div>{mask}</div>}
      <div>
        <IconButton
          sx={{ color: "#475467", padding: "5px" }}
          onClick={async () => {
            if (!displayValue) {
              const unmasked = await unmaskValue();
              if (unmasked !== mask) {
                window.navigator.clipboard.writeText(unmasked);
              }
              return;
            }
            const textToCopy = getNestedChildText(children);
            if (textToCopy) {
              window.navigator.clipboard.writeText(textToCopy);
            }
          }}
        >
          <CopyAllOutlinedIcon sx={{ width: "20px", height: "20px" }} />
        </IconButton>
        <IconButton
          aria-label="toggle password"
          size="small"
          sx={{ color: "#475467", padding: "5px" }}
          onClick={async () => {
            if (!displayValue) {
              await unmaskValue();
            }
            setDisplayValue(!displayValue);
          }}
        >
          {displayValue ? (
            <VisibilityOffOutlinedIcon sx={{ width: "20px", height: "20px" }} />
          ) : (
            <VisibilityOutlinedIcon sx={{ width: "20px", height: "20px" }} />
          )}
        </IconButton>
      </div>
    </Box>
  );
}
