import CopyAllOutlinedIcon from "@mui/icons-material/CopyAllOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/Help";
import { Tooltip, useTheme, Zoom } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  GridActionsCellItem,
  type GridColDef,
  type GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import React from "react";
import { Link } from "react-router-dom";

import { CredentialStatus } from "../../credentialStatus";
import { redactText, renderCellRedacted } from "../../redact";
import { CREDENTIAL_TYPE, typeFromCredential } from "../common";
import { MaskValue } from "../ui/Mask";
import { InvalidChip, NotWorkingChip, WorkingChip } from "../ui/StateChips";

export function getDefaultColumns(
  practiceNames: string[],
  credentialNames: string[],
  supportedPayersMap: Record<string, CredentialsSupportedPayersMessage>,
  openEdit: (row: Partial<UserCredentialsMessage>) => void,
  openDelete: (row: Partial<UserCredentialsMessage>) => void,
): GridColDef[] {
  return [
    {
      field: "type",
      headerName: "Type",
      type: "singleSelect",
      valueOptions: Object.values(CREDENTIAL_TYPE),
      valueGetter: ({ row }) => typeFromCredential(row),
      flex: 1,
    },
    {
      field: "practiceId",
      headerName: "Practice",
      type: "singleSelect",
      valueOptions: practiceNames,
      valueGetter: ({ value, row }: GridRenderCellParams) => {
        const { practices }: { practices: PracticeMessage[] } = row;
        const practice = practices.find(
          (practiceObj) => practiceObj.wieldyId === value,
        );
        return practice?.displayName;
      },
      renderCell: ({ value }: GridRenderCellParams) => (
        <div data-sr-redact>{redactText(value)}</div>
      ),
      flex: 1,
    },
    {
      field: "payerName",
      headerName: "Credential",
      type: "singleSelect",
      valueOptions: credentialNames,
      valueGetter: ({ row, value }: GridRenderCellParams) => row.name || value,
      flex: 1,
      renderCell: (props: GridRenderCellParams) => {
        const credential = props.row;
        const website =
          credential.website || supportedPayersMap[credential.payerId]?.website;
        if (website) {
          const theme = useTheme();
          return (
            <Link
              style={{
                textDecoration: "underline",
                color: theme.palette.primary.main,
              }}
              target="_blank"
              rel="noopener noreferrer"
              to={website}
            >
              <div>{props.value}</div>
            </Link>
          );
        }
        return <div>{props.value}</div>;
      },
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (props: GridRenderCellParams) => (
        <MaskValue unmaskValue={async () => props.row.unmaskValue("username")}>
          {renderCellRedacted(props)}
        </MaskValue>
      ),
    },
    {
      field: "password",
      headerName: "Password",
      flex: 1.25,
      sortable: false,
      renderCell: (props: GridRenderCellParams) => (
        <MaskValue unmaskValue={async () => props.row.unmaskValue("password")}>
          {renderCellRedacted(props)}
        </MaskValue>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 0.5,
      sortable: false,
      renderCell: ({ value }: GridRenderCellParams) =>
        value ? (
          <div>
            <IconButton
              sx={{ color: "#475467", padding: "5px" }}
              onClick={() => {
                if (value) {
                  window.navigator.clipboard.writeText(value);
                }
              }}
            >
              <CopyAllOutlinedIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
            <Tooltip
              title={<div style={{ whiteSpace: "pre-line" }}>{value}</div>}
              TransitionComponent={Zoom}
              placement="top"
              arrow
            >
              <IconButton
                aria-label="tooltip notes"
                size="small"
                sx={{ color: "#475467", padding: "5px" }}
              >
                <HelpOutlineIcon sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          "-"
        ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: Object.keys(CredentialStatus),
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams) => {
        if (value === CredentialStatus.INACTIVE) {
          return <NotWorkingChip />;
        }
        if (value === CredentialStatus.INVALID) {
          return <InvalidChip />;
        }
        return <WorkingChip />;
      },
    },
    {
      field: "settings",
      type: "actions",
      width: 75,
      getActions: ({ row }) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => {
            openEdit(row);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => {
            openDelete(row);
          }}
          showInMenu
        />,
      ],
      flex: 0,
      filterable: false,
      sortable: false,
    },
  ];
}
