import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { type InputProps } from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React from "react";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { ErrorHelperText } from "../../ui/ErrorHelper";
import { formatSSN, removeNonDigits } from "../../utils";
import { FormFields } from "./PatientDetailsForm.zod";

interface PatientDetailsFormFieldsProps {
  readOnly?: boolean;
  control: Control<FormFields>;
  practices: PracticeMessage[];
}

const readOnlyInputStyles = {
  "& input:disabled": {
    color: "initial",
    TextFillColor: "initial",
    WebkitTextFillColor: "initial",
  },
};

const readOnlyInputProps: InputProps = {
  disableUnderline: true,
};

export function PatientDetailsFormFields({
  readOnly,
  control,
  practices,
}: PatientDetailsFormFieldsProps) {
  const theme = useTheme();
  const variant = readOnly ? "standard" : "outlined";
  const placeholder = readOnly ? "-" : undefined;
  const sx = readOnly ? readOnlyInputStyles : {};
  const hasNoEditablePractices = practices?.length === 0 && !readOnly;

  // disableUnderline is supported only for 'standard' and 'filled' variants.
  // See: https://github.com/mui/material-ui/issues/15502
  const setDisableUnderline =
    readOnly && variant === "standard" ? readOnlyInputProps : {};

  return (
    <Box
      data-testid="patientDetailsForm"
      sx={{ margin: `${theme.spacing(3)} 0` }}
    >
      <Grid container spacing={2}>
        <Controller
          name="name"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 4}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                value={value}
                type="string"
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                label="Full Name"
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="ssn"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 4}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                value={formatSSN(value)}
                label="Social Security Number"
                InputLabelProps={{ shrink: true }}
                error={Boolean(error) && value !== ""}
                onChange={(event) => {
                  const ssnDigits = removeNonDigits(event.target.value);
                  return ssnDigits ? onChange(ssnDigits) : onChange(null);
                }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="dob"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 4}>
              <TextField
                required
                fullWidth
                type="date"
                label="DoB"
                name={name}
                placeholder={placeholder}
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                label="Phone Number"
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="email"
                label="Email"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="address"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 12}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                label="Address"
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                label="City"
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 4}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                label="State"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="zipCode"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 4}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                label="Zip Code"
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="practiceId"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => {
            const userPractice = practices.find(
              ({ wieldyId }) => wieldyId === value,
            );
            return (
              <Grid item xs={12} sm={readOnly ? 3 : 6}>
                {readOnly ? (
                  <TextField
                    fullWidth
                    name={name}
                    placeholder={placeholder}
                    type="string"
                    value={userPractice?.displayName}
                    label="Practice"
                    disabled
                    variant={variant}
                    InputLabelProps={{ shrink: true }}
                    sx={sx}
                    InputProps={setDisableUnderline}
                  />
                ) : (
                  <>
                    <FormControl fullWidth>
                      <InputLabel id="select-practice">Practice</InputLabel>
                      <Select
                        labelId="select-practice"
                        value={value}
                        label="Practice"
                        disabled={hasNoEditablePractices}
                        sx={sx}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                      >
                        {practices.map((practice) => {
                          const { wieldyId, displayName: practiceName } =
                            practice;
                          return (
                            <MenuItem key={wieldyId} value={wieldyId}>
                              {practiceName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <ErrorHelperText message={error?.message} />
                  </>
                )}
              </Grid>
            );
          }}
        />
      </Grid>
    </Box>
  );
}

PatientDetailsFormFields.defaultProps = {
  readOnly: false,
};
