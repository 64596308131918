import { z } from "zod";

import { CREDENTIAL_TYPE } from "../common";

/// Coerces the specified array to a gauranteed non-empty type, or throws if the array
/// is in fact empty.
function expect_not_empty<T>(items: T[]): [T, ...T[]] {
  if (items.length === 0) {
    throw new Error("Expected non-empty array");
  }
  return items as [T, ...T[]];
}

const payerCredentialsFormSchemaBase = z.object({
  type: z.enum(expect_not_empty(Object.values(CREDENTIAL_TYPE))),
  username: z.string().min(1, "A username is required"),
  notes: z.string().min(1, "If provided, notes must not be empty").optional(),
  website: z
    .string()
    .min(1, "If provided, website must not be empty")
    .optional(),
  password: z.string().min(1, "A password is required"),
  payer: z.string().min(1, "A payer must be selected").optional(),
  unsupportedPayer: z.string().min(1, "A payer must be entered").optional(),
  pms: z.string().min(1, "A pms must be selected").optional(),
  practiceId: z
    .string({
      required_error: "A practice must be selected",
    })
    .min(1, "A practice must be selected"),
});

export const updatePayerCredentialsFormSchemaBase =
  payerCredentialsFormSchemaBase
    .pick({
      username: true,
      password: true,
      website: true,
      notes: true,
    })
    .partial();

export const addPayerCredentialsFormSchemaBase =
  payerCredentialsFormSchemaBase.refine(
    (data) => {
      if (data.type === CREDENTIAL_TYPE.PAYER && data.payer) return true;
      if (data.type === CREDENTIAL_TYPE.PMS && data.pms) return true;
      return false;
    },
    {
      message: "Either payer or pms",
      path: ["payer", "pms"],
    },
  );

export type AddPayerCredentialsFormFields = z.infer<
  typeof payerCredentialsFormSchemaBase
>;

export type UpdatePayerCredentialsFormFields = z.infer<
  typeof updatePayerCredentialsFormSchemaBase
>;
