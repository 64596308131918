import Typography from "@mui/material/Typography";
import React, { type ReactNode } from "react";

export function InputLabelText({ children }: { children: ReactNode }) {
  return (
    <Typography
      paragraph
      sx={{
        fontSize: "0.875rem",
        fontWeight: 600,
        color: "#303030",
        margin: 0,
        minWidth: "250px",
      }}
    >
      {children}
    </Typography>
  );
}
