import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useState } from "react";

import { InputLabelText } from "../CredentialsDashboard/ui/InputLabelText";
import { InputWrapper } from "../CredentialsDashboard/ui/InputWrapper";
import { LineBreak } from "../CredentialsDashboard/ui/LineBreak";
import UploadFile from "../UploadFile";

const EMPTY_PRACTICE = "";
const ALLOWED_FILE_TYPES = ["text/csv"];

interface NewReconciliationProps {
  open: boolean;
  onClose: () => void;
  practices: PracticeMessage[];
  uploadBankTransactions: (practiceId: string, file: File) => Promise<void>;
}

export default function NewReconciliation({
  open,
  onClose,
  practices,
  uploadBankTransactions,
}: NewReconciliationProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [practiceId, setPracticeId] = useState<string>(EMPTY_PRACTICE);
  const handleSelectChange = (event: SelectChangeEvent) => {
    setPracticeId(event.target.value);
  };

  const closeDialog = () => {
    onClose();
    setPracticeId(EMPTY_PRACTICE);
    setFile(null);
    setFileError(null);
  };
  const canSubmit = practiceId !== EMPTY_PRACTICE && file && !fileError;
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      setIsSubmitting(true);
      uploadBankTransactions(practiceId, file);
      closeDialog();
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "735px",
          width: "100%",
          padding: "24px 36px",
          margin: "auto",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0, margin: 0 }}>
        <Box
          component="form"
          onSubmit={submitHandler}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            padding: "1rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              <UploadFileIcon />
              <IconButton
                aria-label="close"
                onClick={closeDialog}
                sx={{
                  color: "#475467",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              paragraph
              sx={{
                fontSize: "1.125rem",
                fontWeight: 600,
                color: "#303030",
                marginBottom: 0,
              }}
            >
              Upload Bank Activity
            </Typography>
            <Typography
              paragraph
              sx={{
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "#475467",
                marginBottom: 0,
              }}
            >
              Please select a practice and upload a bank activity file to
              reconcile
            </Typography>
          </Box>
          <LineBreak />

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <FormControl
              sx={{
                width: "100%",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <FormLabel>
                <InputLabelText>Practice</InputLabelText>
              </FormLabel>
              <InputWrapper>
                <Select
                  name="practiceId"
                  value={practiceId}
                  label="Choose Practice"
                  onChange={handleSelectChange}
                  displayEmpty
                  IconComponent={KeyboardArrowDownOutlinedIcon}
                  inputProps={{
                    "data-testid": "practiceInput",
                  }}
                  MenuProps={{
                    sx: {
                      marginTop: "1rem",
                    },
                  }}
                  sx={{
                    backgroundColor: "#F9FAFB",
                    width: "100%",
                    borderRadius: "8px",
                    fontSize: "0.875rem",

                    ".MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input":
                      {
                        padding: "12px 14px",
                      },
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "solid 1px rgba(208, 213, 221, 0.5)",
                    },
                    fieldset: {
                      legend: {
                        width: 0,
                      },
                    },
                  }}
                  renderValue={(currentValue) => {
                    const isEmpty = !currentValue;
                    if (isEmpty) {
                      return "Choose A Practice";
                    }
                    const practiceDisplayName = practices.find(
                      (practice) => practice.wieldyId === currentValue,
                    );
                    return practiceDisplayName?.displayName;
                  }}
                >
                  {practices.map((practice) => {
                    const { wieldyId, name: practiceDisplayName } = practice;
                    return (
                      <MenuItem
                        key={wieldyId}
                        value={wieldyId}
                        data-testid={`practiceSelectItem-${wieldyId}`}
                      >
                        {practiceDisplayName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </InputWrapper>
            </FormControl>
          </Box>

          {practiceId !== EMPTY_PRACTICE && (
            <FormControl error={!!fileError}>
              <UploadFile
                file={file}
                setFile={setFile}
                onError={setFileError}
                allowedFileTypes={ALLOWED_FILE_TYPES}
              />
              <FormHelperText>{fileError}</FormHelperText>
            </FormControl>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "auto",
              gap: "12px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#EAECF0",
                color: "#475467",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#e6e7ea",
                },
              }}
              onClick={closeDialog}
              type="button"
              variant="contained"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              sx={{ borderRadius: "8px" }}
              type="submit"
              color="primary"
              variant="contained"
              disabled={!canSubmit}
              data-testid="submitCredentials"
            >
              {isSubmitting ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <CircularProgress color="inherit" size={20} />
                  Submitting...
                </Box>
              ) : (
                "Match"
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
