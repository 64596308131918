import "../LeftNav.css";

import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { Chip, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React from "react";

const selectSXOverride = {
  borderRadius: "8px",
  border: "2px solid #E8FCFD0D",
};

interface GlobalFilterProps {
  selectValues: PracticeMessage[];
  values: PracticeMessage[];
  label: string;
  onChange: (event: SelectChangeEvent<string[]>) => void;
}

export function GlobalFilter({
  selectValues,
  values,
  label,
  onChange,
}: GlobalFilterProps) {
  const iconPath = `/static/images/${label.toLowerCase()}-icon.svg`;

  const selectValuesIds = selectValues.map(
    (selectValue) => selectValue.wieldyId,
  );

  return (
    <div className="globalFilter">
      <div className="globalFilterLabel">
        <img src={iconPath} alt={`${label} Icon`} width="24" height="24" />
        <h3>{label}</h3>
      </div>
      <Select
        className="globalFilterSelect"
        IconComponent={KeyboardArrowDownOutlinedIcon}
        sx={{
          ".MuiSvgIcon-root": {
            fill: "#E9D7D9",
          },
          ".MuiOutlinedInput-notchedOutline": selectSXOverride,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": selectSXOverride,
          "&:hover .MuiOutlinedInput-notchedOutline": selectSXOverride,
          ".MuiSelect-icon": {
            left: 7,
          },
          ".globalFilterSelect": {
            borderRadius: "8px",
          },
          ".MuiSelect-select.MuiInputBase-input[role=combobox]": {
            paddingRight: "8px",
            paddingLeft: "32px",
            display: "flex",
            alignItems: "center",
          },
          ".MuiBox-root": {
            overflowX: "scroll",
            overflowY: "hidden",
            flexWrap: "nowrap",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          },
          ".MuiBox-root::-webkit-scrollbar": {
            width: 0,
            height: 0,
            background: "transparent",
          },
        }}
        labelId="multiple-practice-select"
        id="multiple-practice-select"
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: "#F9FAFB",
              },
            },
          },
        }}
        multiple
        value={selectValuesIds}
        onChange={onChange}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4px",
            }}
          >
            {selected.map((value) => {
              const match = values.find(({ wieldyId }) => wieldyId === value);
              const selectLabel = match?.displayName;

              return (
                <Chip
                  sx={{
                    "&": {
                      height: "28px",
                      borderRadius: "4px",
                      color: "#E9D7D9",
                      backgroundColor: "#101828",
                      border: "solid 1px #25233F",
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "#E9D7D9",
                    },
                    "& .MuiChip-label": {
                      fontSize: "0.875rem",
                    },
                  }}
                  size="small"
                  key={value}
                  label={selectLabel}
                  onDelete={onChange}
                  deleteIcon={<DisabledByDefaultRoundedIcon />}
                />
              );
            })}
          </Box>
        )}
      >
        {values.map((value) => {
          const isChecked = !!selectValues.find(
            (selectedValue) => selectedValue.wieldyId === value.wieldyId,
          );
          return (
            <MenuItem key={value.wieldyId} value={value.wieldyId}>
              <Checkbox checked={isChecked} />
              <ListItemText primary={value.displayName} />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
