import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useState } from "react";
import type { NavigateFunction } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

import { isDevMode } from "../../../../utils/utils";
import ClaimsTable from "../../../ClaimsTable";
import Loading from "../../../Loading";
import PaymentLedger from "../../../PaymentLedger";
import PaymentPlanTab from "../../../PaymentPlans/PaymentPlan";
import { PaymentPlanStore } from "../../../PaymentPlans/store";
import { PatientInfoTab } from "./PatientInfo";
import PatientProfileHeader from "./PatientProfileHeader";

enum PatientProfileTabs {
  PatientInfo,
  Documents,
  InsurancePayments,
  PatientPayments,
  PaymentPlan,
}

function renderHeader({
  isLoading,
  theme,
  patient,
  navigate,
  openPatientEditModal,
  activePaymentPlans,
}: {
  isLoading: boolean;
  theme: Theme;
  patient: PatientMessage | undefined;
  navigate: NavigateFunction;
  openPatientEditModal: () => void;
  activePaymentPlans?: PaymentPlanMessage[];
}) {
  if (isLoading) {
    return <Box sx={{ height: "5.5625rem" }} />;
  }

  return (
    <Box sx={{ marginBottom: theme.spacing(2) }}>
      <PatientProfileHeader
        onClickBackButton={() => navigate(-1)}
        openPatientEditModal={openPatientEditModal}
        activePaymentPlans={activePaymentPlans}
        patient={patient}
      />
    </Box>
  );
}

function renderPatientInfo({
  isLoading,
  patient,
  editPatient,
  updateSnackBar,
  closePatientEditModal,
  triggerPatientEditModal,
  practices,
}: {
  isLoading: boolean;
  patient: PatientMessage | undefined;
  editPatient: (patient: PatientMessage) => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  closePatientEditModal: () => void;
  triggerPatientEditModal: boolean;
  practices: PracticeMessage[];
}) {
  if (isLoading) {
    return (
      <Box sx={{ height: "12rem" }}>
        <Loading />
      </Box>
    );
  }

  return (
    <PatientInfoTab
      patient={patient}
      onSave={editPatient}
      updateSnackBar={updateSnackBar}
      triggerPatientEditModal={triggerPatientEditModal}
      closePatientEditModal={closePatientEditModal}
      practices={practices}
    />
  );
}

function renderPayments({
  isLoading,
  updateSnackBar,
  patient,
  paymentPlanStore,
}: {
  isLoading: boolean;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  patient?: PatientMessage;
  paymentPlanStore: PaymentPlanStore;
}) {
  if (isLoading || patient === undefined) {
    return (
      <Box sx={{ height: "12rem" }}>
        <Loading />
      </Box>
    );
  }
  return (
    <PaymentLedger
      patient={patient}
      updateSnackBar={updateSnackBar}
      paymentPlanStore={paymentPlanStore}
    />
  );
}

function renderPaymentPlan({
  isLoading,
  activePaymentPlans,
  clearPatientPaymentPlan,
}: {
  isLoading: boolean;
  activePaymentPlans?: PaymentPlanMessage[];
  clearPatientPaymentPlan: (paymentPlanId: PaymentPlanMessage["id"]) => void;
}) {
  if (isLoading) {
    return (
      <Box sx={{ height: "12rem" }}>
        <Loading />
      </Box>
    );
  }

  return (
    <PaymentPlanTab
      activePaymentPlans={activePaymentPlans}
      clearPatientPaymentPlan={clearPatientPaymentPlan}
    />
  );
}

function renderInsurancePayments(
  isLoading: boolean,
  claims: ClaimWithProcedureAndPatientMessage[],
  update: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void,
  toPMS: (claim: ClaimWithProcedureAndPatientMessage) => void,
  practices: PracticeMessage[],
) {
  return (
    <ClaimsTable
      practices={practices}
      claims={claims}
      isLoading={isLoading}
      onClaimUpdate={update}
      enableClaimDetails
      onPostToPMS={toPMS}
      excludeByFieldNames={[
        "urgency",
        "wieldyPatientName",
        "practiceDisplayName",
        "paymentId",
        "notes",
      ]}
      sortColumnOrder={[
        "claimStatus",
        "availableSince",
        "payer",
        "claimId",
        "wieldyClaimDate",
        "claimPayerPays",
        "sources",
        "action",
        "postedState",
        "postedDateTime",
      ]}
    />
  );
}

interface PatientProfileViewProps {
  patient?: PatientMessage;
  patientLoading: boolean;
  claims: ClaimWithProcedureAndPatientMessage[];
  claimsLoading: boolean;
  load: (patientId: string) => void;
  update: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  editPatient: (patient: PatientMessage) => void;
  toPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  reset: () => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  practices: PracticeMessage[];
  loadPatientPaymentPlans: (patientId: PatientMessage["wieldyId"]) => void;
  paymentPlans?: PaymentPlanMessage[];
  clearPatientPaymentPlan: (paymentPlanId: PaymentPlanMessage["id"]) => void;
  paymentPlanStore: PaymentPlanStore;
  loadPatientPayments: (patientId: PatientMessage["wieldyId"]) => void;
}
export default function PatientProfileView({
  patient,
  patientLoading,
  claims,
  claimsLoading,
  load,
  update,
  editPatient,
  toPMS,
  reset,
  updateSnackBar,
  practices,
  loadPatientPaymentPlans,
  paymentPlans,
  clearPatientPaymentPlan,
  paymentPlanStore,
  loadPatientPayments,
}: PatientProfileViewProps) {
  const { patientId } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const devMode = isDevMode();

  const activePaymentPlans = paymentPlans?.filter((plan) => !plan.canceledAt);

  const [selectedTab, setSelectedTab] = useState(
    PatientProfileTabs.PatientInfo,
  );

  const [triggerPatientEditModal, setTriggerPatientEditModal] = useState(false);

  const openPatientEditModal = () => {
    setTriggerPatientEditModal(true);
  };

  const closePatientEditModal = () => {
    setTriggerPatientEditModal(false);
  };

  // TODO: could this be moved to the dashboard
  useEffect(() => {
    if (patientId) {
      load(patientId);
    }
    return reset;
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      loadPatientPaymentPlans(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (patientId) {
      loadPatientPayments(patientId);
    }
  }, [patientId]);

  return (
    <Box sx={{ paddingTop: theme.spacing(4) }}>
      {renderHeader({
        isLoading: patientLoading,
        theme,
        patient,
        navigate,
        openPatientEditModal,
        activePaymentPlans,
      })}
      <Tabs
        value={selectedTab}
        onChange={(_event, value) => setSelectedTab(value)}
        sx={{
          marginBottom: theme.spacing(2),
          minHeight: "42px",
          height: "auto",
        }}
        variant="fullWidth"
      >
        <Tab
          data-testid="patientInfo"
          label="Patient Info"
          value={PatientProfileTabs.PatientInfo}
          sx={{ minHeight: "auto" }}
        />
        <Tab
          data-testid="documents"
          label="Documents"
          value={PatientProfileTabs.Documents}
          sx={{ minHeight: "auto" }}
          disabled
        />
        <Tab
          data-testid="insurancePayments"
          label="Insurance Payments"
          value={PatientProfileTabs.InsurancePayments}
          sx={{ minHeight: "auto" }}
        />
        <Tab
          data-testid="patientPayments"
          label="Patient Payments"
          value={PatientProfileTabs.PatientPayments}
          sx={{ minHeight: "auto" }}
          disabled={!devMode}
        />
        <Tab
          data-testid="paymentPlan"
          label="Payment Plan"
          value={PatientProfileTabs.PaymentPlan}
          sx={{ minHeight: "auto" }}
          disabled={!devMode}
        />
      </Tabs>
      {selectedTab === PatientProfileTabs.InsurancePayments &&
        renderInsurancePayments(
          claimsLoading,
          claims,
          update,
          toPMS,
          practices,
        )}
      {selectedTab === PatientProfileTabs.PatientInfo &&
        renderPatientInfo({
          isLoading: patientLoading,
          patient,
          editPatient,
          updateSnackBar,
          closePatientEditModal,
          triggerPatientEditModal,
          practices,
        })}
      {selectedTab === PatientProfileTabs.PatientPayments &&
        renderPayments({
          isLoading: patientLoading,
          updateSnackBar,
          patient,
          paymentPlanStore,
        })}
      {selectedTab === PatientProfileTabs.PaymentPlan &&
        renderPaymentPlan({
          isLoading: patientLoading,
          activePaymentPlans,
          clearPatientPaymentPlan,
        })}
    </Box>
  );
}

PatientProfileView.defaultProps = {
  patient: undefined,
  paymentPlans: undefined,
};
