import { numberToMoneyMessage } from "../../../../../utils/utils";
import { PaymentTerms } from "../../../store";

function buildInstallments(terms: PaymentTerms): InstallmentMessage[] {
  // Check sanity.  We've assumed an awful lot of stuff went right in calling
  // code, and that people foresaw and handled all the edge cases.
  const regularInstallmentCount = terms.contractPeriod - 1;
  const regularInstallmentTotal =
    terms.installmentAmount * regularInstallmentCount;
  if (regularInstallmentTotal >= terms.contractValue) {
    throw new Error("Payment terms are inconsistent");
  }
  const finalInstallmentAmount = terms.contractValue - regularInstallmentTotal;
  const finalInstallmentAmountMessage = numberToMoneyMessage(
    finalInstallmentAmount,
  );
  const lastPaymentAmountMessage = numberToMoneyMessage(terms.lastPayment);
  if (
    lastPaymentAmountMessage.major !== finalInstallmentAmountMessage.major ||
    lastPaymentAmountMessage.minor !== finalInstallmentAmountMessage.minor ||
    lastPaymentAmountMessage.code !== finalInstallmentAmountMessage.code
  ) {
    throw new Error(
      `lastPayment ${terms.lastPayment} does not match ` +
        `remaining contract value ${finalInstallmentAmount}`,
    );
  }

  // Define a closure to format and append each installment.
  const installments: InstallmentMessage[] = [];
  let currentInstallment = terms.withdrawalBeginDate;
  const addInstallment = (amount: number) => {
    installments.push({
      // TODO: Why are we including time of day in the date?
      date: currentInstallment.toISOString(),
      amount: numberToMoneyMessage(amount),
    });
    currentInstallment = currentInstallment.add(1, "month");
  };

  // Loop through the regular installments, then add the final installment
  // (which may have a different amount).
  for (let i = 0; i < regularInstallmentCount; i += 1) {
    addInstallment(terms.installmentAmount);
  }
  addInstallment(terms.lastPayment);
  return installments;
}

export { buildInstallments };
