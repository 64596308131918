/* eslint-disable react/no-array-index-key */
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@mui/material/styles";
import React, { useCallback, useState } from "react";

import Loading from "../Loading";
import DetailsCard from "../PatientsDashboard/ui/DetailsCard";
import PaymentPlanDetailsForm from "./PaymentPlanDetailForm";

interface PaymentPlanProps {
  activePaymentPlans?: PaymentPlanMessage[];
  clearPatientPaymentPlan: (paymentPlanId: PaymentPlanMessage["id"]) => void;
}

export default function PaymentPlan({
  activePaymentPlans,
  clearPatientPaymentPlan,
}: PaymentPlanProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [planToCancel, setPlanToCancel] = useState<string | undefined>();

  const cancelPaymentPlan = useCallback(async () => {
    setIsLoading(true);
    if (planToCancel) {
      await clearPatientPaymentPlan(planToCancel);
      setPlanToCancel(undefined);
    }
    setIsLoading(false);
  }, [planToCancel]);

  if (isLoading || activePaymentPlans === undefined) {
    return <Loading />;
  }

  return (
    <>
      {activePaymentPlans.map((paymentPlan, index) => (
        <Box key={index} sx={{ marginBottom: theme.spacing(2) }}>
          <DetailsCard
            title="Patient Payment Plan"
            headerAdornment={
              <Button
                data-testid="cancel"
                startIcon={<DeleteOutlined />}
                color="error"
                onClick={() => {
                  setPlanToCancel(paymentPlan.id);
                }}
              >
                Cancel
              </Button>
            }
          >
            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <PaymentPlanDetailsForm plan={paymentPlan} />
            </Box>
          </DetailsCard>
        </Box>
      ))}
      <Dialog
        open={!!planToCancel}
        onClose={() => setPlanToCancel(undefined)}
        maxWidth="xs"
      >
        <DialogContent sx={{ padding: 2, paddingTop: 2.5, paddingBottom: 0 }}>
          <DialogContentText color="black">
            Are you sure you want to cancel your Payment Plan?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button data-testid="no" onClick={() => setPlanToCancel(undefined)}>
            No
          </Button>
          <Button
            data-testid="yes"
            variant="contained"
            color="error"
            onClick={cancelPaymentPlan}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

PaymentPlan.defaultProps = {
  activePaymentPlans: undefined,
};
