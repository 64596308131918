import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import CreditCardOutlined from "@mui/icons-material/CreditCardOutlined";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { Dispatch, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  FINIX_APPLICATION_ID,
  FINIX_ENVIRONMENT,
} from "../../../../../api/apiConfig";
import type QueryClient from "../../../../../api/query/queryClient";
import Loading from "../../../../Loading";
import { PaymentPlanStore, PaymentPlanStoreAction } from "../../../store";
import {
  PaymentMethod,
  PaymentMethodBody,
  PaymentMethodHeader,
} from "./PaymentMethod";
import { SavedPaymentMethod } from "./SavedPaymentMethod";
import { useFinix } from "./useFinix";

interface PaymentProps {
  patientId: string;
  store: PaymentPlanStore;
  updateStore: Dispatch<PaymentPlanStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function Payment({
  patientId,
  store,
  updateStore,
  queryClient,
  updateSnackBar,
}: PaymentProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedMethod, setSelectedMethod] = React.useState<string>("");
  const [submitting, setSubmitting] = React.useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const loadPatientPayments = async () => {
    if (patientId) {
      const payments = await queryClient.getPatientPayments(patientId);
      updateStore({
        type: "SET_CURRENT_PAYMENTS_OPTIONS",
        payload: payments ?? [],
      });
      if (payments && payments.length) {
        const primaryPaymentMethod = payments.find(
          (payment) => payment.primary,
        );
        if (primaryPaymentMethod) {
          updateStore({
            type: "SET_PAYMENT_METHOD",
            payload: primaryPaymentMethod,
          });
          /**
           * The two navigates is a hack so the user navigates from the terms page
           * back to the patient details page when a patient
           * already has a payment method. Without this hack, the user would go back
           * to the payment method page which we don't want them there.
           */
          navigate(`/patients/${patientId}?${searchParams}`);
          navigate(
            `/patients/${patientId}/payment-plans/new/terms?${searchParams}`,
          );
        }
      }
    }
  };

  useEffect(() => {
    loadPatientPayments();
  }, [store.paymentMethodOptions]);

  const {
    loading,
    form,
    loadBankPaymentMethod,
    loadCardPaymentMethod,
    canSubmit,
  } = useFinix();

  const handlePaymentMethodChange = useCallback(
    (_: React.ChangeEvent, value: string) => {
      if (value === "__bank__") {
        loadBankPaymentMethod("method-bank");
        setSelectedMethod("__bank__");
      } else if (value === "__credit__") {
        loadCardPaymentMethod("method-card");
        setSelectedMethod("__credit__");
      } else {
        const paymentMethod = store.paymentMethodOptions?.find(
          (pm) => pm.id === value,
        );
        if (paymentMethod) {
          setSelectedMethod(paymentMethod.id);
          updateStore({
            type: "SET_PAYMENT_METHOD",
            payload: paymentMethod,
          });
        }
      }
    },
    [store.paymentMethodOptions],
  );

  const handleSubmit = () => {
    if (selectedMethod === "__bank__" || selectedMethod === "__credit__") {
      if (!form) {
        updateSnackBar({
          severity: "error",
          message:
            "Failed to create payment method, please reload the page and retry",
        });
        return;
      }
      setSubmitting(true);
      form.submit(FINIX_ENVIRONMENT, FINIX_APPLICATION_ID, async (err, res) => {
        if (err) {
          let message = "Failed to create payment method";
          if (err?.status === 400) {
            message = "Invalid payment information";
          }
          updateSnackBar({
            severity: "error",
            message,
          });
        } else {
          // call the form submit function and supply the environment and application ID to submit under
          const tokenData = res.data || {};
          const paymentMethod = await queryClient.postPatientPayment(
            patientId,
            tokenData?.id,
          );
          updateStore({
            type: "SET_PAYMENT_METHOD",
            payload: paymentMethod,
          });
          navigate(
            `/patients/${patientId}/payment-plans/new/terms?${searchParams}`,
          );
        }
        setSubmitting(false);
      });
    } else {
      navigate(
        `/patients/${patientId}/payment-plans/new/terms?${searchParams}`,
      );
    }
  };

  if (loading || store.paymentMethodOptions === undefined) {
    return <Loading />;
  }

  const paymentFormComplete =
    selectedMethod === "" ||
    ((selectedMethod === "__bank__" || selectedMethod === "__credit__") &&
      !canSubmit);

  return (
    <Container
      sx={{ paddingY: theme.spacing(4), maxWidth: "39.5rem !important" }}
    >
      <RadioGroup
        name="payment-method"
        value={selectedMethod}
        onChange={handlePaymentMethodChange}
      >
        <Typography variant="h5" sx={{ marginBottom: "28px" }}>
          Select Payment Method
        </Typography>
        {store.paymentMethodOptions?.map((paymentMethod) => (
          <SavedPaymentMethod
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
          />
        ))}
        <Typography
          variant="h5"
          sx={{ marginBottom: "16px", marginTop: "20px" }}
        >
          Add Payment Method
        </Typography>

        <PaymentMethod value="__bank__">
          <PaymentMethodHeader>
            <Box display="flex" alignItems="start">
              <Box paddingTop="7px">
                <AccountBalanceOutlined />
              </Box>
              <Box marginLeft={2}>
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  lineHeight="160%"
                >
                  Savings or Checking Account
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  lineHeight="157%"
                  color={theme.palette.text.secondary}
                >
                  Payments associated to a bank account
                </Typography>
              </Box>
            </Box>
          </PaymentMethodHeader>
          <PaymentMethodBody>
            <Box id="method-bank" />
          </PaymentMethodBody>
        </PaymentMethod>

        <PaymentMethod value="__credit__">
          <PaymentMethodHeader>
            <Box display="flex" alignItems="start">
              <Box paddingTop="4px">
                <CreditCardOutlined />
              </Box>
              <Box marginLeft={2}>
                <Typography
                  variant="body1"
                  fontSize="18px"
                  fontWeight={600}
                  lineHeight="160%"
                >
                  Credit or Debit Card
                </Typography>
                <Typography
                  component="div"
                  variant="body2"
                  lineHeight="157%"
                  color={theme.palette.text.secondary}
                >
                  Payments through a credit or debit card
                </Typography>
              </Box>
            </Box>
          </PaymentMethodHeader>
          <PaymentMethodBody>
            <Box id="method-card" />
          </PaymentMethodBody>
        </PaymentMethod>

        <Button
          variant="contained"
          color="success"
          size="large"
          fullWidth
          sx={{ marginTop: "28px" }}
          disabled={submitting || paymentFormComplete}
          onClick={handleSubmit}
        >
          {submitting ? "Saving..." : "Continue to Payment Plan"}
        </Button>
      </RadioGroup>
    </Container>
  );
}
