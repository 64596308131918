import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";

/**
 * The ErrorHelperText component is utilized to manage and display error messages within forms.
 * It renders an error message if one is present. In the absence of an error message,
 * it renders a placeholder div with a fixed height to prevent Cumulative Layout Shift (CLS),
 * ensuring a stable and user-friendly layout.
 */
export function ErrorHelperText({ message }: { message?: string }) {
  const placeHolderHeight = "23px";
  if (message) {
    return (
      <FormHelperText sx={{ color: "error.main" }}>{message}</FormHelperText>
    );
  }

  return <Box sx={{ height: placeHolderHeight }} />;
}

ErrorHelperText.defaultProps = {
  message: undefined,
};
