import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { uniq } from "lodash";
import React, { useEffect } from "react";

import { expectEnumValue } from "../../utils/utils";
import DateRangePicker, { DateRange } from "../DateRangePicker";
import Loading from "../Loading";
import { DenialType, isClaimMessage } from "./charts/charts/helper";
import DenialInsights from "./DenialInsights";
import PaymentInsights from "./PaymentInsights";

const ALL = "All";

const EARLIEST_START_DATE = dayjs().subtract(1, "year");

enum VIEWS {
  OVERVIEW = "Overview",
  DENIAL_INSIGHTS = "Denial Insights",
}

// Reporting Dashboard Global Filters (Practice, Payer and Date Range)
function filter<T extends PaymentMessage | ClaimWithProcedureMessage>(
  items: T[],
  practice: string,
  payer: string,
  dateRange: DateRange,
): T[] {
  const byPractice = (item: T) =>
    practice === ALL || item.practice === practice;
  const byPayer = (item: T) => payer === ALL || item.payer === payer;
  const byDateRange = (item: T) => {
    const date = dayjs(
      isClaimMessage(item) ? item.wieldyClaimDate : item.paymentDate,
    );
    return (
      date.isAfter(dateRange.startDate) && date.isBefore(dateRange.endDate)
    );
  };
  return items.filter(byPractice).filter(byPayer).filter(byDateRange);
}

function uniquePracticesFrom(
  payments: PaymentMessage[],
  claims: ClaimWithProcedureMessage[],
): string[] {
  return uniq([
    ...payments.map((payment) => payment.practice),
    ...claims.map((claim) => claim.practice),
  ]);
}

function uniquePayersFrom(
  payments: PaymentMessage[],
  claims: ClaimWithProcedureMessage[],
): string[] {
  return uniq([
    ...(payments
      .filter((payment) => payment.payer)
      .map((payment) => payment.payer) as string[]),
    ...claims.map((claim) => claim.payer),
  ]);
}

function renderWithLoader(
  isLoading: boolean,
  content: JSX.Element,
): JSX.Element {
  if (isLoading) {
    return <Loading />;
  }
  return content;
}

interface ReportsViewProps {
  load: () => Promise<void>;
  reset: () => void;
  payments: PaymentMessage[];
  claims: ClaimWithProcedureMessage[];
}

export default function ReportsView({
  load,
  reset,
  payments,
  claims,
}: ReportsViewProps) {
  const [selectedView, setSelectedView] = React.useState<string>(
    VIEWS.OVERVIEW,
  );

  // Global Filters
  const [practice, setPractice] = React.useState<string>(ALL);
  const [payer, setPayer] = React.useState<string>(ALL);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: EARLIEST_START_DATE,
    endDate: dayjs(),
  });

  // Tab Filters
  const [denialType, setDenialType] = React.useState<DenialType>(
    DenialType.DenialFull,
  );

  useEffect(() => {
    load();
    return reset;
  }, []);

  const theme = useTheme();

  const handleChange = (_: React.MouseEvent<HTMLElement>, newView: VIEWS) => {
    setSelectedView(newView);
  };

  return (
    <>
      <h1>Reports</h1>
      {renderWithLoader(
        !payments.length && !claims.length,
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ToggleButtonGroup
              size="medium"
              color="primary"
              value={selectedView}
              exclusive
              onChange={handleChange}
              aria-label="Reports Views"
            >
              {Object.values(VIEWS).map((view) => (
                <ToggleButton
                  key={view}
                  value={view}
                  aria-label={`${view} view`}
                >
                  {view}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <div>
              <FormControl>
                <InputLabel>Practice</InputLabel>
                <Select
                  sx={{ minWidth: 120 }}
                  label="Practice"
                  value={practice}
                  onChange={(e) => setPractice(e.target.value)}
                >
                  <MenuItem key={ALL} value={ALL}>
                    All
                  </MenuItem>
                  {uniquePracticesFrom(payments, claims).map(
                    (practiceDisplayName) => (
                      <MenuItem
                        key={practiceDisplayName}
                        value={practiceDisplayName}
                      >
                        {practiceDisplayName}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ marginLeft: theme.spacing(2) }}>
                <InputLabel>Payer</InputLabel>
                <Select
                  sx={{ minWidth: 120 }}
                  label="Payer"
                  value={payer}
                  onChange={(e) => setPayer(e.target.value)}
                >
                  <MenuItem key={ALL} value={ALL}>
                    All
                  </MenuItem>
                  {uniquePayersFrom(payments, claims).map((payerName) => (
                    <MenuItem key={payerName} value={payerName}>
                      {payerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedView === VIEWS.DENIAL_INSIGHTS && (
                <FormControl sx={{ marginLeft: theme.spacing(2) }}>
                  <InputLabel>Denial Type</InputLabel>
                  <Select
                    sx={{ minWidth: 120 }}
                    label="Denial Type"
                    value={denialType}
                    onChange={(e) =>
                      setDenialType(
                        expectEnumValue(
                          DenialType,
                          e.target.value as DenialType,
                        ),
                      )
                    }
                  >
                    <MenuItem value={DenialType.DenialFull}>Full</MenuItem>
                    <MenuItem value={DenialType.DenialPartial}>
                      Partial
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              <Box sx={{ display: "inline", marginLeft: theme.spacing(2) }}>
                <DateRangePicker
                  selectedDateRange={dateRange}
                  setDateRange={setDateRange}
                  earliestStartDate={EARLIEST_START_DATE.toString()}
                />
              </Box>
            </div>
          </div>
          {selectedView === VIEWS.OVERVIEW && (
            <PaymentInsights
              payments={filter(payments, practice, payer, dateRange)}
              claims={filter(claims, practice, payer, dateRange)}
            />
          )}

          {selectedView === VIEWS.DENIAL_INSIGHTS && (
            <DenialInsights
              claims={filter(claims, practice, payer, dateRange)}
              denialType={denialType}
            />
          )}
        </>,
      )}
    </>
  );
}
