import { zodResolver } from "@hookform/resolvers/zod";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip, Zoom } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";
import { useForm } from "react-hook-form";

import { formatMoney } from "../../formatters";
import { isDevMode, isoToDate } from "../../utils/utils";
import Loading from "../Loading";
import { renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import AddToolbar from "./AddToolbar";
import {
  AddClaimFormFields,
  defaultClaimFields,
  defaultProcedureFields,
  editableProcedureFields,
  ProcedureFormFields,
  procedureFormSchemaBase,
} from "./CrudForms.zod";
import ProcedureForm from "./ProcedureForm";

const columns: GridColDef[] = [
  {
    flex: 0.7,
    field: "procedureCode",
    headerName: "Code",
  },
  {
    flex: 0.7,
    field: "procedurePayerSubstitutedCode",
    headerName: "Substituted Code",
  },
  {
    flex: 0.7,
    field: "procedureToothNumber",
    headerName: "Tooth Number",
  },
  {
    flex: 1,
    type: "date",
    field: "procedureDate",
    headerName: "Date of Service",
    valueGetter: (params: GridRenderCellParams) =>
      params.row.procedureDate ? isoToDate(params.row.procedureDate) : "",
    renderCell: renderCellRedacted,
  },
  {
    flex: 0.7,
    type: "number",
    field: "procedureSubmittedAmount",
    headerName: "Submitted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedureContractedAmount",
    headerName: "Contracted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    field: "procedureDenialCode",
    headerName: "Denial Code",
    renderCell: (params: GridRenderCellParams) =>
      params.row.procedureDenialDescription ? (
        // This a temporary fix till we have denial code description
        // return an object. The backend delimits each code description with a pipe
        // and we replace that pipe with a new line (\n\n) here for the tooltip.
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {params.row.procedureDenialDescription.replaceAll(" | ", "\n\n")}
            </div>
          }
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        params.value
      ),
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePatientResponsibility",
    headerName: "Patient Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePayerPays",
    headerName: "Payer Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
];

const getColumns = (editProcedure: (procedure: ProcedureMessage) => void) => {
  const allColumns = [...columns];
  if (isDevMode()) {
    allColumns.push({
      flex: 1,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={() => {
            editProcedure(params.row);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    });
  }
  return allColumns;
};

interface ProceduresTableProps {
  procedures: ProcedureMessage[];
  isLoading: boolean;
  onSaveProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  practices: PracticeMessage[];
}

export default function ProceduresTable({
  procedures,
  isLoading,
  onSaveProcedure,
  practices,
}: ProceduresTableProps) {
  const [openProcedureModal, setOpenProcedureModal] = React.useState(false);
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
  } = useForm<ProcedureFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1000,
    resolver: zodResolver(procedureFormSchemaBase),
    defaultValues: defaultProcedureFields,
  });

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  const onProcedureModal = (open: boolean) => {
    if (open) {
      reset(defaultProcedureFields);
    }
    setOpenProcedureModal(open);
  };

  const onEditProcedure = (procedure: ProcedureMessage) => {
    const formFields = editableProcedureFields.reduce<AddClaimFormFields>(
      (acc, field) => ({
        ...acc,
        [field.name]:
          procedure[field.name as keyof typeof procedure] ??
          defaultClaimFields[field.name as keyof typeof defaultClaimFields],
      }),
      {} as AddClaimFormFields,
    );
    reset(formFields);
    setOpenProcedureModal(true);
  };

  const onProcedureFormSubmission = handleSubmit(async (data) => {
    if (onSaveProcedure) {
      await onSaveProcedure(data as ProcedureMessage);
    }
  });

  if (isLoading) {
    return <Loading />;
  }
  const hasErrors = Object.keys(errors).length > 0;
  const procedureIsDisabled = isSubmitting || hasErrors || !isValid;

  return (
    <DataGridPro
      rowHeight={33}
      getRowSpacing={getRowSpacing}
      sx={{
        border: 0,
        fontSize: "0.8rem",
        weight: "500",
        lineHeight: "20px",
        color: "#475467",
        [`&>.${gridClasses.main}`]: {
          [`&>.${gridClasses.columnHeaders}`]: {
            borderBottom: "none",
            backgroundColor: "#F9FAFB",
            fontSize: "0.9rem",
            color: "#303030",
          },
        },
      }}
      rows={procedures.map((procedure, key) => ({ ...procedure, key }))}
      columns={getColumns(onEditProcedure)}
      getRowId={(row) => JSON.stringify(row)}
      disableRowSelectionOnClick
      disableVirtualization
      initialState={{
        sorting: {
          sortModel: [{ field: "procedureDate", sort: "desc" }],
        },
      }}
      slots={{
        toolbar: AddToolbar,
        noRowsOverlay: CustomNoRowsOverlay,
      }}
      slotProps={{
        toolbar: {
          addEntityForm: (
            <ProcedureForm control={control} practices={practices} />
          ),
          handleSubmit: onProcedureFormSubmission,
          isDisabled: procedureIsDisabled,
          entityName: "Procedure",
          enableAddToolBar: true,
          setOpenModal: onProcedureModal,
          openModal: openProcedureModal,
          enableGridToolbar: false,
        },
      }}
    />
  );
}

ProceduresTable.defaultProps = {
  onSaveProcedure: undefined,
};
