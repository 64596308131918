import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import type { SxProps, Theme } from "@mui/material/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";

import { isDevMode } from "../../../utils/utils";

const DASHBOARD = {
  Payments: {
    display: "Insurance Payments",
    path: "Payments",
    beta: false,
  },
  Claims: {
    display: "Claims",
    path: "Claims",
    beta: false,
  },
  Patients: {
    display: "Patients",
    path: "Patients",
    beta: true,
  },
  Credentials: {
    display: "Credentials",
    path: "Credentials",
    beta: false,
  },
};

const DEV = {
  Reports: {
    display: "Reports",
    path: "Reports",
    beta: false,
  },
  Reconciliation: {
    display: "Reconciliation",
    path: "Reconciliation",
    beta: true,
  },
};

interface DashboardItemProps {
  formattedPath: string;
  toPath: string;
  display: string;
  isSelected: boolean;
  beta: boolean;
}

function DashboardItem({
  formattedPath,
  display,
  beta,
  toPath,
  isSelected,
}: DashboardItemProps) {
  const iconPath = `/static/images/${formattedPath}-icon.svg`;
  const buttonSXOverride: SxProps<Theme> = {
    borderRadius: "12px",
    justifyContent: "left",
    padding: "8px 12px",
    width: "100%",
    fontSize: "0.875rem",
    fontWeight: 500,
    color: isSelected ? "#E9D7D9" : "#F9FAFB",
    backgroundColor: isSelected ? "#1E1D31" : "#25233F",
    ":hover": { backgroundColor: "#1E1D31" },
  };

  return (
    <Box component="ul" sx={{ width: "100%", maxWidth: "230px" }}>
      <Link style={{ textDecoration: "none" }} to={toPath}>
        <IconButton aria-label={display} sx={buttonSXOverride}>
          <img
            style={{ marginRight: ".75rem" }}
            src={iconPath}
            alt={`${display} Icon`}
            width="24"
            height="24"
          />
          {display}
          {beta ? (
            <Chip
              label="Beta"
              size="small"
              sx={(theme) => ({
                border: `1px solid ${theme.palette.background.paper}`,
                color: theme.palette.background.paper,
                marginLeft: "auto",
              })}
            />
          ) : null}
        </IconButton>
      </Link>
    </Box>
  );
}

export function DashboardList() {
  const dashboard = isDevMode() ? { ...DASHBOARD, ...DEV } : DASHBOARD;
  const location = useLocation();
  const { search } = location;

  return (
    <Box
      component="ul"
      sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
    >
      {Object.keys(dashboard).map((key) => {
        const dashboardKey = key as keyof typeof DASHBOARD;
        const currentDashboardItem = dashboard[dashboardKey];
        const { path, display, beta } = currentDashboardItem;

        const formattedPath = path.toLowerCase();
        const toPath = `/${formattedPath}${search}`;
        const isSelected = location.pathname.startsWith(`/${formattedPath}`);

        return (
          <DashboardItem
            key={formattedPath}
            isSelected={isSelected}
            toPath={toPath}
            beta={beta}
            formattedPath={formattedPath}
            display={display}
          />
        );
      })}
    </Box>
  );
}
