import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import React from "react";

interface Page {
  title: string;
  Icon: React.ComponentType;
  next: string;
  path: string;
  step: number;
}

export const CREATE_PAGES: Record<string, Page> = {
  paymentPlanMethod: {
    title: "Payment Method",
    Icon: AccountBalanceWalletOutlinedIcon,
    next: "paymentPlanConfiguration",
    path: "/",
    step: 0,
  },
  paymentPlanConfiguration: {
    title: "Payment Plan",
    Icon: CalendarTodayOutlinedIcon,
    next: "paymentPlanConfirmation",
    path: "/terms",
    step: 1,
  },
  paymentPlanConfirmation: {
    title: "Summary",
    Icon: SummarizeOutlinedIcon,
    next: "paymentPlanSuccess",
    path: "/summary",
    step: 2,
  },
};

export const CREATE_PAYMENT_PLAN_FLOW: Array<keyof typeof CREATE_PAGES> = [
  "paymentPlanMethod",
  "paymentPlanConfiguration",
  "paymentPlanConfirmation",
];
