import { replaceByWieldyId } from "../../utils/utils";

export type PaymentsStore = {
  payment: PaymentWithClaimMessage | null;
  claims: ClaimWithProcedureAndPatientMessage[];
  payments: PaymentMessage[];
  loadedClaimsProcedures: boolean;
  loadedPayments: boolean;
};

export const initialPaymentsStore: PaymentsStore = {
  payment: null,
  claims: [],
  payments: [],
  loadedClaimsProcedures: false,
  loadedPayments: false,
};

export type PaymentsStoreAction =
  | {
      type: "SET_PAYMENT";
      payment: PaymentWithClaimMessage;
      claims: ClaimWithProcedureAndPatientMessage[];
    }
  | { type: "SET_PAYMENTS"; payments: PaymentMessage[] }
  | { type: "RESET_PAYMENT" }
  | { type: "RESET_PAYMENTS" }
  | { type: "UPDATE_CLAIM"; claimToUpdate: ClaimWithProcedureAndPatientMessage }
  | { type: "UPDATE_PAYMENT"; paymentToUpdate: PaymentMessage };

export function paymentsStoreReducer(
  store: PaymentsStore,
  action: PaymentsStoreAction,
): PaymentsStore {
  switch (action.type) {
    case "RESET_PAYMENT":
      return {
        ...store,
        payment: null,
        claims: [],
        loadedClaimsProcedures: false,
      };
    case "RESET_PAYMENTS":
      return {
        ...store,
        payments: [],
      };
    case "SET_PAYMENT":
      return {
        ...store,
        payment: action.payment,
        claims: action.claims,
        loadedClaimsProcedures: true,
      };
    case "SET_PAYMENTS":
      return {
        ...store,
        payments: action.payments,
        loadedPayments: true,
      };
    case "UPDATE_CLAIM":
      return {
        ...store,
        claims: replaceByWieldyId(store.claims, action.claimToUpdate) ?? [
          action.claimToUpdate,
        ],
      };
    case "UPDATE_PAYMENT":
      return {
        ...store,
        payments: replaceByWieldyId(store.payments, action.paymentToUpdate) ?? [
          action.paymentToUpdate,
        ],
      };
    default:
      return store;
  }
}
