import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { type InputProps } from "@mui/material/Input";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React from "react";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { ErrorHelperText } from "../../ui/ErrorHelper";
import { formatSSN, removeNonDigits } from "../../utils";
import { FormFields } from "./PatientDetailsForm.zod";

interface PatientDetailsResponsiblePartyFormFieldsProps {
  readOnly?: boolean;
  control: Control<FormFields>;
}

const readOnlyInputStyles = {
  "& input:disabled": {
    color: "initial",
    TextFillColor: "initial",
    WebkitTextFillColor: "initial",
  },
};

const readOnlyInputProps: InputProps = {
  disableUnderline: true,
};

export function PatientDetailsResponsiblePartyFormFields({
  readOnly,
  control,
}: PatientDetailsResponsiblePartyFormFieldsProps) {
  const theme = useTheme();
  const variant = readOnly ? "standard" : "outlined";
  const placeholder = readOnly ? "-" : undefined;
  const sx = readOnly ? readOnlyInputStyles : {};

  // disableUnderline is supported only for 'standard' and 'filled' variants.
  // See: https://github.com/mui/material-ui/issues/15502
  const setDisableUnderline =
    readOnly && variant === "standard" ? readOnlyInputProps : {};

  return (
    <Box sx={{ margin: `${theme.spacing(3)} 0` }}>
      <Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
        <Controller
          name="responsibleName"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                label="Full Name"
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                InputProps={setDisableUnderline}
                sx={sx}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleSsn"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                value={formatSSN(value)}
                label="Social Security Number"
                InputLabelProps={{ shrink: true }}
                error={Boolean(error) && value !== ""}
                InputProps={setDisableUnderline}
                sx={sx}
                onChange={(event) => {
                  const ssnDigits = removeNonDigits(event.target.value);
                  return ssnDigits ? onChange(ssnDigits) : onChange(null);
                }}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleEmail"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                type="email"
                label="Email"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleMobilePhone"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                label="Mobile Number"
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleWorkPhone"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={(event) =>
                  onChange(event.target.value === "" ? null : event)
                }
                error={Boolean(error)}
                label="Work Phone Number"
                sx={sx}
                InputProps={setDisableUnderline}
                InputLabelProps={{ shrink: true }}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleAddress"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={readOnly ? 3 : 6}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                label="Address"
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleCity"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                label="City"
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleState"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                label="State"
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
        <Controller
          name="responsibleZipCode"
          control={control}
          render={({
            field: { ref, name, value, onBlur, onChange },
            fieldState: { error },
          }) => (
            <Grid item xs={12} sm={3}>
              <TextField
                required
                fullWidth
                name={name}
                placeholder={placeholder}
                type="string"
                value={value}
                inputRef={ref}
                onBlur={onBlur}
                disabled={readOnly}
                label="Zip Code"
                variant={variant}
                onChange={onChange}
                error={Boolean(error)}
                InputLabelProps={{ shrink: true }}
                sx={sx}
                InputProps={setDisableUnderline}
              />
              <ErrorHelperText message={error?.message} />
            </Grid>
          )}
        />
      </Grid>
    </Box>
  );
}

PatientDetailsResponsiblePartyFormFields.defaultProps = {
  readOnly: false,
};
