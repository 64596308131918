import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorFilledIcon from "@mui/icons-material/Error";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import WarningIconIcon from "@mui/icons-material/Warning";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";

import { PaymentTransactionMessageWithFlag } from "./types";

export default function StatusRenderer(
  props: GridRenderCellParams<PaymentTransactionMessageWithFlag>,
) {
  const { value, row } = props;
  const theme = useTheme();
  switch (value) {
    case "PAID":
      return (
        <Box
          data-testid="paid"
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.success.main,
            textTransform: "capitalize",
            fontSize: "13px",
          }}
        >
          <CheckCircleOutlinedIcon
            sx={{
              marginRight: theme.spacing(1),
            }}
          />
          {value}
        </Box>
      );
    case "FAILED":
      return (
        <Box
          data-testid="failed"
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.error.main,
            textTransform: "capitalize",
            fontSize: "13px",
          }}
        >
          <ErrorFilledIcon
            sx={{
              marginRight: theme.spacing(1),
            }}
          />

          {value}
        </Box>
      );
    case "PENDING":
      return (
        <Box
          data-testid="pending"
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.warning.main,
            textTransform: "capitalize",
            fontSize: "13px",
          }}
        >
          <PendingOutlinedIcon
            sx={{
              marginRight: theme.spacing(1),
            }}
          />
          {value}
        </Box>
      );
    case "SCHEDULED":
      if (row.hasError) {
        return (
          <Box
            data-testid="scheduled"
            sx={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.warning.main,
              textTransform: "capitalize",
              fontSize: "13px",
            }}
          >
            <WarningIconIcon
              sx={{
                marginRight: theme.spacing(1),
              }}
            />
            {value}
          </Box>
        );
      }
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.info.main,
            textTransform: "capitalize",
            fontSize: "13px",
          }}
        >
          <ScheduleOutlinedIcon
            sx={{
              marginRight: theme.spacing(1),
            }}
          />
          {value}
        </Box>
      );
    default:
      return null;
  }
}
