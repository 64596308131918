import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import {
  DataGridPro,
  gridClasses,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import head from "lodash/head";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import useDataGridPersistence from "../../../../utils/datagrid";
import {
  isoToDate,
  sortAlphabetically,
  toValueOptions,
} from "../../../../utils/utils";
import Loading from "../../../Loading";
import { renderCellRedacted } from "../../../redact";
import CustomNoRowsOverlay from "../../../TableNoDataOverlay";
import PatientListHeader from "./PatientListHeader";

const getColumns = (
  searchParams: URLSearchParams,
  payerValueOptions: string[],
) => [
  {
    flex: 1,
    field: "name",
    headerName: "Patient Name",
    type: "string",
    renderCell: renderCellRedacted,
    valueGetter: (params: GridRenderCellParams<PatientWithClaimMessage>) =>
      params.row.name ?? "",
  },
  {
    flex: 1,
    field: "dob",
    headerName: "Patient DOB",
    type: "date",
    renderCell: renderCellRedacted,
    valueGetter: (params: GridRenderCellParams<PatientWithClaimMessage>) => {
      const patient = params.row;
      return patient.dob ? isoToDate(patient.dob) : "";
    },
  },
  {
    flex: 1,
    field: "payer",
    headerName: "Payer",
    type: "singleSelect",
    valueOptions: payerValueOptions,
    valueGetter: (params: GridRenderCellParams<PatientWithClaimMessage>) => {
      const { Claim } = params.row;
      const patientClaim = head(Claim);
      return patientClaim?.payer ?? "";
    },
  },
  {
    flex: 1,
    field: "lastClaimDate",
    headerName: "Last Claim Date",
    type: "date",
    valueGetter: (params: GridRenderCellParams<PatientWithClaimMessage>) => {
      const { lastClaimDate } = params.row;
      return lastClaimDate ? isoToDate(lastClaimDate) : "";
    },
  },
  {
    flex: 1,
    field: "lastPaymentDate",
    headerName: "Last Payment Date",
    type: "date",
    valueGetter: (params: GridRenderCellParams<PatientWithClaimMessage>) => {
      const { lastPaymentDate } = params.row;
      return lastPaymentDate ? isoToDate(lastPaymentDate) : "";
    },
  },
  {
    flex: 0.5,
    field: "",
    headerName: "Details",
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`/patients/${params.row.wieldyId}?${searchParams}`}>
        <Button
          type="button"
          variant="outlined"
          style={{
            cursor: "pointer",
            width: "100%",
          }}
        >
          <NavigateNextIcon
            style={{ cursor: "pointer" }}
            aria-label="Select Row"
          />
        </Button>
      </Link>
    ),
  },
];

interface PatientsListViewProps {
  patients: PatientWithClaimMessage[];
  patientsLoading: boolean;
  load: () => Promise<void>;
  reset: () => void;
  practices: PracticeMessage[];
}

export default function PatientsListView({
  patients,
  patientsLoading,
  load,
  reset,
  practices,
}: PatientsListViewProps) {
  useEffect(() => {
    load();
    return reset;
  }, []);
  const theme = useTheme();

  const searchParams = new URLSearchParams(window.location.search);

  const dataGridPersistence = useDataGridPersistence("patientsListView");

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );
  const payerValueOptions = toValueOptions(
    patients.flatMap((patient) => patient.Claim),
    "payer",
    sortAlphabetically,
  );

  return (
    <Box sx={{ paddingTop: theme.spacing(4) }}>
      <Box sx={{ marginBottom: theme.spacing(4) }}>
        <PatientListHeader practices={practices} />
      </Box>
      {/* TODO: extract DataGridPro to its own component */}
      <DataGridPro
        loading={patientsLoading}
        apiRef={dataGridPersistence.apiRef}
        columns={getColumns(searchParams, payerValueOptions)}
        sx={{
          [`& .${gridClasses.columnHeader}:first-child:focus, & .${gridClasses.cell}:first-child:focus`]:
            {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            },
          [`& .${gridClasses.columnHeader}:last-child:focus, & .${gridClasses.cell}:last-child:focus`]:
            {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            },
          [`& .rowDark`]: {
            backgroundColor: "#F2F4F7",
          },
        }}
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "rowDark" : ""
        }
        disableRowSelectionOnClick
        rows={patients}
        getRowId={(row) => row.wieldyId}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          loadingOverlay: Loading,
          toolbar: GridToolbar,
        }}
        pagination
        pageSizeOptions={[10, 20, 40]}
        initialState={{
          sorting: {
            sortModel: [{ field: "lastClaimDate", sort: "desc" }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
          ...(dataGridPersistence.retrieveState() ?? undefined),
        }}
        autoHeight
      />
    </Box>
  );
}
