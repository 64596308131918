import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import Chip from "@mui/material/Chip";
import React from "react";

import NotWorkingIcon from "./NotWorkingIcon.svg";

export function WorkingChip() {
  return (
    <Chip
      icon={<CheckOutlinedIcon aria-label="Credentials are valid" />}
      label="Active"
      sx={{
        height: "22px",
        backgroundColor: "#ECFDF3",
        border: "solid 1px #ABEFC6",
        color: "#067647",
        fontSize: "12px",
        fontWeight: 500,
        "& .MuiChip-icon": {
          color: "#17B26A",
          width: "12px",
          height: "12px",
          marginLeft: "10px",
        },
      }}
    />
  );
}

export function NotWorkingChip() {
  return (
    <Chip
      icon={<img src={NotWorkingIcon} alt="Credentials not valid" />}
      label="Inactive"
      sx={{
        height: "22px",
        backgroundColor: "#FEF3F2",
        border: "solid 1px #FECDCA",
        color: "#F04438",
        fontSize: "12px",
        fontWeight: 500,
        "& .MuiChip-icon": {
          width: "12px",
          height: "12px",
          marginLeft: "10px",
        },
      }}
    />
  );
}

export function InvalidChip() {
  return (
    <Chip
      icon={<img src={NotWorkingIcon} alt="Credentials not valid" />}
      label="Invalid"
      sx={{
        height: "22px",
        backgroundColor: "#FEF3F2",
        border: "solid 1px #FECDCA",
        color: "#F04438",
        fontSize: "12px",
        fontWeight: 500,
        "& .MuiChip-icon": {
          width: "12px",
          height: "12px",
          marginLeft: "10px",
        },
      }}
    />
  );
}
