export enum ClaimStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}

export enum ReviewStatus {
  ACCEPTED = "ACCEPTED",
  REVIEW = "REVIEW",
}

export enum CreatedByActor {
  SYSTEM = "SYSTEM",
  HUMAN = "HUMAN",
}
