import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import React from "react";

export function Logout() {
  const { logout } = useAuth0();

  return (
    <IconButton
      color="secondary"
      onClick={() => logout()}
      sx={{
        ":hover": {
          bgcolor: "#1E1D31",
          borderRadius: "12px",
          justifyContent: "left",
          padding: "8px 12px",
          width: "90%",
        },
        fontSize: "0.875rem",
        marginTop: "10px",
        borderRadius: "12px",
        justifyContent: "left",
        padding: "8px 12px",
        width: "90%",
      }}
    >
      <LogoutIcon sx={{ marginRight: ".75rem", width: 24, height: 24 }} />
      Logout
    </IconButton>
  );
}
