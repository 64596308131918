import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import React from "react";

import useQueryClient from "../../api/query";
import { PaymentPlanStore } from "../PaymentPlans/store";
import { SavedPaymentMethod } from "../PaymentPlans/views/create/Payment/SavedPaymentMethod";
import { SMSPaymentMethod } from "../PaymentPlans/views/create/Payment/SMSPaymentMethod";
import NewPaymentMethod from "../PaymentPlans/views/NewPaymentMethod";

interface RetryInstallmentDialogProps {
  open: boolean;
  close?: () => void;
  primaryOnClick: () => void;
  paymentPlanStore: PaymentPlanStore;
  patient: PatientMessage;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function RetryInstallmentDialog({
  open,
  close,
  primaryOnClick,
  paymentPlanStore,
  patient,
  updateSnackBar,
}: RetryInstallmentDialogProps) {
  const theme = useTheme();
  const [newPaymentMethodModal, setNewPaymentMethodModal] =
    React.useState(false);
  const [paymentMethodChecked, setPaymentMethodChecked] = React.useState(
    paymentPlanStore.paymentMethodOptions?.find((x) => x.primary),
  );

  const queryClient = useQueryClient();
  const hasPaymentMethodOptions = Array.isArray(
    paymentPlanStore.paymentMethodOptions,
  );

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="sm">
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            padding: 3,
            minWidth: "456px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            const _formData = new FormData(event.target as HTMLFormElement);
            primaryOnClick();
          }}
        >
          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: 0,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DialogContentText
                  color="black"
                  sx={{ fontSize: theme.typography.h5, fontWeight: 600 }}
                >
                  Manually Reattempt Payment
                </DialogContentText>
                <IconButton
                  aria-label="close"
                  onClick={close}
                  sx={{
                    color: theme.palette.grey[500],
                    paddingX: "8px",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <DialogContentText
                sx={{
                  fontSize: theme.typography.subtitle2,
                  color: "#09080FA6",
                }}
              >
                Please select how to reattempt the payment
              </DialogContentText>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <DialogContentText
                color="black"
                sx={{
                  fontSize: theme.typography.h6,
                  fontWeight: 600,
                }}
              >
                Associated Payment Methods
              </DialogContentText>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  "& label": {
                    width: "100%",
                  },
                }}
              >
                {hasPaymentMethodOptions &&
                  paymentPlanStore.paymentMethodOptions
                    ?.sort((a) => (a.primary === true ? -1 : 0)) // sort so primary is first card
                    .map((paymentMethodOption) => {
                      const isChecked =
                        paymentMethodOption.id === paymentMethodChecked?.id;
                      return (
                        <SavedPaymentMethod
                          key={paymentMethodOption.id}
                          paymentMethod={paymentMethodOption}
                          checked={isChecked}
                          onChange={() =>
                            setPaymentMethodChecked(paymentMethodOption)
                          }
                        />
                      );
                    })}

                <IconButton
                  type="button"
                  color="info"
                  onClick={() =>
                    setNewPaymentMethodModal(!newPaymentMethodModal)
                  }
                  sx={{
                    borderRadius: "12px",
                    alignItems: "center",
                    gap: "2px",
                    marginTop: "12px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  <AddCircleOutlineIcon />
                  Add new payment method
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "16px", flexDirection: "column" }}>
              <DialogContentText
                color="black"
                sx={{
                  fontSize: theme.typography.h6,
                  fontWeight: 600,
                }}
              >
                Request payment method update
              </DialogContentText>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  "& label": {
                    width: "100%",
                  },
                }}
              >
                <SMSPaymentMethod />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: 0 }}>
            <Button
              type="submit"
              data-testid="yes"
              variant="contained"
              autoFocus
              sx={{ width: "100%" }}
              disabled
            >
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {newPaymentMethodModal && (
        <NewPaymentMethod
          onRequestClose={() =>
            setNewPaymentMethodModal(!newPaymentMethodModal)
          }
          open={newPaymentMethodModal}
          onAdd={() => {
            primaryOnClick();
          }}
          queryClient={queryClient}
          patient={patient}
          updateSnackBar={updateSnackBar}
        />
      )}
    </>
  );
}

RetryInstallmentDialog.defaultProps = {
  close: undefined,
};
