/* eslint-disable indent */
import { type InputProps } from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import dayjs, { type Dayjs } from "dayjs";
import React, { type HTMLInputTypeAttribute } from "react";

import { moneyMessageToNumber } from "../../utils/utils";

export type PaymentPlanFieldValue = string | number | Dayjs | MoneyMessage;
export type PaymentPlanFieldChangeHandler = (
  name: string,
  value: PaymentPlanFieldValue,
) => void;

function toDisplay(
  value: PaymentPlanFieldValue | undefined,
  type: HTMLInputTypeAttribute | "money" | "percent",
  precision = 2,
): string | number {
  if (value === undefined) {
    return "";
  }

  switch (type) {
    case "percent":
      return `${value as number}%`;
    case "money": {
      let moneyValue = value;
      if (typeof value === "object") {
        moneyValue = moneyMessageToNumber(value as MoneyMessage);
      }
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(moneyValue as number);
    }
    case "string":
      return value as string;
    case "date":
      return dayjs(value as string).format("YYYY-MM-DD");
    case "number":
      return value as number;
    default:
      throw new Error("Unsupported type for PaymentPlanField");
  }
}
interface PaymentPlanFieldProps {
  label: string;
  name: string;
  type?: HTMLInputTypeAttribute | "money" | "percent";
  precision?: number;
  value?: PaymentPlanFieldValue;
  required?: boolean;
  disabled?: boolean;
}

export default function PaymentPlanField({
  label,
  name,
  type = "string",
  value,
  precision,
  required,
  disabled,
}: PaymentPlanFieldProps) {
  const variant = disabled ? "standard" : "outlined";
  let inputProps: InputProps = { name };
  // disableUnderline is supported only for 'standard' and 'filled' variants.
  // See: https://github.com/mui/material-ui/issues/15502
  if (variant === "standard") {
    inputProps = {
      ...inputProps,
      disableUnderline: true,
    };
  }

  const inputLabelProps = { shrink: true };
  const sx = disabled
    ? {
        "& input:disabled": {
          color: "initial",
          TextFillColor: "initial",
          WebkitTextFillColor: "initial",
        },
      }
    : undefined;
  const placeholder = disabled ? "-" : undefined;
  return (
    <TextField
      label={label}
      variant={variant}
      InputProps={inputProps}
      InputLabelProps={inputLabelProps}
      sx={sx}
      type={type}
      value={toDisplay(value, type, precision)}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      fullWidth
    />
  );
}

PaymentPlanField.defaultProps = {
  type: "string",
  value: undefined,
  required: false,
  disabled: false,
  precision: 2,
};
