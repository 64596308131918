enum Urgency {
  LOW,
  MEDIUM,
  HIGH,
}

export default new Map<Urgency, { label: string; color: string }>([
  [Urgency.LOW, { label: "Low", color: "#12B76A" }],
  [Urgency.MEDIUM, { label: "Medium", color: "#F79009" }],
  [Urgency.HIGH, { label: "High", color: "#F04438" }],
]);
export { Urgency };
