import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

export type DateRange = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

enum Preset {
  Today,
  Week,
  Month,
  All,
}

function paymentsDateRange(earliestStartDate: string | null): DateRange {
  if (!earliestStartDate) {
    return {
      startDate: dayjs(),
      endDate: dayjs(),
    };
  }

  return {
    startDate: dayjs(earliestStartDate),
    endDate: dayjs(),
  };
}

function presetFromDateRange(dateRange: DateRange): Preset | null {
  if (
    dateRange.endDate === null ||
    dateRange.startDate === null ||
    !dateRange.endDate.isSame(dayjs(), "day")
  ) {
    return null;
  }
  if (dateRange.startDate.isSame(dayjs(), "day")) {
    return Preset.Today;
  }
  if (dateRange.startDate.isSame(dayjs().subtract(1, "week"), "day")) {
    return Preset.Week;
  }
  if (dateRange.startDate.isSame(dayjs().subtract(1, "month"), "day")) {
    return Preset.Month;
  }
  return Preset.All;
}

interface DatePickerProps {
  selectedDateRange: DateRange;
  setDateRange: (dateRange: DateRange) => void;
  earliestStartDate: string | null;
}

export default function DateRangePicker({
  selectedDateRange,
  setDateRange,
  earliestStartDate,
}: DatePickerProps) {
  const [preset, setPreset] = useState<Preset | null>(
    presetFromDateRange(selectedDateRange),
  );

  const handlePreset = (presetValue: number | null) => {
    if (typeof presetValue !== "number") {
      return;
    }

    switch (presetValue) {
      case Preset.Today: {
        setDateRange({
          startDate: dayjs(),
          endDate: dayjs(),
        });
        break;
      }
      case Preset.Week: {
        setDateRange({
          startDate: dayjs().subtract(1, "week"),
          endDate: dayjs(),
        });
        break;
      }
      case Preset.Month: {
        setDateRange({
          startDate: dayjs().subtract(1, "month"),
          endDate: dayjs(),
        });
        break;
      }
      default: {
        // Preset.All
        setDateRange(paymentsDateRange(earliestStartDate));
        break;
      }
    }
    setPreset(presetValue);
  };

  const handleDateSearch = (rangeToSet: DateRange) => {
    setPreset(null);
    setDateRange(rangeToSet);
  };

  return (
    <>
      <ToggleButtonGroup
        size="small"
        style={{ marginRight: "12px" }}
        color="primary"
        value={preset}
        exclusive
        onChange={(_event, newPresetValue) => {
          handlePreset(newPresetValue);
        }}
        aria-label="Date Presets"
      >
        <ToggleButton data-testid="today" value={Preset.Today}>
          Today
        </ToggleButton>
        <ToggleButton data-testid="week" value={Preset.Week}>
          Week
        </ToggleButton>
        <ToggleButton data-testid="month" value={Preset.Month}>
          Month
        </ToggleButton>
        <ToggleButton data-testid="all" value={Preset.All}>
          All
        </ToggleButton>
      </ToggleButtonGroup>
      <DatePicker
        label="Start Date"
        value={selectedDateRange.startDate}
        displayWeekNumber
        onChange={(newValue) =>
          handleDateSearch({
            ...selectedDateRange,
            startDate: newValue,
          })
        }
        slotProps={{
          textField: { style: { width: "160px", marginRight: "2px" } },
        }}
      />
      <DatePicker
        label="End Date"
        value={selectedDateRange.endDate}
        displayWeekNumber
        onChange={(newValue) =>
          handleDateSearch({
            ...selectedDateRange,
            endDate: newValue,
          })
        }
        slotProps={{ textField: { style: { width: "160px" } } }}
      />
    </>
  );
}
