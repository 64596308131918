import { z } from "zod";

import { isUSMobileNumber } from "../../utils";

export const patientSchema = z.object({
  email: z.string().email("Please enter the patient's email").nullable(),
  name: z.string().min(1, "Please enter the patient's full name"),
  ssn: z.string().length(9, "Patient SSN is formatted incorrectly").nullable(),
  dob: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, {
    message: "Please enter the patient's date of birth",
  }),
  phoneNumber: z
    .string()
    .refine(isUSMobileNumber, "Please enter the patient's phone number")
    .nullable(),
  address: z.string().min(1, "Please enter the patient's address").nullable(),
  city: z.string().min(1, "Please enter the patient's city").nullable(),
  state: z.string().min(1, "Please enter the patient's state").nullable(),
  zipCode: z.string().min(1, "Please enter the patient's zip code").nullable(),
  practiceId: z
    .string({
      required_error: "A patient must belong to a practice",
    })
    .min(1, "A patient must be set to a practice"),
  isAdditionalInfoRequired: z.boolean(),
});

export const responsibleSchema = z.object({
  responsibleEmail: z
    .string()
    .email("Please enter the responsible person's email"),
  responsibleName: z
    .string()
    .min(1, "Please enter the responsible person's  full name"),
  responsibleSsn: z
    .string()
    .regex(/^\d{9}$/, "Responsible person's SSN is formatted incorrectly")
    .length(9, "Responsible person's SSN is formatted incorrectly")
    .nullable(),
  dob: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, {
    message: "Please enter the responsible person's date of birth",
  }),
  // TODO: Ask about phone number validation preference, my default is non so its easy to support
  responsibleMobilePhone: z
    .string()
    .refine(
      isUSMobileNumber,
      "Please enter the responsible person's phone number",
    ),
  responsibleWorkPhone: z
    .string()
    .refine(
      isUSMobileNumber,
      "Please enter the responsible person's work number",
    )
    .nullable(),
  responsibleAddress: z
    .string()
    .min(1, "Please enter the responsible person's address"),
  responsibleCity: z
    .string()
    .min(1, "Please enter the responsible person's city"),
  responsibleState: z
    .string()
    .min(1, "Please enter the responsible person's state"),
  responsibleZipCode: z
    .string()
    .min(1, "Please enter the responsible person's zip code"),
});

export const extendedFormSchema = patientSchema.merge(responsibleSchema);

type PatientFormFields = z.infer<typeof patientSchema>;
type ExtendedPatientFormFields = z.infer<typeof extendedFormSchema>;

export type FormFields = PatientFormFields | ExtendedPatientFormFields;
