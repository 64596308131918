import TagManager from "react-gtm-module";

import { Backend, getBackend } from "../api/apiConfig";

function gtmCode(): string | null {
  switch (getBackend()) {
    case Backend.Staging:
      return "GTM-MX9HZWQ";
    case Backend.Production:
      return "GTM-W7K9C8N";
    default:
      return null;
  }
}

export default function initializeGTM(): void {
  const code = gtmCode();
  if (code) {
    const tagManagerArgs = {
      gtmId: code,
    };
    TagManager.initialize(tagManagerArgs);
  }
}
