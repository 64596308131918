import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";

import useDataGridPersistence from "../../utils/datagrid";
import { isDevMode } from "../../utils/utils";
import ClaimsTable from "../ClaimsTable";

function filterClaims(
  claims: ClaimWithProcedureAndPatientMessage[],
  selectedPractices: PracticeMessage["name"][],
): ClaimWithProcedureAndPatientMessage[] {
  const selectedPracticeSet = new Set(selectedPractices);
  return (
    claims.filter((claim) =>
      selectedPracticeSet.has(claim.practiceDisplayName),
    ) || []
  );
}

interface ToDosListViewProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  loading: boolean;
  updateClaim: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  onSaveClaim: (claim: ClaimMessage) => Promise<void>;
  onSaveProcedure: (procedure: ProcedureMessage) => Promise<void>;
  postToPMS: (claim: ClaimWithProcedureAndPatientMessage) => void;
  batchPostPMS: () => void;
  isBatchPosting: boolean;
  load: () => Promise<void>;
  reset: () => void;
  selectedPractices: PracticeMessage[];
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function ToDosListView({
  claims,
  loading,
  updateClaim,
  onSaveClaim,
  onSaveProcedure,
  postToPMS,
  batchPostPMS,
  isBatchPosting,
  load,
  reset,
  selectedPractices,
  supportedPayers,
}: ToDosListViewProps) {
  useEffect(() => {
    load();
    return reset;
  }, []);

  const dataGridPersistence = useDataGridPersistence("claimsListView");
  const selectedPracticeNames = selectedPractices.map(
    (practice) => practice.displayName,
  );

  return (
    <>
      <h1>Claims</h1>
      {isDevMode() ? (
        <Button
          variant="contained"
          onClick={batchPostPMS}
          disabled={isBatchPosting}
          sx={{ width: "auto", minWidth: "7.5rem" }}
        >
          {!isBatchPosting ? (
            "Batch Post"
          ) : (
            <CircularProgress color="inherit" size={24} />
          )}
        </Button>
      ) : null}
      <Grid
        container
        spacing={2}
        style={{
          marginTop: "27px",
          padding: "0px 2px",
        }}
      >
        <Grid item xs={12}>
          <ClaimsTable
            enablePagination
            enablePaymentNavigation
            claims={filterClaims(claims, selectedPracticeNames)}
            isLoading={loading}
            onClaimUpdate={updateClaim}
            onPostToPMS={postToPMS}
            enableClaimDetails
            initialState={dataGridPersistence.retrieveState()}
            apiRef={dataGridPersistence.apiRef}
            excludeByFieldNames={["paymentId", "notes"]}
            sortColumnOrder={[
              "urgency",
              "claimStatus",
              "availableSince",
              "practiceDisplayName",
              "payer",
              "wieldyPatientName",
              "claimId",
              "wieldyClaimDate",
              "claimPayerPays",
              "sources",
              "action",
              "postedState",
              "postedDateTime",
            ]}
            onSaveClaim={onSaveClaim}
            onSaveProcedure={onSaveProcedure}
            practices={selectedPractices}
            supportedPayers={supportedPayers}
          />
        </Grid>
      </Grid>
    </>
  );
}
