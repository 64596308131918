import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../../../../formatters";
import { isDevMode } from "../../../../../utils/utils";
import CustomNoRowsOverlay from "../../../../TableNoDataOverlay";
import { averagePaymentByProcedure } from "./rows";

const applicationHeaderOffset = 47;

function getColumns(payers: string[]): GridColDef[] {
  const cols = [
    {
      field: "procedureCode",
      headerName: "Procedure Code",
      flex: 1,
    },
  ];
  return cols.concat(
    payers.map((payer) => ({
      field: payer,
      headerName: payer,
      flex: 1,
      valueGetter: (params: GridRenderCellParams) =>
        params.value ? formatMoney(params.value) : "-",
    })),
  );
}

function toPayers(claims: ClaimWithProcedureMessage[]): string[] {
  return Array.from(new Set(claims.map((claim) => claim.payer)));
}

interface AvgPaymentByProcedureProps {
  claims: ClaimWithProcedureMessage[];
}

export default function AvgPaymentByProcedure({
  claims,
}: AvgPaymentByProcedureProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );
  return (
    <>
      <h2>Average Payment By Procedure Across Payer</h2>
      <DataGridPro
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            zIndex: 1,
            top: applicationHeaderOffset,
          },
          "& .MuiDataGrid-main": {
            overflow: "visible",
          },
        }}
        columns={getColumns(toPayers(claims))}
        rows={averagePaymentByProcedure(claims)}
        getRowSpacing={getRowSpacing}
        getRowId={(row) => row.procedureCode}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: isDevMode() ? GridToolbar : undefined,
        }}
      />
    </>
  );
}
