import { AlertColor } from "@mui/material/Alert/Alert";
import React from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { PaymentMethodsPage } from "./PaymentMethodsPage";
import { SuccessPage } from "./SuccessPage";
import { WelcomePage } from "./WelcomePage";
// import Loading from "../Loading";

interface PatientPortalProps {
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function PatientPortal({
  queryClient,
  updateSnackBar,
}: PatientPortalProps) {
  // TODO validation session & load practice information

  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route
        path="/payment-methods"
        element={
          <PaymentMethodsPage
            queryClient={queryClient}
            updateSnackBar={updateSnackBar}
          />
        }
      />
      <Route path="/success" element={<SuccessPage />} />
    </Routes>
  );
}
