import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import { SavedPaymentMethodDetails } from "../../PaymentPlans/views/create/Payment/SavedPaymentMethod";

export default function PaymentMethodCard({
  paymentMethod,
  canRemove,
  onSetAsPrimary,
  onDelete,
}: {
  paymentMethod: PatientPaymentMessage;
  canRemove?: boolean;
  onSetAsPrimary: (paymentMethodId: string) => void;
  onDelete: (paymentMethodId: string) => void;
}) {
  const theme = useTheme();
  return (
    <Box border={1} borderColor={theme.palette.divider} borderRadius={1}>
      <Box padding={2}>
        <Box
          component="label"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body1"
            fontSize="18px"
            fontWeight={600}
            lineHeight="160%"
          >
            {paymentMethod.paymentMethodType === "CARD"
              ? "Credit Card"
              : "Bank Account"}
          </Typography>
          <Box display="flex" alignItems="center">
            {paymentMethod.primary && (
              <Chip
                label="Primary"
                sx={{ marginRight: 1, height: "20px", fontSize: "12px" }}
              />
            )}
            <Radio
              value={paymentMethod.id}
              onClick={() => {
                if (!paymentMethod.primary) {
                  onSetAsPrimary(paymentMethod.id);
                }
              }}
            />
          </Box>
        </Box>
        <SavedPaymentMethodDetails paymentMethod={paymentMethod} />
      </Box>
      <Button
        disabled={!canRemove || paymentMethod.primary}
        onClick={() => onDelete(paymentMethod.id)}
        variant="text"
        startIcon={<DeleteOutlineIcon />}
        sx={{
          background: "rgba(37, 35, 63, 0.08)",
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          color: theme.palette.error.main,
          textTransform: "none",
        }}
        fullWidth
      >
        Remove payment method
      </Button>
    </Box>
  );
}

PaymentMethodCard.defaultProps = {
  canRemove: true,
};
