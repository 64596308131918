import { AlertColor } from "@mui/material/Alert/Alert";
import React, { Dispatch } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import ReconciliationListView from "./ReconciliationListView";
import { ReconciliationsStore, ReconciliationsStoreAction } from "./store";

interface ReconciliationDashboardProps {
  store: ReconciliationsStore;
  updateStore: Dispatch<ReconciliationsStoreAction>;
  queryClient: QueryClient;
  loadPractices: () => Promise<void>;
  practices: PracticeMessage[];
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function ReconciliationDashboard({
  store,
  updateStore,
  queryClient,
  loadPractices,
  practices,
  updateSnackBar,
}: ReconciliationDashboardProps) {
  const loadReconciliations = async () => {
    await loadPractices();
    updateStore({
      transactions: await queryClient.getBankTransactions(),
      type: "SET_TRANSACTIONS",
    });
  };

  const resetReconciliations = () => {
    updateStore({
      type: "RESET_TRANSACTIONS",
    });
  };

  const uploadBankTransactions = async (practiceId: string, file: File) => {
    try {
      await queryClient.uploadBankTransactions(practiceId, file);
      resetReconciliations();
      updateStore({
        transactions: await queryClient.getBankTransactions(),
        type: "SET_TRANSACTIONS",
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully matched transactions.",
      });
    } catch (error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to match transactions. Try again",
      });
      // eslint-disable-next-line no-console
      console.error(`Failed to upload bank transactions: ${error}`);
    }
  };
  const updateTransaction = async (
    transactionUpdate: BankTransactionUpdateMessage,
  ) => {
    updateStore({
      type: "UPDATE_TRANSACTION",
      transactionUpdate,
    });
    await queryClient.updateBankTransaction(transactionUpdate);
    resetReconciliations();
    updateStore({
      transactions: await queryClient.getBankTransactions(),
      type: "SET_TRANSACTIONS",
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ReconciliationListView
            load={loadReconciliations}
            reset={resetReconciliations}
            transactions={store.transactions}
            transactionsLoading={!store.loadedTransactions}
            practices={practices}
            uploadBankTransactions={uploadBankTransactions}
            updateTransaction={updateTransaction}
          />
        }
      />
    </Routes>
  );
}
