import { useAuth0 } from "@auth0/auth0-react";
import axios, { type Axios, type CreateAxiosDefaults } from "axios";
import { useCallback } from "react";

let hostname = "";
// We set this so that it's only run on the client side.
if (typeof window !== "undefined") {
  hostname = window && window.location && window.location.hostname;
}

enum Backend {
  Local,
  Staging,
  Production,
}

function getBackend(): Backend {
  if (hostname === "localhost") {
    return Backend.Local;
  }

  if (hostname.toLowerCase().includes("staging")) {
    return Backend.Staging;
  }

  return Backend.Production;
}

function getBackendAPI(): string {
  switch (getBackend()) {
    case Backend.Local:
      return "http://localhost:8000/api/external/";
    case Backend.Staging:
      return "https://staging.api.wieldy.ai/api/external/";
    case Backend.Production:
      return "https://api.wieldy.ai/api/external/";
    default:
      throw new Error("Backend should be local, staging, or production");
  }
}

const FINIX_ENVIRONMENT = {
  [Backend.Local]: "sandbox",
  [Backend.Staging]: "sandbox",
  [Backend.Production]: "live",
}[getBackend()];

const FINIX_APPLICATION_ID = {
  [Backend.Local]: "APg9hedUxuBokSwDswxTxtaE",
  [Backend.Staging]: "APg9hedUxuBokSwDswxTxtaE",
  [Backend.Production]: "AP8LuL1XzLR661owkPq4whE2",
}[getBackend()];

const AUTH = {
  [Backend.Local]: false,
  [Backend.Staging]: true,
  [Backend.Production]: true,
};

const USE_AUTH = AUTH[getBackend()];

const useApi = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getClient = useCallback(async (): Promise<Axios> => {
    const options: CreateAxiosDefaults = {
      baseURL: getBackendAPI(),
    };
    if (USE_AUTH) {
      const accessToken = await getAccessTokenSilently();
      options.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return axios.create(options);
  }, [getAccessTokenSilently]);

  return { getClient };
};

export default useApi;
export {
  Backend,
  getBackend,
  getBackendAPI,
  FINIX_APPLICATION_ID,
  FINIX_ENVIRONMENT,
  USE_AUTH,
};
