import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import type { Control } from "react-hook-form";
import { Controller } from "react-hook-form";

import { FormFields } from "./PatientDetailsForm.zod";

interface ToggleResponsiblePartyFormFieldsProps {
  control: Control<FormFields>;
  defaultChecked?: boolean;
  onChange: () => void;
  readOnly?: boolean;
}

export function ToggleResponsiblePartyFormFields({
  control,
  defaultChecked,
  onChange,
  readOnly,
}: ToggleResponsiblePartyFormFieldsProps) {
  const theme = useTheme();

  const unCheckedIcon = readOnly ? (
    <VisibilityIcon fontSize="small" />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
  const checkedIcon = readOnly ? (
    <VisibilityOffIcon fontSize="small" />
  ) : (
    <CheckBoxIcon />
  );

  const formControlLabel = readOnly ? {} : { width: "35%" };

  return (
    <Box sx={{ display: "flex" }}>
      <Controller
        name="isAdditionalInfoRequired"
        control={control}
        render={({ field: { name, value, onChange: inputOnChange } }) => (
          <FormControlLabel
            label="Responsible Party"
            sx={formControlLabel}
            componentsProps={{ typography: { sx: { fontSize: "0.875rem" } } }}
            control={
              <Checkbox
                name={name}
                icon={unCheckedIcon}
                checkedIcon={checkedIcon}
                value={value}
                onChange={(event) => {
                  onChange();
                  return inputOnChange(event);
                }}
                defaultChecked={defaultChecked}
              />
            }
          />
        )}
      />
      <Divider
        orientation="vertical"
        sx={{ margin: `0 ${theme.spacing(2)}` }}
        flexItem
      />
      <Typography
        variant="caption"
        style={{
          display: "flex",
          alignItems: "center",
          color: theme.palette.text.secondary,
        }}
      >
        Check this field to add specific details for the responsible person of
        this patient
      </Typography>
    </Box>
  );
}

ToggleResponsiblePartyFormFields.defaultProps = {
  defaultChecked: false,
  readOnly: false,
};
