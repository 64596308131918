/* eslint-disable indent */
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import { AlertColor } from "@mui/material/Alert/Alert";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import { type StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { type SxProps, type Theme, useTheme } from "@mui/material/styles";
import React, { Dispatch } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import type QueryClient from "../../../api/query/queryClient";
import Loading from "../../Loading";
import Modal from "../../Modal";
import { PatientsStore } from "../../PatientsDashboard/store";
import { CREATE_PAGES, CREATE_PAYMENT_PLAN_FLOW } from "../config";
import { PaymentPlanStore, PaymentPlanStoreAction } from "../store";
import { Payment } from "./create/Payment";
import { Summary } from "./create/Summary";
import { Terms } from "./create/Terms";

function renderStepIcon(
  Icon: React.ElementType,
  theme: Theme,
): React.ElementType<StepIconProps> {
  return function Element({ active, completed }: StepIconProps) {
    if (completed && !active) {
      return (
        <IconButton
          sx={{
            color: theme.palette.success.main,
            border: `2px solid ${theme.palette.success.main}`,
            "&:hover": { backgroundColor: "initial" },
          }}
        >
          <CheckOutlined />
        </IconButton>
      );
    }

    const iconButtonStyles: SxProps<Theme> = active
      ? {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          },
        }
      : {
          border: "2px solid rgba(37, 35, 63, 0.50)",
          "&:hover": { backgroundColor: "initial" },
        };
    return (
      <IconButton sx={iconButtonStyles}>
        <Icon />
      </IconButton>
    );
  };
}

interface CreatePaymentPlanViewProps {
  patientId: string;
  store: PaymentPlanStore;
  updateStore: Dispatch<PaymentPlanStoreAction>;
  patientStore: PatientsStore;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function CreatePaymentPlanView({
  patientId,
  patientStore,
  store,
  updateStore,
  queryClient,
  updateSnackBar,
}: CreatePaymentPlanViewProps) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPath = pathname.split("payment-plans/new")?.[1] || "/";
  let activeStep = 0;
  if (currentPath === "/terms") {
    activeStep = 1;
  } else if (currentPath === "/summary") {
    activeStep = 2;
  } else if (currentPath === "/success") {
    activeStep = -1;
  }

  return (
    <Modal
      title="Payment plan"
      open
      onClose={() => {
        navigate(`/patients/${patientId}`);
      }}
      adornment={
        activeStep >= 0 ? (
          <Stepper
            activeStep={activeStep}
            connector={<StepConnector sx={{ width: "85px" }} />}
          >
            {CREATE_PAYMENT_PLAN_FLOW.map((step) => (
              <Step key={step}>
                <StepLabel
                  StepIconComponent={renderStepIcon(
                    CREATE_PAGES[step].Icon,
                    theme,
                  )}
                >
                  {CREATE_PAGES[step].title}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : null
      }
    >
      {!patientStore.patient && <Loading />}
      {patientStore.patient && (
        <Routes>
          <Route
            path="/"
            element={
              <Payment
                patientId={patientId}
                store={store}
                updateStore={updateStore}
                queryClient={queryClient}
                updateSnackBar={updateSnackBar}
              />
            }
          />
          <Route
            path="/terms"
            element={
              <Terms
                patientId={patientId}
                store={store}
                updateStore={updateStore}
              />
            }
          />
          <Route
            path="/summary"
            element={
              <Summary
                patientId={patientId}
                store={store}
                queryClient={queryClient}
                updateSnackBar={updateSnackBar}
              />
            }
          />
        </Routes>
      )}
    </Modal>
  );
}
