export enum Action {
  RESUBMIT,
  APPEAL,
  WRITE_OFF,
  PATIENT_RESPONSIBILITY,
  INTERNAL_TEAMMATE,
  COMPLETED,
  DUPLICATE,
}

export default new Map<Action, { label: string }>([
  [Action.RESUBMIT, { label: "✈️ Resubmit" }],
  [Action.APPEAL, { label: "🏛 Appeal" }],
  [Action.WRITE_OFF, { label: "💸 Write Off" }],
  [Action.PATIENT_RESPONSIBILITY, { label: "🤒 Patient Responsibility" }],
  [Action.INTERNAL_TEAMMATE, { label: "👬 Internal Teammate" }],
  [Action.COMPLETED, { label: "🏁 Completed" }],
  [Action.DUPLICATE, { label: "📋 Duplicate" }],
]);
