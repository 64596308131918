import { Button, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  linkPath: string;
  displayText: string;
  ariaLabel: string;
}

export function NavigationCell({ linkPath, displayText, ariaLabel }: Props) {
  const theme = useTheme();

  return (
    <Link
      to={linkPath}
      style={{
        textDecoration: "underline",
        color: theme.palette.primary.main,
      }}
    >
      <Button
        component="p"
        data-sr-redact
        aria-label={ariaLabel}
        variant="text"
        style={{
          textDecoration: "underline",
          color: theme.palette.primary.main,
        }}
      >
        {displayText}
      </Button>
    </Link>
  );
}
