import "../../App.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Pagination } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {
  DataGridPro,
  gridClasses,
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  GridPagination,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridToolbar,
  GridValueFormatterParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import React, { MutableRefObject } from "react";

import { formatMoney } from "../../formatters";
import { ReviewStatus } from "../../status";
import {
  enumToStrings,
  isDevMode,
  isoToDate,
  sortAlphabetically,
  toValueOptions,
} from "../../utils/utils";
import Loading from "../Loading";
import { renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import urgencySelectColor, { Urgency } from "../urgencyColors";

const COLUMN_DEFAULT_VISIBILITY = {
  urgency: false,
  practice: true,
  date: true,
  payer: true,
  payee: false,
  payerPaymentId: true,
  paymentStatus: true,
  paymentType: true,
  productType: false,
  eopPaymentAmount: true,
  isPosted: true,
  reviewStatus: isDevMode(),
};

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(_event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function CustomFooter(): React.ReactElement {
  return (
    <GridFooterContainer>
      <CustomPagination />
      <GridPagination />
    </GridFooterContainer>
  );
}

function renderUrgency(props: GridRenderCellParams) {
  const { value } = props;
  const urgency = Urgency[value];
  const textColor =
    typeof urgency === "number"
      ? urgencySelectColor.get(urgency)?.color
      : "#475467";
  const text = value || "-";

  return (
    <div style={{ color: textColor, textTransform: "capitalize" }}>
      {text.toLowerCase()}
    </div>
  );
}

const renderPosted = (props: GridRenderCellParams) => (
  <Checkbox
    disabled
    color="primary"
    checked={props.value}
    inputProps={{
      "aria-labelledby": "isPosted",
    }}
    sx={{
      [`&.Mui-disabled`]: {
        color: (theme) => theme.palette.primary.main,
      },
    }}
  />
);

const getColumns = (
  payerValueOptions: string[],
  practiceValueOptions: string[],
  onSelectPayment: (payment: PaymentMessage) => void,
) => [
  {
    flex: 1,
    field: "urgency",
    headerName: "Urgency",
    type: "singleSelect",
    valueOptions: enumToStrings(Urgency).map((urg) => ({
      value: urg.toUpperCase(),
      label: urg,
    })),
    renderCell: renderUrgency,
    sortComparator: (a: string, b: string) => {
      const urgencyA = Urgency[a as keyof typeof Urgency] ?? -1;
      const urgencyB = Urgency[b as keyof typeof Urgency] ?? -1;
      return urgencyA - urgencyB;
    },
  },
  {
    flex: 1,
    field: "practiceDisplayName",
    headerName: "Practice",
    type: "singleSelect",
    valueOptions: practiceValueOptions.length
      ? practiceValueOptions
      : undefined,
  },
  {
    flex: 1,
    type: "date",
    field: "paymentDate",
    headerName: "Payment Date",
    valueGetter: (params: GridRenderCellParams) =>
      params.row.paymentDate ? isoToDate(params.row.paymentDate) : "",
  },
  {
    flex: 1,
    field: "payer",
    headerName: "Payer",
    type: "singleSelect",
    valueOptions: payerValueOptions.length ? payerValueOptions : undefined,
  },
  {
    flex: 1,
    field: "payee",
    headerName: "Payee",
  },
  {
    flex: 1,
    field: "payerPaymentId",
    headerName: "Payment ID",
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    field: "paymentStatus",
    headerName: "Payment Status",
  },
  {
    flex: 1,
    field: "paymentType",
    headerName: "Payment Type",
  },
  {
    flex: 1,
    field: "productType",
    headerName: "Product Type",
  },
  {
    flex: 1,
    type: "number",
    field: "eopPaymentAmount",
    headerName: "Payment Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    field: "isPosted",
    type: "boolean",
    headerName: "All Posted",
    renderCell: renderPosted,
  },
  {
    flex: 1,
    field: "reviewStatus",
    type: "singleSelect",
    headerName: "Review Status",
    valueOptions: enumToStrings(ReviewStatus).map((reviewStatus) => ({
      value: reviewStatus.toUpperCase(),
      label: reviewStatus,
    })),
  },
  {
    flex: 1,
    field: "",
    headerName: "Details",
    sortable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: GridRenderCellParams) => (
      // TODO: Replace with a React Router Link.
      <Button
        data-testid="toPaymentDetails"
        type="button"
        variant="outlined"
        style={{
          cursor: "pointer",
          width: "100%",
        }}
        onClick={() => {
          onSelectPayment(params.row);
        }}
      >
        <NavigateNextIcon
          style={{ cursor: "pointer" }}
          aria-label="Select Row"
        />
      </Button>
    ),
  },
];

interface PaymentsTableProps {
  isLoading: boolean;
  payments: PaymentMessage[];
  onSelectPayment: (payment: PaymentMessage) => void;
  initialState?: GridInitialStatePro;
  apiRef?: MutableRefObject<GridApiPro>;
}

export default function PaymentsTable({
  isLoading,
  payments,
  onSelectPayment,
  initialState,
  apiRef,
}: PaymentsTableProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  return (
    <div style={payments && payments.length ? {} : { height: "25rem" }}>
      <DataGridPro
        loading={isLoading}
        apiRef={apiRef}
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        rows={payments}
        columns={getColumns(
          toValueOptions(payments, "payer", sortAlphabetically),
          toValueOptions(payments, "practiceDisplayName", sortAlphabetically),
          onSelectPayment,
        )}
        getRowId={(row) => row.wieldyId}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: COLUMN_DEFAULT_VISIBILITY,
          },
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: "paymentDate", sort: "desc" }],
          },
          ...(initialState ?? {}),
        }}
        pageSizeOptions={[10, 20, 40]}
        pagination
        slots={{
          pagination: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: GridToolbar,
          loadingOverlay: Loading,
        }}
        sx={{
          [`& .${gridClasses.columnHeader}:first-child:focus, & .${gridClasses.cell}:first-child:focus`]:
            {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            },
          [`& .${gridClasses.columnHeader}:last-child:focus, & .${gridClasses.cell}:last-child:focus`]:
            {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            },
        }}
      />
    </div>
  );
}

PaymentsTable.defaultProps = {
  apiRef: undefined,
  initialState: undefined,
};
