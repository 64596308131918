import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React, { ReactElement } from "react";

import { isRedactMode } from "../utils/utils";

const MASK = "**********";

function redactText(text?: string | null): string | null | undefined {
  return isRedactMode() ? MASK : text;
}

function renderCellRedacted(props: GridRenderCellParams): ReactElement {
  // Grid cells which specify a valueFormatter function in its definition include a formattedValue
  // property in the GridRenderCellParams object. We generally want to display the
  // formatted value if it's present, otherwise we can just show the raw value.
  // See: https://github.com/mui/mui-x/issues/273
  const displayValue = props.formattedValue || props.value;

  return <div data-sr-redact>{redactText(displayValue)}</div>;
}

export { redactText, renderCellRedacted };
