import CloseIcon from "@mui/icons-material/Close";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";

import useQueryClient from "../../../api/query";
import { stall } from "../../../utils/utils";
import {
  CREDENTIAL_TYPE,
  isNewPayer,
  isSupportPayer,
  typeFromCredential,
} from "../common";
import UserSignalIcon from "../ui/UserSignalIcon.svg";

type RemoveUserCredentialType = {
  removeUserCredential: () => void;
};

interface CredentialsDeleteDialogProps {
  credentials: Partial<UserCredentialsMessage & RemoveUserCredentialType>;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  open: boolean;
  onClose: () => void;
}

export function CredentialsDeleteDialog({
  credentials,
  updateSnackBar,
  open,
  onClose,
}: CredentialsDeleteDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { wieldyId: credentialId } = credentials;
  const onDelete = async () => {
    try {
      if (credentialId) {
        setIsSubmitting(true);
        const { removeUserCredential } = credentials;
        const credentialType = typeFromCredential(credentials);
        if (credentialType === CREDENTIAL_TYPE.PAYER) {
          if (isSupportPayer(credentials)) {
            await Promise.all([
              await queryClient.deletePayerCredential(credentialId),
              await stall(),
            ]);
          } else if (isNewPayer(credentials)) {
            await Promise.all([
              await queryClient.deleteOtherCredential(credentialId),
              await stall(),
            ]);
          }
        }

        if (typeof removeUserCredential === "function") {
          removeUserCredential();
        }

        onClose();
        updateSnackBar({
          severity: "success",
          message: "Successfully deleted credentials.",
        });
      }
    } catch (error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to delete credentials. Try again.",
      });
      // eslint-disable-next-line no-console
      console.error(`Failed to delete credentials: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="credential-settings-dialog-title"
      aria-describedby="credential-settings-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "735px",
          width: "100%",
          padding: "24px 36px",
          margin: "auto",
          borderRadius: "12px",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F2F4F7",
              width: "48px",
              height: "48px",
              borderRadius: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "8px solid #F9FAFB",
            }}
          >
            <img
              src={UserSignalIcon}
              alt="Credentials Icon"
              width="24"
              height="24"
            />
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: "#475467",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle
          id="credential-settings-dialog-title"
          sx={{
            fontWeight: 600,
            color: "#303030",
            fontSize: "18px",
            paddingX: 0,
          }}
        >
          Delete Credentials
        </DialogTitle>
      </Box>
      <DialogContent sx={{ padding: 0 }}>
        <DialogContentText>
          Are you sure you want to delete{" "}
          {credentials.payerName || credentials.name}?
        </DialogContentText>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
            }}
          >
            <Button
              fullWidth
              sx={{ borderRadius: "8px" }}
              onClick={onClose}
              color="primary"
              data-testid="cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              fullWidth
              sx={{ borderRadius: "8px" }}
              color="error"
              variant="contained"
              data-testid="deleteCredentials"
              onClick={onDelete}
            >
              Yes
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
