const defaultPatientMessage: PatientMessage = {
  wieldyId: "",
  lastClaimDate: null,
  lastPaymentDate: null,
  name: null,
  ssn: null,
  dob: null,
  phoneNumber: null,
  email: null,
  address: null,
  city: null,
  state: null,
  zipCode: null,
  practiceId: null,
  responsibleName: null,
  responsibleSsn: null,
  responsibleMobilePhone: null,
  responsibleWorkPhone: null,
  responsibleEmail: null,
  responsibleAddress: null,
  responsibleCity: null,
  responsibleState: null,
  responsibleZipCode: null,
  insuranceSubscriberName: null,
  insuranceSubscriberId: null,
  insurancePractice: null,
  insurancePracticeDisplayName: null,
  insurancePracticeLocation: null,
  insurance: null,
  insuranceType: null,
  insurancePolicyEffectiveDate: null,
  insurancePatientLifetimeMax: null,
  insurancePatientLifetimeUsed: null,
  insuranceCovered: null,
};

export function constructPatientMessage(
  partial?: Partial<PatientMessage>,
): PatientMessage {
  return {
    ...defaultPatientMessage,
    ...partial,
  };
}
