import "../../App.css";

import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import React, { Dispatch } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { PostedState } from "../posting";
import PaymentsDetailsView from "./PaymentsDetailsView";
import PaymentsListView, { PaymentDashboardSettings } from "./PaymentsListView";
import { PaymentsStore, PaymentsStoreAction } from "./store";

interface PaymentsDashboardProps {
  store: PaymentsStore;
  updateStore: Dispatch<PaymentsStoreAction>;
  queryClient: QueryClient;
  loadPractices: () => Promise<void>;
  selectedLocations: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  dashboardSettings: PaymentDashboardSettings;
  saveDashboardSettings: (newSettings: PaymentDashboardSettings) => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export default function PaymentsDashboard({
  store,
  updateStore,
  queryClient,
  loadPractices,
  selectedLocations,
  selectedPractices,
  dashboardSettings,
  saveDashboardSettings,
  updateSnackBar,
}: PaymentsDashboardProps) {
  const loadPayment = async (paymentId: string) => {
    const payment = await queryClient.getPayment(paymentId);
    const claimIds = payment.Claim?.map((claim) => claim.wieldyId) ?? [];
    const claims = claimIds.length
      ? await queryClient.getClaimsProceduresAndPatient(claimIds)
      : [];
    updateStore({
      type: "SET_PAYMENT",
      payment,
      claims,
    });
  };

  const loadPayments = async () => {
    await loadPractices();
    updateStore({
      payments: await queryClient.getPayments(),
      type: "SET_PAYMENTS",
    });
  };

  const resetPayments = () => {
    updateStore({
      type: "RESET_PAYMENTS",
    });
  };

  const resetPayment = () => {
    updateStore({
      type: "RESET_PAYMENT",
    });
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    try {
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: PostedState[PostedState.POSTING],
        },
      });
      updateSnackBar({
        severity: "info",
        message: "Posting to PMS",
      });
      const postResponse = await queryClient.postToPMS(claim.wieldyId);
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedDateTime: postResponse.postedDateTime,
          postedAttempts: postResponse.postedAttempts,
        },
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully posted to PMS",
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.data) {
        const responseBody = error.response.data;
        updateSnackBar({
          severity: "error",
          message: responseBody.postedLatestErrorMessage,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: responseBody.postedState,
            postedAttempts: responseBody.postedAttempts,
            postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
          },
        });
      }
    }
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.updateClaim(claimWork);
  };
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PaymentsListView
            payments={store.payments}
            paymentsLoading={!store.loadedPayments}
            load={loadPayments}
            reset={resetPayments}
            selectedLocations={selectedLocations}
            selectedPractices={selectedPractices}
            dashboardSettings={dashboardSettings}
            saveDashboardSettings={saveDashboardSettings}
            updateSnackBar={updateSnackBar}
          />
        }
      />
      <Route
        path="/:paymentId"
        element={
          <PaymentsDetailsView
            claims={store.claims}
            updateClaim={updateClaim}
            postToPMS={postToPMS}
            payment={store.payment}
            loadPayment={loadPayment}
            loadingClaims={!store.loadedClaimsProcedures}
            reset={resetPayment}
            practices={selectedPractices}
          />
        }
      />
    </Routes>
  );
}
