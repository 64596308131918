import React, { useEffect, useImperativeHandle, useRef } from "react";
import SignaturePad, { Options } from "signature_pad";

export interface SignaturePadRef {
  addEventListener: (event: string, callback: () => void) => void;
  clear: () => void;
  toDataURL: () => string | undefined;
  off: () => void;
}

export interface SignaturePadProps extends Options {
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
}

const SignaturePadWrapper = React.forwardRef<
  SignaturePadRef,
  SignaturePadProps
>(({ width, height, backgroundColor, ...options }, ref) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const signaturePadRef = useRef<SignaturePad | null>(null);

  useImperativeHandle(ref, () => ({
    // This function returns an object that will be the value of the ref.
    addEventListener: (event: string, callback: () => void) => {
      signaturePadRef.current?.addEventListener(event, callback);
    },
    clear: () => signaturePadRef.current?.clear(),
    toDataURL: () => signaturePadRef.current?.toDataURL(),
    off: () => signaturePadRef.current?.off(),
  }));

  useEffect(() => {
    if (canvasRef.current) {
      // Initialize signaturePad with the canvas and options
      signaturePadRef.current = new SignaturePad(canvasRef.current, options);
      // signaturePadRef.current.addEventListener("endStroke", () => {
      //   onEndStroke?.();
      // });

      const ratio = Math.max(window.devicePixelRatio || 1, 1);

      // This part causes the canvas to be cleared
      canvasRef.current.width = canvasRef.current.offsetWidth * ratio;
      canvasRef.current.height = canvasRef.current.offsetHeight * ratio;
      canvasRef.current.getContext("2d")?.scale(ratio, ratio);

      // This library does not listen for canvas changes, so after the canvas is automatically
      // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
      // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
      // that the state of this library is consistent with visual state of the canvas, you
      // have to clear it manually.
      signaturePadRef.current.clear();
    }
    // Cleanup
    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
      }
    };
  }, []);

  return (
    <canvas
      data-testid="signaturePad"
      ref={canvasRef}
      style={{
        touchAction: "none",
        userSelect: "none",
        width,
        height,
        background: backgroundColor,
        borderRadius: "4px",
        border: "1px solid rgba(37, 35, 63, 0.12)",
      }}
    />
  );
});
SignaturePadWrapper.displayName = "SignaturePad";
SignaturePadWrapper.defaultProps = {
  width: "100%",
  height: "300px",
  backgroundColor: "white",
};

export default SignaturePadWrapper;
