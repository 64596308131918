import "./PDFViewer.css";

import { useAuth0 } from "@auth0/auth0-react";
import range from "lodash/range";
import React, { useEffect, useMemo, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import type {
  OnDocumentLoadSuccess,
  Options,
} from "react-pdf/dist/cjs/shared/types";

import { USE_AUTH } from "../../api/apiConfig";
import Loading from "../Loading";
import CustomNoRowsOverlay from "../TableNoDataOverlay";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url,
).toString();

function LoadingSource() {
  return (
    <div style={{ marginTop: "20%" }}>
      <Loading />
    </div>
  );
}

interface SourceProps {
  url: string;
  pageWidth?: number;
}

export default function Source({ url, pageWidth }: SourceProps) {
  const [pages, setPages] = useState<number>(0);
  const [accessToken, setAccessToken] = useState<string | undefined>();

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    if (USE_AUTH) {
      getAccessTokenSilently().then(setAccessToken);
    }
  }, [getAccessTokenSilently]);

  const onDocumentLoadSuccess: OnDocumentLoadSuccess = ({ numPages }) =>
    setPages(numPages);

  const options: Options = useMemo(() => {
    const opts: Options = {
      cMapUrl: "cmaps/",
      standardFontDataUrl: "standard_fonts/",
    };
    if (USE_AUTH) {
      opts.httpHeaders = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return opts;
  }, [USE_AUTH, accessToken]);

  if (USE_AUTH && !accessToken) {
    return <LoadingSource />;
  }

  const arrayOfPages = range(1, pages + 1);

  return (
    <div>
      <Document
        error={CustomNoRowsOverlay()}
        loading={LoadingSource()}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <div
          style={{
            maxHeight: `40rem`,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {arrayOfPages.map((page) => (
            <Page
              key={`page_${page}`}
              className="pdfPage"
              error={<div />}
              loading={LoadingSource()}
              pageNumber={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={pageWidth || 300}
            />
          ))}
        </div>
      </Document>
    </div>
  );
}

Source.defaultProps = {
  pageWidth: 300,
};
